import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useVoucherProducts } from '../../../store/hooks/shop';
import { VOUCHER_PRODUCT_LIST } from '../../../navigation/routes';
import Layout from '../../../components/App/Layout';
import ProductDetail from '../../../components/Shop/ProductDetail';

const VoucherProductPage = () => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const [product, setProduct] = React.useState();
  const products = useVoucherProducts();

  React.useEffect(() => {
    setProduct(products[productId]);
  }, [products, productId]);

  return (
    <Layout
      title={product ? product.name : t('Product Detail')}
      back={VOUCHER_PRODUCT_LIST}
      backPath={VOUCHER_PRODUCT_LIST}
    >
      <Box mt={4} mb={2} px={2}>
        <ProductDetail product={product} loading={false} />
      </Box>
    </Layout>
  );
};

export default React.memo(VoucherProductPage);
