import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ConfirmButton from '../Common/ConfirmButton';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const ProductListItem = ({ product, onSubmit, submitting, disabled, renew }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    product && (
      <Grid item>
        <Card className={classes.container}>
          <img src={product.image} alt={product.name} className={classes.image} />
          <CardContent>
            <Typography variant="h5">{product.name}</Typography>

            <Typography variant="h5" style={{ marginTop: 10 }}>
              {product.currency.symbol} {product.amount.toFixed(2)}
            </Typography>

            <Typography variant="caption" color="textSecondary" style={{ marginTop: 10 }}>
              {t('VAT Excluded')}
            </Typography>

            {!submitting && !disabled ? (
              <ConfirmButton
                onClick={() => {
                  onSubmit(product.id);
                }}
                style={{ marginTop: 30 }}
                color="primary"
                variant="contained"
              >
                {renew ? t('Renew Membership') : t('Request Membership')}
              </ConfirmButton>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    )
  );
};

ProductListItem.defaultProps = {
  submitting: false,
  onSubmit: () => {},
};

ProductListItem.propTypes = {
  onSubmit: PropTypes.func,
  product: PropTypes.object,
  submitting: PropTypes.bool,
};

const useStyles = makeStyles({
  container: {
    width: 250,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 100,
    objectFit: 'contain',
  },
});

export default React.memo(ProductListItem);
