import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
  },
  alignLeft: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const BenefitDetail = ({ items, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();

  React.useEffect(() => {
    setItem(items.find(x => x.id === parseInt(id)));
  }, [items, id]);

  const [item, setItem] = React.useState();

  return item ? (
    <Container maxWidth="sm">
      <Card>
        <img src={`data:image/gif;base64,${item.benefit_image}`} alt={item.name} className={classes.image} />
        <CardContent>
          <Typography className={classes.title} variant="caption" gutterBottom>
            {item.lpi_partner_id_name}
          </Typography>
          <Typography variant="h5">{item.name}</Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {item.general_benefit}
          </Typography>
          {item.member_benefit && (
            <Typography variant="caption" component="p">
              <Typography variant="caption" color="textSecondary">
                {t('Members only') + ': '}
              </Typography>
              {item.member_benefit}
            </Typography>
          )}
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </CardContent>
        <CardActions disableSpacing>
          <Button
            size="large"
            color="secondary"
            className={classes.alignLeft}
            href={item.link}
            target="_blank"
            disabled={!item.link}
          >
            {item.link ? t('CLAIM VOUCHER') : t('MEMBERS ONLY')}
          </Button>
        </CardActions>
      </Card>
    </Container>
  ) : loading ? (
    <LoadingState />
  ) : (
    <EmptyState />
  );
};

export default React.memo(BenefitDetail);
