import React, { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';

import useStripeCheckoutURL from '../../../hooks/Shop/useStripeCheckoutURL';
import { CHECKOUT_FAILURE } from '../../../navigation/routes';

const REDIRECT_TIMEOUT_MS = 2000;
const PAGE_SLUG = 'credit-card';

const CardCheckout = ({ cart }) => {
  const { t } = useTranslation();
  const { checkoutURL, status } = useStripeCheckoutURL(cart.id);
  const history = useHistory();

  useEffect(() => {
    if (status === 'success') {
      if (!checkoutURL) {
        history.push(CHECKOUT_FAILURE + `/${cart.id}`);
      } else {
        setTimeout(() => {
          // Avoid redirection if user changed page
          if (history.location.pathname.includes(PAGE_SLUG)) {
            window.open(checkoutURL, '_self');
          }
        }, REDIRECT_TIMEOUT_MS);
      }
    }
  }, [status]);

  switch (status) {
    case 'idle':
    case 'loading':
    case 'success':
      return (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box my={3}>
            <CircularProgress />
          </Box>

          <Typography align="center">{t('You will be redirected to payment page in a while')}...</Typography>
        </Box>
      );

    case 'error':
      return (
        <Box display="flex" justifyContent="center" height="100%" alignItems="center" flexDirection="column">
          <Typography style={{ color: red[500] }} align="center" gutterBottom variant="h6">
            {t('Error processing this order ID')}
          </Typography>
          <Typography align="center">
            {t('Perhaps, you have already paid for this order or the specified order ID does not exists')}
          </Typography>
        </Box>
      );
  }
};

export default memo(CardCheckout);
