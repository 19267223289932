import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import { initializeLocales } from './locales';
import Theme from './Theme';
import Store from './store/store';
import Wrapper from './components/App/Wrapper';
import rollbarConfig from './rollbarConfig';

initializeLocales();

const queryClient = new QueryClient();

function App() {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={Store.store}>
            <ConnectedRouter history={Store.history}>
              <ThemeProvider theme={Theme}>
                <Wrapper />
              </ThemeProvider>
            </ConnectedRouter>
          </ReduxProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export default App;
