import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useLanguages from '../../hooks/useLanguages';
import { Chip, Typography } from '@material-ui/core';

const LanguagesAutocomplete = ({ value, onChange, label, error, ...props }) => {
  const { status, languages } = useLanguages();

  return (
    <>
      <Autocomplete
        options={languages}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label={label || 'Language'} variant="outlined" />}
        loading={status === 'loading'}
        value={value || (props.multiple ? [] : '')}
        // getOptionSelected={(option, value) => {
        //   console.log(option, value);
        //   return option.id === value.id;
        // }}
        onChange={(_e, languages) => onChange && onChange(languages)}
        {...(props.multiple && {
          renderTags: (value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} variant="outlined" label={option.name} {...getTagProps({ index })} />
            )),
        })}
        {...props}
      />

      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </>
  );
};

export default LanguagesAutocomplete;
