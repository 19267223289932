import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Layout from '../../components/App/Layout';
import Panel from '../../components/Common/Panel';
import useHelpdeskMutations from '../../hooks/Helpdesk/useHelpdeskMutations';
import useHelpdeskTopics from '../../hooks/Helpdesk/useHelpdeskTopics';
import { setMessage } from '../../store/reducers/SnackReducer';
import { HELPDESK_TICKETS } from '../../navigation/routes';

const TicketCreatePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { topics, loading } = useHelpdeskTopics();
  const { createTicket } = useHelpdeskMutations();

  const handleSubmit = event => {
    event.preventDefault();
    const form = new FormData(event.target);
    const requestType = form.get('type');

    if (requestType === '') {
      dispatch(setMessage(t('Please select a request type')));
      return;
    }

    const parts = requestType.split('___');

    const queue = parts[0].length > 0 ? parts[0] : 'learning';

    const data = {
      queue: queue,
      title: parts[1],
      message: form.get('message'),
    };

    createTicket
      .mutateAsync(data)
      .then(() => {
        dispatch(setMessage(t('Your request has been sent')));
        history.push(HELPDESK_TICKETS);
      })
      .catch(err => {
        console.error(err);
        dispatch(setMessage(t('An error occurred during the request')));
      });
  };

  return (
    <Layout title={t('LPI Helpdesk')} back backPath={HELPDESK_TICKETS}>
      <Box p={2} pt={4}>
        <Container maxWidth="md">
          {!loading && topics && (
            <Panel title={t('Request support')}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel htmlFor="type">{t('Topic')}</InputLabel>
                      <Select
                        native
                        label={t('Topic')}
                        inputProps={{
                          name: 'type',
                          id: 'type',
                        }}
                      >
                        <option value="" key={t.id}></option>
                        {topics.map(topic => (
                          <optgroup key={topic.id} label={topic.name}>
                            {topic.childs.map(t => (
                              <option value={`${t.queue}___${t.name}`} key={t.id}>
                                {t.name}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      multiline
                      fullWidth
                      variant="outlined"
                      rows={10}
                      name="message"
                      placeholder={t('Write your question')}
                      required
                    />
                  </Grid>

                  <Grid item container justify="flex-end" xs={12}>
                    <Button variant="contained" color="primary" type="submit" disabled={createTicket.isLoading}>
                      {createTicket.isLoading ? <CircularProgress size={20} /> : t('Submit')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Panel>
          )}
        </Container>
      </Box>
      <Snackbar />
    </Layout>
  );
};

export default TicketCreatePage;
