export const FETCH_ACTIVITY = 'Activity.Fetch';
export const FETCH_ACTIVITY_SUCCESS = 'Activity.Fetch.Success';
export const FETCH_ACTIVITY_FAILURE = 'Activity.Fetch.Failure';

export const FETCH_ACTIVITIES = 'Activities.Fetch';
export const FETCH_ACTIVITIES_SUCCESS = 'Activities.Fetch.Success';
export const FETCH_ACTIVITIES_FAILURE = 'Activities.Fetch.Failure';

export const CREATE_ACTIVITY = 'Activities.Create';
export const CREATE_ACTIVITY_SUCCESS = 'Activities.Create.Success';
export const CREATE_ACTIVITY_FAILURE = 'Activities.Create.Failure';

export const EDIT_ACTIVITY = 'Activities.Edit';
export const EDIT_ACTIVITY_SUCCESS = 'Activities.Edit.Success';
export const EDIT_ACTIVITY_FAILURE = 'Activities.Edit.Failure';

export const CLAIM_ACTIVITY = 'Activities.Claim';
export const CLAIM_ACTIVITY_SUCCESS = 'Activities.Claim.Success';
export const CLAIM_ACTIVITY_FAILURE = 'Activities.Claim.Failure';

export const UPDATE_ACTIVITY = 'Activities.Update';
export const UPDATE_ACTIVITY_SUCCESS = 'Activities.Update.Success';
export const UPDATE_ACTIVITY_FAILURE = 'Activities.Update.Failure';

export const FETCH_ACTIVITY_CATEGORIES = 'Activities.Categories.Fetch';
export const FETCH_ACTIVITY_CATEGORIES_SUCCESS = 'Activities.Categories.Fetch.Success';
export const FETCH_ACTIVITY_CATEGORIES_FAILURE = 'Activities.Categories.Fetch.Failure';

export const OPEN_CLAIM_DIALOG = 'Activities.Claim.Dialog.Open';
export const DISMISS_CLAIM_DIALOG = 'Activities.Claim.Dialog.Dismiss';
