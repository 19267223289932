import React from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import i18n from 'i18next';

const processNotificationType = (notification, markAsRead) => {
  switch (notification.type) {
    case 'message':
      return notification.payload;

    case 'link': {
      const payload = JSON.parse(notification.payload);
      return <Link href={payload?.url}>{payload?.text}</Link>;
    }

    case 'button': {
      const payload = JSON.parse(notification.payload);
      return (
        <Box>
          <Typography gutterBottom>{payload?.text}</Typography>
          <Box display="flex" justifyContent="flex-end" style={{ marginBottom: 10 }}>
            {!notification.seen && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    if (markAsRead) markAsRead.mutate(notification.id);
                  }}
                  style={{ marginLeft: 5 }}
                >
                  {i18n.t('Ignore')}
                </Button>
                {payload?.buttons.map((button, index) => (
                  <Button
                    key={index}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (markAsRead) markAsRead.mutate(notification.id);
                      window.open(button.url, '_blank');
                    }}
                    style={{ marginLeft: 5 }}
                  >
                    {button.text}
                  </Button>
                ))}
              </>
            )}
          </Box>
        </Box>
      );
    }
  }
};

export default processNotificationType;
