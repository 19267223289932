import _keyBy from 'lodash/keyBy';
import _uniqBy from 'lodash/uniqBy';
import * as types from '../actions/certifications/types';
import { AUTH_LOGOUT_SUCCESS } from '../actions/auth/types';

const INITIAL_STATE = {
  groups: {},
  list: {},
  scoreboard: [],
  achievements: [],
};

const parseGroups = certifications => {
  let groups = certifications
    .filter(c => c.group_id)
    .map(c => ({
      id: c.group_id,
      name: c.group_name,
    }));

  groups = _uniqBy(groups, 'id').map(g => ({
    ...g,
    certs: certifications.filter(c => c.group_id === g.id).map(c => c.id),
  }));

  return groups;
};

const certificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CERTIFICATIONS_SUCCESS:
      return {
        ...state,
        groups: _keyBy(parseGroups(action.payload), 'id'),
        list: _keyBy(action.payload, 'id'),
      };
    case types.FETCH_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        achievements: action.payload,
      };
    case types.FETCH_SCOREBOARD_SUCCESS:
      return {
        ...state,
        scoreboard: action.payload,
      };
    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default certificationReducer;
