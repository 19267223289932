import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import useVoucherProducts from '../../../hooks/Shop/useVoucherProducts';
import { VOUCHER_PRODUCT_LIST } from '../../../navigation/routes';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Layout from '../../../components/App/Layout';
import ProductsGrid from '../../../components/Shop/ProductsGrid';

const VoucherProductListPage = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const [eventVouchers, setEventVouchers] = useState([]);
  const [codeVouchers, setCodeVouchers] = useState([]);

  const { status, vouchers } = useVoucherProducts();

  const handleClick = React.useCallback(
    product => {
      history.push(`${VOUCHER_PRODUCT_LIST}/${product.id}`);
    },
    [history],
  );

  useEffect(() => {
    setEventVouchers(vouchers.filter(v => v.is_event_voucher));
    setCodeVouchers(vouchers.filter(v => !v.is_event_voucher));
  }, [vouchers]);

  return (
    <Layout title={t('Buy Exam Vouchers')}>
      <Box mt={4} px={2}>
        <Container>
          <Box mb={4}>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Choose the kind of exam you want to buy')}
            </Typography>
          </Box>
          {eventVouchers.length > 0 && (
            <Box mb={4}>
              <Typography variant="h5" color="textSecondary">
                {t('Event Vouchers')}
              </Typography>
              <Box mt={2}>
                <ProductsGrid products={eventVouchers} loading={status === 'loading'} handleClick={handleClick} />
              </Box>
            </Box>
          )}

          <Box mb={4}>
            <Typography variant="h5" color="textSecondary">
              {t('Vouchers')}
            </Typography>
            <Box mt={2}>
              <ProductsGrid products={codeVouchers} loading={status === 'loading'} handleClick={handleClick} />
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default React.memo(VoucherProductListPage);
