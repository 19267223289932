import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_VOUCHERS = 'Vouchers.Fetch';

export const fetchVouchers = createRoutine(FETCH_VOUCHERS);

const INITIAL_REQUEST_STATE = {
  fetchVouchers: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  requests: INITIAL_REQUEST_STATE,
};

const vouchersReducer = handleActions(
  {
    // fetchVouchers
    [fetchVouchers.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = true;
      }),
    [fetchVouchers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = false;
        draft.list = payload.results;
      }),
    [fetchVouchers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default vouchersReducer;
