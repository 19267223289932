import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../backend/index';

export const QUERY_KEY = 'certifications.all';

/**
 * Get all available certificates
 */
const useCertificates = () => {
  const req = useQuery([QUERY_KEY], () => api.certificates.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    certificates: req.data?.results || [],
    ...req,
  };
};

export default useCertificates;
