import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 0,
  },
  more: {
    color: '#CFD8DC',
  },
  title: {
    marginBottom: '15px',
  },
  subtitle: {
    marginBottom: '15px',
  },
}));

const Panel = ({ title, subtitle, action, actionComponent, children }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardHeader
        className={classes.header}
        action={
          actionComponent ? (
            actionComponent()
          ) : action ? (
            <IconButton aria-label="settings">
              <MoreVertIcon className={classes.more} />
            </IconButton>
          ) : (
            ''
          )
        }
        title={
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        }
        subheader={
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

Panel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default React.memo(Panel);
