import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import isEqual from 'lodash/isEqual';

// Action Creators
export const CART_FETCH = 'Cart.Fetch';
export const CART_ADD_ITEM = 'Cart.Item.Add';
export const CART_UPDATE_ITEM = 'Cart.Item.Update';
export const CART_REMOVE_ITEM = 'Cart.Item.Remove';
export const CART_CLEAR = 'Cart.Clear';
export const CART_APPLY_COUPON = 'Cart.ApplyCoupon';
export const CHECKOUT_INIT = 'Checkout.Initialize';
export const CHECKOUT_CONFIG = 'Checkout.Config';
export const CHECKOUT_CONFIRM = 'Checkout.Confirm';
export const CHECKOUT_FREE_CONFIRM = 'Checkout.Free.Confirm';
export const CART_OPEN = 'Cart.Open';
export const CART_CLOSE = 'Cart.Close';

export const VOUCHER_PRODUCTS_FETCH = 'VoucherProducts.Fetch';

export const fetchCart = createRoutine(CART_FETCH);
export const cartAddItem = createRoutine(CART_ADD_ITEM);
export const cartUpdateItem = createRoutine(CART_UPDATE_ITEM);
export const cartRemoveItem = createRoutine(CART_REMOVE_ITEM);
export const cartClear = createRoutine(CART_CLEAR);
export const cartApplyCoupon = createRoutine(CART_APPLY_COUPON);
export const initCheckout = createRoutine(CHECKOUT_INIT);
export const fetchCheckoutConfig = createRoutine(CHECKOUT_CONFIG);
export const confirmPayment = createRoutine(CHECKOUT_CONFIRM);
export const confirmFreeCart = createRoutine(CHECKOUT_FREE_CONFIRM);
export const openCart = createAction(CART_OPEN);
export const closeCart = createAction(CART_CLOSE);

export const fetchVoucherProducts = createRoutine(VOUCHER_PRODUCTS_FETCH);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  fetchOrder: { loading: false, error: null },
  addItem: { loading: false, error: null },
  updateItem: { loading: false, error: null },
  removeItem: { loading: false, error: null },
  clear: { loading: false, error: null },
  applyCoupon: { loading: false, error: null },
  initCheckout: { loading: false, error: null },
  confirmPayment: { loading: false, error: null },
  fetchVoucherProducts: { loading: false, error: null },
  fetchCheckoutConfig: { loading: false, error: null },
};

const INITIAL_STATE = {
  cart: null,
  order: null,
  cartIsOpen: false,
  checkoutSecret: null,
  config: null,
  confirm: null,
  voucherProducts: {},
  jobOffersProducts: {},
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const shopReducer = handleActions(
  {
    [fetchCheckoutConfig.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchCheckoutConfig.loading = true;
        draft.requests.fetchCheckoutConfig.error = null;
      }),

    [fetchCheckoutConfig.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCheckoutConfig.loading = false;
        draft.config = payload;
      }),

    [fetchCheckoutConfig.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCheckoutConfig.loading = false;
        draft.requests.fetchCheckoutConfig.error = payload;
      }),

    [initCheckout.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.initCheckout.loading = true;
        draft.requests.initCheckout.error = null;
      }),

    [initCheckout.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.initCheckout.loading = false;
        draft.checkoutSecret = payload.client_secret;
      }),

    [initCheckout.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.initCheckout.loading = false;
        draft.requests.initCheckout.error = payload;
      }),

    [confirmPayment.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.confirmPayment.loading = true;
        draft.requests.confirmPayment.error = null;
      }),

    [confirmPayment.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.confirmPayment.loading = false;
        draft.checkoutSecret = payload.client_secret;
      }),

    [confirmPayment.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.confirmPayment.loading = false;
        draft.requests.confirmPayment.error = payload;
      }),

    [fetchCart.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchCart.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        if (!isEqual(draft.cart, payload)) {
          draft.cart = payload;
        }
      }),

    [fetchCart.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [cartAddItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.addItem.loading = true;
        draft.requests.addItem.error = null;
      }),

    [cartAddItem.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addItem.loading = false;
        draft.cart = payload;
      }),

    [cartAddItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addItem.loading = false;
        draft.requests.addItem.error = payload;
      }),

    [cartUpdateItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.updateItem.loading = true;
        draft.requests.updateItem.error = null;
      }),

    [cartUpdateItem.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateItem.loading = false;
        draft.cart = payload;
      }),

    [cartUpdateItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateItem.loading = false;
        draft.requests.updateItem.error = payload;
      }),

    [cartRemoveItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.removeItem.loading = true;
        draft.requests.removeItem.error = null;
      }),

    [cartRemoveItem.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeItem.loading = false;
        draft.cart = payload;
      }),

    [cartRemoveItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeItem.loading = false;
        draft.requests.removeItem.error = payload;
      }),

    [cartClear.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.clear.loading = true;
        draft.requests.clear.error = null;
      }),

    [cartClear.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.clear.loading = false;
        draft.cart = payload;
      }),

    [cartClear.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.clear.loading = false;
        draft.requests.clear.error = payload;
      }),

    [cartApplyCoupon.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.applyCoupon.loading = true;
        draft.requests.applyCoupon.error = null;
      }),

    [cartApplyCoupon.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyCoupon.loading = false;
        draft.cart = payload;
      }),

    [cartApplyCoupon.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyCoupon.loading = false;
        draft.requests.applyCoupon.error = payload;
      }),

    [fetchVoucherProducts.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchVoucherProducts.loading = true;
        draft.requests.fetchVoucherProducts.error = null;
      }),

    [fetchVoucherProducts.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVoucherProducts.loading = false;
        draft.voucherProducts = _keyBy(payload, 'id');
      }),

    [fetchVoucherProducts.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVoucherProducts.loading = false;
        draft.requests.fetchVoucherProducts.error = payload;
      }),

    [openCart]: state =>
      produce(state, draft => {
        draft.cartIsOpen = true;
      }),
    [closeCart]: state =>
      produce(state, draft => {
        draft.cartIsOpen = false;
      }),
  },
  INITIAL_STATE,
);

export default shopReducer;
