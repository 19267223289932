import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';

export const QUERY_KEY = 'membership';

export default function useMembershipMutations() {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const renew = useMutation(productId => api.renewMembership({ product_id: productId }), {
    onSuccess: invalidateQuery,
  });

  return {
    renew,
  };
}
