import _keyBy from 'lodash/keyBy';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withTranslation } from 'react-i18next';
import { isBefore, subYears } from 'date-fns';
import { Formik } from 'formik';

import { fetchScoreboard } from '../../store/actions/certifications';
import TextField from '../Forms/TextField';
import DateField from '../Forms/DateField';
import NumberField from '../Forms/NumberField';
import ChooseCategory from './ChooseCategory';

import { getMetric } from '../../utils/metrics';
import { CircularProgress } from '@material-ui/core';

class ActivityForm extends PureComponent {
  handleChangeCategory = formProps => {
    formProps.setFieldValue('category_id', null);
    formProps.setFieldValue('sub_category_id', null);
    formProps.setFieldValue('spec_category_id', null);
  };

  state = {
    isSubmitting: false,
  };

  renderActivityForm(formProps) {
    const { t } = this.props;
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formProps;
    const { metrics } = this.props;
    const categories = _keyBy(this.props.categories, 'id');
    const subCategories = _keyBy(this.props.subCategories, 'id');
    const specCategories = _keyBy(this.props.specCategories, 'id');
    const today = new Date();
    const todayFormatted = [
      String(today.getFullYear()),
      String(101 + today.getMonth()).substring(1),
      String(100 + today.getDate()).substring(1),
    ].join('-');
    let currentMetric = null;
    try {
      currentMetric = getMetric(
        metrics[`${values.category_id}-${values.sub_category_id}-${values.spec_category_id}`].name,
      );
    } catch (e) {
      return null;
    }
    return (
      <React.Fragment>
        <Typography variant="h6" color="textSecondary">
          {specCategories[values.spec_category_id]?.name}
        </Typography>
        <Typography component="p" style={{ margin: '16px 0px' }}>
          {categories[values.category_id]?.name} -{subCategories[values.sub_category_id]?.name}
        </Typography>

        <Button variant="contained" size="small" color="default" onClick={() => this.handleChangeCategory(formProps)}>
          {t('Change Category')}
        </Button>
        <br />
        <br />

        <Typography variant="h6" color="textSecondary">
          {t('Describe your Activity')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors['name'] && touched['name'] ? errors['name'] : null}
              label="Title"
            />
          </Grid>
          <Grid item md xs={12}>
            <DateField
              name="event_date"
              value={values.event_date === undefined ? '' : values.event_date}
              onChange={handleChange}
              error={errors['event_date'] ? errors['event_date'] : null}
              label="Date"
              inputProps={{
                max: todayFormatted,
              }}
            />
          </Grid>

          <Grid item md xs={12}>
            <NumberField
              name="units"
              value={values.units}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors['units'] && touched['units'] ? errors['units'] : null}
              label={currentMetric}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Description"
              error={errors['description'] && touched['description'] ? errors['description'] : null}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>

        <Box marginTop={4} marginBottom={4}>
          <Typography variant="h6" color="textSecondary">
            {t('Supporting Materials')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t('Upload file OR enter URL to supporting materials (Optional but required upon request)')}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="website"
              value={values.website}
              onChange={handleChange}
              onBlur={handleBlur}
              label="URL to supporting Materials (Optional)"
              error={errors['website'] && touched['website'] ? errors['website'] : null}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              {t('Additional Documentation')}
            </Typography>
            <Box maxWidth="100%" width={350}>
              <List>
                {values.document &&
                  values.document.map((doc, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={doc.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            const newDocs = [...values.document];
                            newDocs.splice(index, 1);
                            setFieldValue('document', newDocs);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                {values.documents &&
                  values.documents.map(doc => (
                    <ListItem key={doc.id}>
                      <ListItemText
                        primary={
                          <Link href={doc.download_url} target="_blank" rel="noopener" color="secondary">
                            {doc.name}
                          </Link>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            const newDocs = values.documents && values.documents.filter(item => item.id !== doc.id);
                            setFieldValue('documents', newDocs);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Box>

            <Box marginLeft={2} display="flex" style={{ cursor: 'pointer' }}>
              <CloudUploadIcon />
              <Box marginLeft={1}>
                <label htmlFor="upload-file-button" style={{ cursor: 'pointer' }}>
                  <ListItemText primary={<strong>Add new file</strong>} />
                </label>
                <input
                  name="document"
                  type="file"
                  className={this.props.classes.hiddenInput}
                  onChange={event => {
                    let newDocs = [];
                    event.target.files.forEach(file => {
                      newDocs.push({
                        name: file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name,
                        blob: file,
                      });
                    });
                    setFieldValue('document', [...(values.document || []), ...newDocs]);
                  }}
                  id="upload-file-button"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography>{this.props.error}</Typography>
            <Box marginY={3}>
              <Button variant="contained" color="primary" onClick={() => {
                this.setState({ isSubmitting: true });
                formProps.handleSubmit()
                }} disabled={this.state.isSubmitting}>
                {this.state.isSubmitting ? (
                  <Box mx={5}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  t('Submit')
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderCategoryChooser(formProps) {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" color="textSecondary">
          {t('Choose type of Activity')}
        </Typography>
        <Typography component="p">
          {t('Choose what kind of activity you would claim to gain PDUs for your certificates.')}
        </Typography>

        <ChooseCategory
          specCategories={this.props.specCategories}
          categories={this.props.categories}
          formProps={formProps}
        />
      </React.Fragment>
    );
  }

  handleSubmit = async values => {
    if (!values.document) {
      // eslint-disable-next-line no-unused-vars
      const { document, ...rest } = values;
      this.props.onSubmit(rest);
    } else {
      this.props.onSubmit(values);
    }
  };

  componentDidMount() {
    this.props.fetchScoreboard();
  }

  render() {
    const { t, scoreboard } = this.props;

    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={validate(this.props.rules, t, scoreboard)}
        onSubmit={this.handleSubmit}
      >
        {props => (!props.values.category_id ? this.renderCategoryChooser(props) : this.renderActivityForm(props))}
      </Formik>
    );
  }
}

const validate = (rules, t, scoreboard) => values => {
  const errors = {};
  if (!values.category_id) {
    errors.category_id = t('Category is required');
  }

  if (!values.sub_category_id) {
    errors.sub_category_id = t('SubCategory is required');
  }

  if (!values.spec_category_id) {
    errors.spec_category_id = t('Specification is required');
  }

  if (!values.name) {
    errors.name = t('Title is required');
  }

  if (!values.description) {
    errors.description = t('Description is required');
  }

  if (!values.event_date) {
    errors.event_date = t('Event date is required');
  }

  if (
    isBefore(
      new Date(values.event_date),
      scoreboard.cycle_start ? new Date(scoreboard.cycle_start) : subYears(new Date(), 1),
    )
  ) {
    errors.event_date = t('Event date cannot be more than 1 year before the cycle start date');
  }

  if (!values.units) {
    errors.units = t('Quantity is required');
  } else {
    const sel_rules = rules.filter(
      r =>
        r.category_id === values.category_id &&
        r.sub_category_id === values.sub_category_id &&
        r.spec_category_id === values.spec_category_id,
    );

    if (sel_rules.length > 0) {
      const r = sel_rules[0];
      const pdu = values.units * r.pdu_per_achievement;
      if (pdu > r.max_pdu_per_cycle) {
        errors.units = `${t('You can submit max')} ${r.max_pdu_per_cycle / r.pdu_per_achievement} ${t('units')}`;
      }
    }
  }

  return errors;
};

const styles = {
  hiddenInput: {
    display: 'none',
  },
};

ActivityForm.propTypes = {
  onSubmit: PropTypes.func,
  specCategories: PropTypes.array,
  categories: PropTypes.array,
  subCategories: PropTypes.array,
  metrics: PropTypes.object,
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      ({ certifications }) => ({
        scoreboard: certifications.scoreboard,
      }),
      { fetchScoreboard },
    )(ActivityForm),
  ),
);
