import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  value: {
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
    },
  },
  comparision: {
    fontSize: 12,
    marginLeft: 8,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
}));

const StatsItem = ({ label, error, value, comparision, subtitle }) => {
  const classes = useStyles();

  return (
    <Box display="block">
      <Box className={classes.label}>{label}</Box>

      <Box display="block">
        <Box display="inline" className={classes.value} style={error ? { color: 'red' } : {}}>
          {value}
        </Box>
        {comparision && (
          <Box display="inline" className={classes.comparision}>
            / {comparision}
          </Box>
        )}
      </Box>

      {subtitle && (
        <Hidden xsDown>
          <Box className={classes.subtitle}>{subtitle}</Box>
        </Hidden>
      )}
    </Box>
  );
};

StatsItem.prototypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  comparision: PropTypes.string,
  subtitle: PropTypes.string,
};

export default React.memo(StatsItem);
