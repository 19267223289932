import { all, fork } from 'redux-saga/effects';

import ActivitiesSaga from './sagas/ActivitiesSaga';
import AuthSaga from './sagas/AuthSaga';
import LegacyAuthSaga from './sagas/LegacyAuthSaga';
import CertificationsSaga from './sagas/CertificationsSaga';
import JobsSaga from './sagas/JobsSaga';
import MembershipsSaga from './sagas/MembershipsSaga';
import PersonalPageSaga from './sagas/PersonalPageSaga';
import VouchersSaga from './sagas/VouchersSaga';
import VolunteersSaga from './sagas/VolunteersSaga';
import CouponsSaga from './sagas/CouponsSaga';
import BenefitsSaga from './sagas/BenefitsSaga';
import ShopSaga from './sagas/ShopSaga';
import PartnershipSaga from './sagas/PartnershipSaga';

export default function* root() {
  yield all([
    fork(ActivitiesSaga),
    fork(AuthSaga),
    fork(LegacyAuthSaga),
    fork(CertificationsSaga),
    fork(JobsSaga),
    fork(MembershipsSaga),
    fork(PersonalPageSaga),
    fork(VouchersSaga),
    fork(VolunteersSaga),
    fork(CouponsSaga),
    fork(BenefitsSaga),
    fork(ShopSaga),
    fork(PartnershipSaga),
  ]);
}
