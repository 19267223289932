import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import ActivitySummaryRow from './ActivitySummaryRow';
import { useTranslation } from 'react-i18next';

const ActivitySummaryTable = ({ scoreboard, user }) => {
  const { t } = useTranslation();
  const isActive = scoreboard.cycle_id && user.membership_step === 'member';

  return (
    <Table style={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          {isActive && (
            <>
              <TableCell>{t('End')}</TableCell>
              <TableCell>
                {t('Qualifying PDUs')}
                <br />
                <small>{t('Earned/Required')}</small>
              </TableCell>
            </>
          )}
          <TableCell style={{ borderRight: '1px solid #E0E0E0' }}>{t('Unused PDUs')}</TableCell>
          {isActive && (
            <>
              <TableCell>
                {t('Education')}
                <br />
                <small>{t('Earned/Max')}</small>
              </TableCell>
              <TableCell>
                {t('Community')}
                <br />
                <small>{t('Earned/Max')}</small>
              </TableCell>
              <TableCell>
                {t('Experience')}
                <br />
                <small>{t('Earned/Max')}</small>
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <ActivitySummaryRow key={scoreboard.group_name} score={scoreboard} user={user} />
      </TableBody>
    </Table>
  );
};

ActivitySummaryTable.propTypes = {
  scoreboard: PropTypes.object,
  user: PropTypes.object,
};

export default React.memo(ActivitySummaryTable);
