import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { createMembershipRequest } from '../../../store/actions/memberships';

import ProductList from '../ProductList';

import PageTitle from '../../App/PageTitle';
import Typography from '@material-ui/core/Typography';

const EligibleStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const membership = useSelector(({ memberships }) => memberships);

  const handleSubmit = useCallback(
    productId => {
      dispatch(createMembershipRequest(productId));
    },
    [dispatch],
  );
  return (
    <>
      <PageTitle title={t('Choose your membership plan')} />
      <Typography>{t('You are eligible to become a LPI Member. Click below to request membership.')}</Typography>
      <br />
      <ProductList products={membership.products} submitting={membership.submitting} onSubmit={handleSubmit} />
    </>
  );
};

export default React.memo(EligibleStep);
