import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../backend/index';
import { setMessage } from '../../store/reducers/SnackReducer';
import { useDispatch } from 'react-redux';

export const QUERY_KEY = 'shop.cart';

/**
 * Get and manage a cart
 * @param { string } [orderId] If provided, fetch the cart with this orderId instead of default one
 */
const useCart = orderId => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const invalidateCart = () => queryClient.invalidateQueries([QUERY_KEY, orderId]);

  const req = useQuery([QUERY_KEY, orderId], () =>
    orderId ? api.cart.fetch({ order_id: orderId }) : api.cart.fetch(),
  );

  /* Define mutation hooks */
  const addItemMutation = useMutation(
    ({ productId, quantity }) => api.cart.addItem({ product_id: productId, item_qty: quantity }),
    {
      onSuccess: invalidateCart,
    },
  );
  const updateItemMutation = useMutation(
    ({ item, quantity }) => api.cart.updateItem({ item_id: item.id, item_qty: quantity }),
    {
      onSuccess: invalidateCart,
    },
  );
  const removeItemMutation = useMutation(({ item }) => api.cart.removeItem({ item_id: item.id }), {
    onSuccess: invalidateCart,
  });

  const clearCart = useMutation(() => api.cart.clear(), {
    onSuccess: invalidateCart,
  });

  // TODO #LPIDEV-6560 pass orderId with coupon code
  // WARN coupon will be applied only to default cart
  const applyCouponMutation = useMutation(
    ({ couponCode }) => api.cart.applyCoupon({ coupon_code: couponCode, order_id: orderId }),
    {
      onSuccess: invalidateCart,
      onError: response => {
        console.log(response);
        dispatch(setMessage(response));
      },
    },
  );

  /* Mutation handler */
  const addItem = (productId, quantity) => addItemMutation.mutateAsync({ productId, quantity });
  const updateItem = (item, quantity) => updateItemMutation.mutateAsync({ item, quantity });
  const removeItem = item => removeItemMutation.mutateAsync({ item });
  const applyCoupon = couponCode => applyCouponMutation.mutateAsync({ couponCode });

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    cart: req.data || {},

    addItem,
    updateItem,
    removeItem,
    applyCoupon,
    clearCart,

    mutationStatus: {
      addItem: addItemMutation.status,
      updateItem: updateItemMutation.status,
      removeItem: removeItemMutation.status,
      applyCoupon: applyCouponMutation.status,
      clearCart: clearCart.status,
    },
    ...req,
  };
};

export default useCart;
