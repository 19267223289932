import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Layout from '../../components/App/Layout';
import Panel from '../../components/Common/Panel';
import { setMessage } from '../../store/reducers/SnackReducer';
import { HELPDESK_TICKETS } from '../../navigation/routes';
import { useProfile } from '../../hooks/useProfile';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useHelpdeskTicket from '../../hooks/Helpdesk/useHelpdeskTicket';
// import { useUser } from '../../store/hooks/company';

const TicketDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: user, isLoading: isLoadingProfile } = useProfile()
  const [message, setMessageText] = useState('');

  const { id } = useParams();

  const { ticket, isLoading, sendMessage, refetch } = useHelpdeskTicket(id);

  const handleSendMessage = () => {
    if (!message) return;

    const escaped = message.replace(/\n/g, '<br />');

    sendMessage
      .mutateAsync({ message: escaped })
      .then(() => {
        setMessageText('');
      })
      .catch(() => {
        dispatch(setMessage(t('An error occurred during the request')));
      });
  };

  return (
    <Layout title={t('LPI Helpdesk')} back backPath={HELPDESK_TICKETS}>
      <Box p={2} pt={4}>
        <Container maxWidth="md">
          {!isLoading && !isLoadingProfile && ticket && (
            <Panel title={t('Ticket details')}>
              <Typography variant="h6">{ticket.title}</Typography>

              <Box py={1}>
                <Divider />
              </Box>

              <Typography variant="body1">
                {t('Status')}: {ticket.state}
              </Typography>

              <Typography variant="body1">
                {t('Team')}: {ticket.team_name}
              </Typography>

              <Typography variant="body1">
                {t('Opened at')}:{' '}
                {new Date(ticket.date).toLocaleDateString() + ' ' + new Date(ticket.date).toLocaleTimeString()}
              </Typography>

              <Box py={1}>
                <Divider />
              </Box>

              <Box dangerouslySetInnerHTML={{ __html: ticket.description }} />

              <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                {ticket.messages.map(message => (
                  <Box
                    padding="10px 15px 0 15px"
                    border="1px solid black"
                    borderRadius="5px"
                    key={message.id}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    mt={2}
                    alignSelf={message.author_email == user.email ? 'flex-end' : 'flex-start'}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignSelf={message.author_email == user.email ? 'flex-end' : 'flex-start'}
                    >
                      <Typography variant="caption">
                        {new Date(message.date).toLocaleDateString() +
                          ' ' +
                          new Date(message.date).toLocaleTimeString()}
                        {' - '}
                        {message.author_name}
                        {' - '}
                        {message.author_email}
                      </Typography>
                      <Box dangerouslySetInnerHTML={{ __html: message.body }} />
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box display="flex" alignItems="flex-end" mt={5}>
                <TextField
                  style={{ flexGrow: 1 }}
                  name="message"
                  variant="outlined"
                  disabled={sendMessage.isLoading}
                  fullWidth
                  multiline
                  rows={4}
                  value={message}
                  onChange={e => setMessageText(e.target.value)}
                />
                <Box ml={2}>
                  <Button variant="contained" color="primary" onClick={handleSendMessage}>
                    {sendMessage.isLoading ? (
                      <CircularProgress color="secondary" size={30} style={{ padding: 3 }} />
                    ) : (
                      t('Send')
                    )}
                  </Button>
                </Box>
              </Box>
            </Panel>
          )}

          {isLoading && (
            <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" />
            </Box>
          )}
        </Container>
      </Box>
      <Snackbar />
    </Layout>
  );
};

export default TicketDetailPage;
