import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../backend/index';

export const QUERY_KEY = 'location.all';

/**
 * Get all available locations
 */
const usePlaces = () => {
  const req = useQuery([QUERY_KEY], () => api.places.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    places: req.data || [],
    ...req,
  };
};

export default usePlaces;
