import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getAuthRequests } from '../../store/selectors';
import { update } from '../../store/reducers/AuthReducer';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Layout from '../../components/App/Layout';
import ProfileForm from '../../components/Users/ProfileForm';
import { Container } from '@material-ui/core';

const ProfileSinglePage = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  // const user = useSelector(getUser);
  // const request = useSelector(getAuthRequests);

  // const handleSubmit = useCallback(
  //   values => {
  //     let contactable_jobs = values.contactable_jobs;
  //     if (contactable_jobs !== undefined) {
  //       contactable_jobs = contactable_jobs ? 'yes' : 'no';
  //     }
  //     return dispatch(update({ id: user.id, data: { contactable_jobs } }));
  //   },
  //   [dispatch, user],
  // );

  return (
    <Layout title={t('Profile')}>
      <Container maxWidth="md" style={{ padding: 20, paddingTop: 40 }}>
        <ProfileForm
        // data={user}
        // loading={request.update.loading}
        // error={request.update.error}
        // onSubmit={handleSubmit}
        />
      </Container>
    </Layout>
  );
};

export default React.memo(ProfileSinglePage);
