import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';

// Polyfill for Object.entries
if (!Object.entries)
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i);
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
  };

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0d03750c364e4546b5406f7a7a0570d3@sentry.io/1858860',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
