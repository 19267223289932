import _values from 'lodash/values';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchScoreboard } from '../../store/actions/certifications';
import { fetchActivities } from '../../store/actions/activities';
import * as routes from '../../navigation/routes';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import Layout from '../../components/App/Layout';
import ActivityTable from '../../components/Activities/ActivityTable';
import RejectActivityTable from '../../components/Activities/RejectActivityTable';
import ActivityEmpty from '../../components/Activities/AcvivityEmpty';
import ActivitySummaryTable from '../../components/Activities/ActivitySummaryTable';
import { Button, Box } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { useTranslation, withTranslation } from 'react-i18next';

class ActivitiesPage extends PureComponent {
  componentDidMount() {
    this.props.fetchScoreboard();
    this.props.fetchActivities();
  }

  render() {
    if (!this.props.loaded) return null;

    const { classes, valid, unused, rejected, review, user, t } = this.props;
    return (
      <Layout title={t('Manage PDUs')}>
        <Box mt={4} px={2}>
          {user.can_submit_pdus && (
            <>
              <Fab color="primary" component={Link} to={routes.ACTIVITIES_CREATE} className={classes.fabRounded}>
                <AddIcon className={classes.fabIcon} />
              </Fab>
              <Fab
                color="primary"
                component={Link}
                to={routes.ACTIVITIES_CREATE}
                className={classes.fab}
                variant="extended"
              >
                <AddIcon className={classes.fabIcon} />
                {t('Add Activity')}
              </Fab>
            </>
          )}
          {!user.can_submit_pdus && (
            <Alert severity="error" style={{ marginBottom: 16 }}>
              {t(
                'Only active members and inactive certification holders seeking membership need to submit PDUs. For more information on PDUs, please read',
              )}
              <br />
              <a
                href="https://www.lpi.org/member/pdu-procedures-and-policies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lpi.org/member/pdu-procedures-and-policies
              </a>
            </Alert>
          )}

          <Card style={{ marginBottom: 16 }}>
            <CardHeader title={t('Cycle Summary')} />
            <CardContent>
              <ActivitySummaryTable user={this.props.user} scoreboard={this.props.scoreboard} />

              <Button component={Link} to={routes.ACTIVITIES_SUMMARY}>
                {t('See details')}
              </Button>
            </CardContent>
          </Card>

          {review.length > 0 ? (
            <Card style={{ marginBottom: 16 }}>
              <CardHeader title="PDUs under review" />
              <CardContent>
                <ActivityTable claim={false} activities={review} />
              </CardContent>
            </Card>
          ) : null}
          <Card style={{ marginBottom: 16 }}>
            <CardHeader title={t('Accepted PDUs')} />
            <CardContent>
              <Typography>{t('Current cycle')}</Typography>
              {valid.length > 0 ? (
                <ActivityTable activities={valid} />
              ) : unused.length > 0 ? (
                <>
                  <p>{t('Your membership cycle is not active yet')}</p>
                </>
              ) : (
                <ActivityEmpty button={false} />
              )}

              {unused.length > 0 && (
                <>
                  <Typography>{t('Unused')}</Typography>
                  <ActivityTable activities={unused} />
                </>
              )}
            </CardContent>
          </Card>

          {rejected.length > 0 ? (
            <Card style={{ marginBottom: 16 }}>
              <CardHeader title="Rejected PDUs" />
              <CardContent>
                <RejectActivityTable claim={false} activities={rejected} />
              </CardContent>
            </Card>
          ) : null}
        </Box>
      </Layout>
    );
  }
}

const useStyles = withStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 10000,
    margin: 32,
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  fabRounded: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: '72px 8px',
    zIndex: 10000,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  fabIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(),
    },
  },
}));

const mapStateToProps = ({ auth, activities, certifications }) => {
  const orderedActivities = _reverse(_sortBy(_values(activities.list), 'create_date'));

  const rejectedActivities = orderedActivities.filter(a => a.state === 'rejected');
  const reviewActivities = orderedActivities.filter(
    a => a.state === 'need_review' || a.state === 'waiting_materials' || a.state === 'under_dispute',
  );

  const notActiveActivitiesIds = [...rejectedActivities.map(a => a.id), ...reviewActivities.map(a => a.id)];

  const validActivities = orderedActivities.filter(a => !notActiveActivitiesIds.includes(a.id) && a.cycle_id);
  const unusedActivities = orderedActivities.filter(a => !notActiveActivitiesIds.includes(a.id) && !a.cycle_id);

  return {
    user: auth.user,
    scoreboard: certifications.scoreboard,
    rejected: rejectedActivities,
    review: reviewActivities,
    valid: validActivities,
    unused: unusedActivities,
    loaded: activities.loaded,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchActivities, fetchScoreboard })(useStyles(ActivitiesPage)),
);
