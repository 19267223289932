import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { formatDate } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const ActivitySummaryRow = ({ score, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isActive = score.cycle_id && user.membership_step === 'member';

  const cats = _keyBy(score.categories, 'name');

  const targetReached = score.pdu >= score.target;
  const educationCompleted = _get(cats, 'Education.pdu') < _get(cats, 'Education.minimum');
  const communityCompleted = _get(cats, 'Community.pdu') < _get(cats, 'Community.minimum');
  const experienceCompleted = _get(cats, 'Experience.pdu') < _get(cats, 'Experience.minimum');

  return (
    <TableRow>
      {isActive && (
        <>
          <TableCell>{formatDate(score.cycle_end)}</TableCell>
          <TableCell
            className={
              !targetReached || !educationCompleted || experienceCompleted || communityCompleted
                ? classes.error
                : classes.ok
            }
          >
            {score.pdu}
            <span style={{ color: 'black' }}>
              /{score.target} (edu:{' '}
              {cats['Education'].real_pdu < cats['Education'].maximum
                ? cats['Education'].real_pdu
                : cats['Education'].maximum}
              {', '}
              com:{' '}
              {cats['Community'].real_pdu < cats['Community'].maximum
                ? cats['Community'].real_pdu
                : cats['Community'].maximum}
              {', '}
              exp:{' '}
              {cats['Experience'].real_pdu < cats['Experience'].maximum
                ? cats['Experience'].real_pdu
                : cats['Experience'].maximum}
              )
            </span>
          </TableCell>
        </>
      )}

      <TableCell style={{ borderRight: '1px solid #E0E0E0' }}>{score.remaining_pdu}</TableCell>
      {isActive && (
        <>
          {cats['Education'] ? (
            <TableCell className={educationCompleted ? classes.error : classes.ok}>
              {cats['Education'].real_pdu}/{cats['Education'].maximum}
              <small className={classes.minimum}>
                {t('min')} {cats['Education'].minimum}
              </small>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )}

          {cats['Community'] ? (
            <TableCell className={communityCompleted ? classes.error : classes.ok}>
              {cats['Community'].real_pdu}/{cats['Community'].maximum}
              <small className={classes.minimum}>
                {t('min')} {cats['Community'].minimum}
              </small>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )}

          {cats['Experience'] ? (
            <TableCell className={experienceCompleted ? classes.error : classes.ok}>
              {cats['Experience'].real_pdu}/{cats['Experience'].maximum}
              <small className={classes.minimum}>
                {t('min')} {cats['Experience'].minimum}
              </small>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

ActivitySummaryRow.propTypes = {
  scoreboard: PropTypes.object,
  user: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  minimum: {
    display: 'block',
  },
  error: {
    color: theme.palette.error.main,
  },
  ok: {
    fontWeight: 'bold',
  },
}));

export default React.memo(ActivitySummaryRow);
