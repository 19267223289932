import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { acceptCodeOfConduct, logout } from '../../store/actions/auth';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageBackground from '../../components/App/PageBackground';

class CodeOfConductPage extends PureComponent {
  accept = () => {
    this.props.acceptCodeOfConduct(this.props.user.id);
  };

  reject = () => {
    this.props.logout();
  };

  render() {
    const { classes } = this.props;
    return (
      <PageBackground styles={{ backgroundColor: '#ededed' }}>
        <Grid container justify="center">
          <Grid item>
            <Card className={classes.cocContainer}>
              <CardHeader title="Code of Conduct" />
              <CardContent className={classes.textContainer}>
                <Typography variant="h5">Title</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </Typography>

                <br />
                <Typography variant="h5">Title</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </Typography>

                <br />
                <Typography variant="h5">Title</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </Typography>

                <br />
                <Typography variant="h5">Title</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </Typography>

                <br />
                <Typography variant="h5">Title</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={this.accept}>Accept</Button>
                <Button onClick={this.reject}>Reject</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </PageBackground>
    );
  }
}

const styles = theme => ({
  cocContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: 100,
      width: 600,
    },
  },
  textContainer: {
    borderTop: '1px solid #ededed',
    borderBottom: '1px solid #ededed',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 600,
      overflow: 'auto',
    },
  },
});

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
  };
};

export default withStyles(styles)(connect(mapStateToProps, { acceptCodeOfConduct, logout })(CodeOfConductPage));
