import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import React from 'react';
import Layout from '../../components/App/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScoreboard } from '../../store/actions/certifications';
import { fetchActivities } from '../../store/actions/activities';
import { getAcceptedActivities, getScoreboard, getUser } from '../../store/selectors';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ActivitySummaryDetailsSection from '../../components/Activities/ActivitySummaryDetailsSection';
import ActivitySummaryTable from '../../components/Activities/ActivitySummaryTable';

const ActivitiesSummaryPage = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const activities = useSelector(getAcceptedActivities);
  const score = useSelector(getScoreboard);

  const activitiesByCategory = _groupBy(activities, 'category_name');

  React.useEffect(() => {
    dispatch(fetchScoreboard());
    dispatch(fetchActivities());
  }, [dispatch]);

  return (
    <Layout title="PDU Summary">
      <Card style={{ marginBottom: 16 }}>
        <CardHeader title="Cycle Summary" />
        <CardContent>
          <ActivitySummaryTable scoreboard={score} user={user} />
        </CardContent>
      </Card>

      {_keys(activitiesByCategory).map(section => (
        <ActivitySummaryDetailsSection key={section} section={section} activities={activitiesByCategory[section]} />
      ))}
    </Layout>
  );
};

export default ActivitiesSummaryPage;
