import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

const ConfirmButton = ({ onClick, children, ...rest }) => {
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();

  const submitClick = ev => {
    onClick(ev);
    setConfirm(false);
  };

  const renderConfirm = () => (
    <Button {...rest} variant="outlined" color="secondary" fullWidth onClick={submitClick}>
      {t('Are you sure?')}
    </Button>
  );

  const renderButton = () => (
    <Button {...rest} onClick={() => setConfirm(true)}>
      {children}
    </Button>
  );

  return confirm ? renderConfirm() : renderButton();
};

export default React.memo(ConfirmButton);
