import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBenefits } from '../reducers/BenefitsReducer';
import { getBenefits, getBenefitsRequests } from '../selectors';

const useBenefits = () => {
  const dispatch = useDispatch();
  const benefits = useSelector(getBenefits);
  const requests = useSelector(getBenefitsRequests);
  const [wrapper, setWrapper] = React.useState([]);

  React.useEffect(() => {
    dispatch(fetchBenefits());
  }, [dispatch]);

  React.useEffect(() => {
    setWrapper(benefits);
  }, [benefits]);

  return { requests, benefits: wrapper };
};
export { useBenefits };
