import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory } from 'react-router-dom';
import { BENEFITS as rootPath } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BenefitDetail from './BenefitDetail';
import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
  },
});

const BenefitsGrid = ({ items, loading }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const classes = useStyles();

  return (
    <Switch>
      <Route path={rootPath + '/:id'}>
        <BenefitDetail items={items} loading={loading} />
      </Route>
      <Route path={rootPath}>
        {items.length > 0 ? (
          <Grid container spacing={3}>
            {items.map(benefit => (
              <Grid item xs={3} key={benefit.id}>
                <Card
                  onClick={() => {
                    history.push(rootPath + '/' + benefit.id);
                  }}
                >
                  <img
                    src={`data:image/gif;base64,${benefit.benefit_image}`}
                    alt={benefit.name}
                    className={classes.image}
                  />
                  <CardContent>
                    <Typography className={classes.title} variant="caption" gutterBottom>
                      {benefit.lpi_partner_id_name}
                    </Typography>
                    <Typography variant="h5">{benefit.name}</Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      {benefit.general_benefit}
                    </Typography>
                    {benefit.member_benefit && (
                      <Typography variant="caption" component="p">
                        <Typography variant="caption" color="textSecondary">
                          {t('Members only') + ': '}
                        </Typography>
                        {benefit.member_benefit}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : loading ? (
          <LoadingState />
        ) : (
          <EmptyState />
        )}
      </Route>
    </Switch>
  );
};

export default React.memo(BenefitsGrid);
