import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getJobRequests } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { JOBS_APPLIED } from '../../navigation/routes';
import api from '../../backend';

import Layout from '../../components/App/Layout';
import ApplicationsTable from '../../components/Jobs/ApplicationsTable';
import useApplications from '../../hooks/Jobs/useApplications';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import usePeopleOfferSearch from '../../hooks/Jobs/usePeopleOfferSearch';

const ApplicationsPage = () => {
  const { t } = useTranslation();

  const { status, applications } = useApplications();
  const [items, setItems] = React.useState(null);

  // Used to trigger a render on favorite click
  const { offers } = usePeopleOfferSearch({ favorites: true });

  useEffect(() => {
    if (status === 'success') {
      const offersProms = applications.map(application => api.job.get(application.job_id));
      Promise.allSettled(offersProms).then(reqs => setItems(reqs.map(req => req.value).filter(el => !!el)));
    }
  }, [status, offers]);

  return (
    <Layout title={t('Job Applications')}>
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Your applications')}
        </Typography>
        {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
      </Box>

      {(() => {
        if (items === null || status === 'idle' || status === 'loading') {
          return (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="50vh">
              <CircularProgress />
            </Box>
          );
        }

        switch (status) {
          case 'error':
            return (
              <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="50vh">
                <Typography>{t('An error occurred during the request')}</Typography>
              </Box>
            );

          case 'success':
            return <ApplicationsTable items={items} rootPath={JOBS_APPLIED} />;
        }
      })()}
    </Layout>
  );
};

export default React.memo(ApplicationsPage);
