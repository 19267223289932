import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';
import { QUERY_KEY } from './useLpiLocations';

const useLpiLocationMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const approve = useMutation(id => api.locations.approve(id), {
    onSuccess: invalidateQuery,
  });

  return {
    approve,
  };
};

export default useLpiLocationMutations;
