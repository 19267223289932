import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../navigation/routes';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import logo from '../assets/images/logo.png';
import { withTranslation } from 'react-i18next';

class NotFoundPage extends PureComponent {
  render() {
    const { classes, t } = this.props;
    return (
      <Grid container justify="center">
        <Grid item className={classes.container}>
          <img src={logo} alt="Linux Professional Institute" className={classes.logo} />

          <Typography variant="h2">Ops!</Typography>
          <Typography variant="h5" className={classes.message}>
            {t("The page you are looking for isn't here")}
          </Typography>
          <Button component={Link} variant="outlined" size="large" to={routes.HOME}>
            {t('Go home')}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  container: {
    marginTop: 50,
    padding: 16,
  },
  logo: {
    height: 50,
    marginBottom: 50,
  },
  message: {
    marginTop: 10,
    marginBottom: 30,
  },
};

export default withTranslation()(withStyles(styles)(NotFoundPage));
