import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, Switch } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { red } from '@material-ui/core/colors';
import { Box, Paper, styled, Typography, CircularProgress, Container, Grid } from '@material-ui/core';
import { loadDashboardData } from '../../store/actions/auth';
import { useDispatch } from 'react-redux';

import useCart from '../../hooks/Shop/useCart';
import * as routes from '../../navigation/routes';
import Cart from '../../components/Shop/Cart';
import PrivateRoute from '../../components/App/PrivateRoute';
import CheckoutMethods from '../../components/Shop/Checkout/CheckoutMethods';
import CheckoutSuccess from '../../components/Shop/Checkout/CheckoutSuccess';
import CheckoutFailure from '../../components/Shop/Checkout/CheckoutFailure';
import CardCheckout from '../../components/Shop/Checkout/CardCheckout';
import TransferCheckout from '../../components/Shop/Checkout/TransferCheckout';
import PaypalCheckout from '../../components/Shop/Checkout/PaypalCheckout';

import logo from '../../assets/images/logo-white.png';
import { is } from 'date-fns/locale';

const CheckoutPageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100%',
  background: 'rgba(255,205,4,1)',
  paddingBottom: 30,
});

const CheckoutContainer = styled(Container)({
  marginTop: 100,
});

const LogoContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 16,
});

const CheckoutBox = styled(Box)({
  padding: 16,
  flex: 1,
});

const CheckoutCartBox = styled(CheckoutBox)({
  backgroundColor: '#ededed',
});

const matchOrderId = pathname => {
  const lastPath = /[^\/]+$/.exec(pathname)?.[0];
  if (!/checkout|success|failure|credit-card|paypal|bank-transfer/.test(lastPath)) return lastPath;
};

const CheckoutPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // If present in the URL, use the provided order ID, else fetch the default cart
  const orderId = matchOrderId(pathname);
  const { status, cart } = useCart(orderId);

  const isSuccess = pathname.includes('/success');

  dispatch(loadDashboardData());

  return (
    <CheckoutPageContainer>
      <LogoContainer>
        <img src={logo} alt="Linux Professional Institute" />
      </LogoContainer>
      <CheckoutContainer maxWidth="md">
        <Paper>
          <Box display="flex" minHeight={500} flexDirection={matches ? 'row' : 'column'}>
            <CheckoutBox>
              <>
                {!isSuccess && (
                  <Link to="/cart">
                    <Typography variant="overline">{t('Back to your Cart')}</Typography>
                  </Link>
                )}

                <Typography variant="h6" gutterBottom>
                  {t('Checkout')}
                </Typography>

                {(() => {
                  switch (status) {
                    case 'idle':
                    case 'loading':
                      return (
                        <Box display="flex" justifyContent="center" height="100%" alignItems="center">
                          <CircularProgress />
                        </Box>
                      );

                    case 'error':
                      return (
                        <Grid container direction="column">
                          <Typography style={{ color: red[500] }} align="center" gutterBottom variant="h6">
                            {t('Error processing this order')}
                          </Typography>
                          <Typography align="center">{t('This order ID does not exists')}</Typography>
                        </Grid>
                      );

                    case 'success':
                      return (
                        <Box>
                          <Grid container direction="column">
                            <Typography variant="subtitle2">{t('Billing details')}</Typography>
                            <Typography variant="caption">{cart.company_name}</Typography>
                            <Typography variant="caption">{cart.partner_name}</Typography>
                            <Typography variant="caption">{cart.billing_street}</Typography>
                            <Typography variant="caption">{cart.billing_city}</Typography>
                            <Typography variant="caption">{cart.billing_state}</Typography>
                            <Typography variant="caption">{cart.billing_country}</Typography>
                          </Grid>

                          <Switch>
                            <PrivateRoute path={routes.CHECKOUT_CARD} render={() => <CardCheckout cart={cart} />} />
                            <PrivateRoute
                              path={routes.CHECKOUT_TRANSFER}
                              render={() => <TransferCheckout cart={cart} />}
                            />
                            <PrivateRoute path={routes.CHECKOUT_PAYPAL} render={() => <PaypalCheckout cart={cart} />} />
                            <PrivateRoute path={routes.CHECKOUT_SUCCESS} component={CheckoutSuccess} />
                            <PrivateRoute path={routes.CHECKOUT_FAILURE} component={CheckoutFailure} />
                            <PrivateRoute render={() => <CheckoutMethods cart={cart} />} />
                          </Switch>
                        </Box>
                      );
                  }
                })()}
              </>
            </CheckoutBox>

            <CheckoutCartBox>
              <Typography variant="h6">{t('Your order')}</Typography>
              <Cart editable={false} toCheckout={false} orderId={orderId} disableCoupon={isSuccess} />
            </CheckoutCartBox>
          </Box>
        </Paper>
      </CheckoutContainer>
    </CheckoutPageContainer>
  );
};

export default React.memo(CheckoutPage);
