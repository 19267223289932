import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_BENEFITS = 'Benefits.Fetch';

export const fetchBenefits = createRoutine(FETCH_BENEFITS);

const INITIAL_REQUEST_STATE = {
  fetchBenefits: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  requests: INITIAL_REQUEST_STATE,
};

const benefitsReducer = handleActions(
  {
    // fetchBenefits
    [fetchBenefits.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchBenefits.loading = true;
      }),
    [fetchBenefits.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchBenefits.loading = false;
        draft.list = payload.results;
      }),
    [fetchBenefits.FAILURE]: state =>
      produce(state, draft => {
        draft.requests.fetchBenefits.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default benefitsReducer;
