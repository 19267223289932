import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/App/Layout';

import CertificationList from '../../components/Certifications/CertificationList';
import PageTitle from '../../components/App/PageTitle';
import { Typography, Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { useGroupedCertifications } from '../../store/hooks/certifications';
import { useTranslation } from 'react-i18next';

const CertificationsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { groupedCertifications, certificationCount } = useGroupedCertifications();

  return (
    <Layout title="Certifications">
      <Box mt={4} mb={2}>
        <Container>
          {certificationCount > 0 && !!groupedCertifications.length ? (
            groupedCertifications.map(group => (
              <React.Fragment key={group.id}>
                <Typography variant="h4" className={classes.title}>
                  {group.name}
                </Typography>
                <CertificationList certifications={group.certifications} />
              </React.Fragment>
            ))
          ) : (
            <>
              <PageTitle
                title={t('You currently do not have any credentials from the Linux Professional Institute.')}
              />
              <Box textAlign="center">
                <Typography>
                  {t('Only active certification holders are eligible for membership. Get certified today and join.')}
                </Typography>
                <Box paddingTop={3}>
                  <Button href={'https://www.lpi.org/'} target="_blank" variant="contained" color="primary">
                    {t('Get Certified')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Layout>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing(),
  },
}));

export default React.memo(CertificationsPage);
