import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchMembershipRules } from '../../store/actions/memberships';
import { fetchActivitiesCategories, createActivity } from '../../store/actions/activities';
import { getSpecCategoriesList, getSubCategoriesList, getRuleMetrics } from '../../store/selectors';
import * as routes from '../../navigation/routes';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';

import Layout from '../../components/App/Layout';

import ActivityForm from '../../components/Activities/ActivityForm';
import Alert from '@material-ui/lab/Alert';
import { withTranslation } from 'react-i18next';

class AddActivityPage extends PureComponent {
  componentDidMount() {
    this.props.fetchActivitiesCategories();
    this.props.fetchMembershipRules();
  }

  submit = values => {
    this.props.createActivity(values, routes.ACTIVITIES_LIST);
  };

  render() {
    const { user, t } = this.props;

    return (
      <Layout title="Add Activity">
        <Box mt={4} mb={2} px={2}>
          {user.can_submit_pdus ? (
            <Card>
              <CardContent>
                <ActivityForm
                  metrics={this.props.metrics}
                  initialValues={this.props.initialValues}
                  specCategories={this.props.specCategories}
                  subCategories={this.props.subCategories}
                  categories={this.props.categories}
                  error={this.props.error}
                  rules={this.props.rules}
                  onSubmit={this.submit}
                />
              </CardContent>
            </Card>
          ) : (
            <Alert severity="error" style={{ marginBottom: 16 }}>
              {t(
                'Only active members and inactive certification holders seeking membership need to submit PDUs. For more information on PDUs, please read',
              )}
              <br />
              <a
                href="https://www.lpi.org/member/pdu-procedures-and-policies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lpi.org/member/pdu-procedures-and-policies
              </a>
            </Alert>
          )}
        </Box>
      </Layout>
    );
  }
}

const safeParseInt = value => {
  const parsed = parseInt(value);
  return isNaN(parsed) ? null : parsed;
};

const mapStateToProps = (state, { location }) => {
  let params = new URLSearchParams(location.search);
  const { memberships } = state;

  const today = new Date();
  const todayFormatted = [
    String(today.getFullYear()),
    String(101 + today.getMonth()).substring(1),
    String(100 + today.getDate()).substring(1),
  ].join('-');

  const initialValues = {
    category_id: safeParseInt(params.get('category_id')),
    sub_category_id: safeParseInt(params.get('sub_category_id')),
    spec_category_id: safeParseInt(params.get('spec_category_id')),
    name: params.get('name') || '',
    description: params.get('description') || '',
    document: null,
    website: params.get('website') || '',
    event_date: params.get('event_date') || todayFormatted,
    units: safeParseInt(params.get('event_date')) || 1,
  };

  return {
    specCategories: getSpecCategoriesList(state),
    subCategories: getSubCategoriesList(state),
    categories: state.activities.categories,
    category: params.get('category'),
    metrics: getRuleMetrics(state),
    error: state.activities.error,
    rules: memberships.rules,
    user: state.auth.user,
    initialValues,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchMembershipRules,
    fetchActivitiesCategories,
    createActivity,
  })(AddActivityPage),
);
