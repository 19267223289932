import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';

const ActivitySummaryDetailsTable = ({ activities }) => {
  const { t } = useTranslation();
  const totals = activities.reduce(
    (acc, cur) => ({
      current: cur.cycle_id ? acc.current + cur.pdu : acc.current,
      next: !cur.cycle_id ? acc.next + cur.pdu : acc.next,
    }),
    { current: 0, next: 0 },
  );

  return (
    <Table style={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right"> {t('PDUs')} </TableCell>
          <TableCell align="right"> {t('Current')} </TableCell>
          <TableCell align="right"> {t('Unused')} </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities.map(a => (
          <TableRow key={a.id}>
            <TableCell>{a.name}</TableCell>
            <TableCell>{a.spec_category_name}</TableCell>
            <TableCell>{formatDate(a.event_date)}</TableCell>
            <TableCell align="right">{a.pdu}</TableCell>
            <TableCell align="right">{a.cycle_id ? a.pdu : ''}</TableCell>
            <TableCell align="right">{!a.cycle_id ? a.pdu : ''}</TableCell>
          </TableRow>
        ))}
        <TableRow key={`total_desc`}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right">PDUs for this cycle</TableCell>
          <TableCell align="right">PDUs for the next cycle</TableCell>
        </TableRow>
        <TableRow key={`totals`}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right">{totals.current}</TableCell>
          <TableCell align="right">{totals.next}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default React.memo(ActivitySummaryDetailsTable);
