import React from 'react';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';

import Menu from './Sidebar/Menu';
import Logo from './Logo';

const drawerWidth = 240;

const Container = styled.div`
  margin-top: 30px;
  height: 64px;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #263238;
  padding: 0px 8px 0px 13px;
`;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#37474f',
    color: '#CFD8DC',
  },
}));

const Spacer = ({ height }) => <Box py={`${height || 5}px`} width="100%" />;

const Sidebar = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <nav className={classes.drawer}>
      <Hidden smUp>
        <Drawer
          open={open}
          onClose={onClose}
          variant="temporary"
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Container>
            <Logo white py={1} my={1} />
          </Container>
          <Spacer />
          <Menu />
        </Drawer>
      </Hidden>

      <Hidden xsDown>
        <Drawer
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          data-testid="desktop-sidebar"
        >
          <Container>
            <Logo white py={1} my={1} />
          </Container>
          <Spacer />
          <Menu />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default React.memo(Sidebar);
