import { takeLatest, put, all, call } from 'redux-saga/effects';
import { FETCH_CERTIFICATIONS, FETCH_SCOREBOARD, FETCH_ACHIEVEMENTS } from '../actions/certifications/types';
import {
  fetchCertificationsSuccess,
  fetchCertificationsFailure,
  fetchScoreboardSuccess,
  fetchScoreboardFailure,
  fetchAchievementsSuccess,
  fetchAchievementsFailure,
} from '../actions/certifications';
import odoo from '../../backend';

function* fetchCertificationsSaga() {
  try {
    const certifications = yield call([odoo, odoo.fetchCertifications]);
    yield put(fetchCertificationsSuccess(certifications.results));
  } catch (error) {
    yield put(fetchCertificationsFailure(error));
  }
}

function* fetchScoreboardSaga() {
  try {
    const scoreboard = yield call([odoo, odoo.fetchScoreboard]);
    yield put(fetchScoreboardSuccess(scoreboard));
  } catch (error) {
    yield put(fetchScoreboardFailure(error));
  }
}

function* fetchAchievementsSaga() {
  try {
    const achievements = yield call([odoo, odoo.fetchAchievements]);
    yield put(fetchAchievementsSuccess(achievements.results));
  } catch (error) {
    yield put(fetchAchievementsFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([FETCH_CERTIFICATIONS], fetchCertificationsSaga),
    yield takeLatest([FETCH_SCOREBOARD], fetchScoreboardSaga),
    yield takeLatest([FETCH_ACHIEVEMENTS], fetchAchievementsSaga),
  ]);
}
