import * as types from '../actions/auth/types';

const INITIAL_STATE = {
  user: null,
  loginSubmitting: false,
  loginError: '',
  requests: {
    update: { loading: false, error: null },
  },
};

const legacyAuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN: {
      return {
        ...state,
        loginError: '',
        loginSubmitting: true,
      };
    }
    case types.FETCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case types.AUTH_LOGIN_SUCCESS: {
      return {
        ...INITIAL_STATE,
        user: action.payload.user,
      };
    }
    // Update User
    case types.UPDATE_USER_INFO: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: { loading: true, error: null },
        },
      };
    }
    case types.UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        requests: {
          ...state.requests,
          update: { loading: false, error: null },
        },
      };
    }
    case types.UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: { loading: false, error: action.payload },
        },
      };
    }
    // End Update User
    case types.ACCEPT_COC_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case types.AUTH_LOGIN_FAILURE: {
      return {
        ...INITIAL_STATE,
        loginError: action.payload,
      };
    }
    case types.AUTH_LOGOUT_SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default legacyAuthReducer;
