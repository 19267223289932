import React, { useEffect, useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { JOBS_OFFERS } from '../../navigation/routes';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Grid, Paper, Container, Hidden, CircularProgress, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InView } from 'react-intersection-observer';

import Layout from '../../components/App/Layout';
import OfferPreview from '../../components/Jobs/OfferPreview';
import OffersDetails from '../../components/Jobs/OffersDetail';

import CertificateSelect from '../../components/Jobs/Filters/CertificateSelect';
import ContractTypeSelect from '../../components/Jobs/Filters/ContractTypeSelect';
import { getTranslatedJobType, getTranslatedSortName } from '../../utils/getTranslatedEnum';
import FiltersV2 from '../../components/App/Filters/FiltersV2';
import usePeopleOfferSearch from '../../hooks/Jobs/usePeopleOfferSearch';
import usePeopleOffer from '../../hooks/Jobs/usePeopleOffer';
import SortSelect from '../../components/Jobs/Filters/SortSelect';
import CountryInput from '../../components/Jobs/Filters/CountryInput';
import StateInput from '../../components/Jobs/Filters/StateInput';
import CityInput from '../../components/Jobs/Filters/CityInput';
import SkillsSelect from '../../components/Jobs/Filters/SkillsSelect';
import JobOptions from '../../components/Jobs/Filters/JobOptions';
import SavedFilters from '../../components/Jobs/Filters/SavedFilters';
import LanguageSelect from '../../components/Jobs/Filters/LanguageSelect';

const OffersPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isUpSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [offerDetails, setOfferDetails] = useState(null);
  const {
    params: { id },
  } = useRouteMatch();
  const singleOffer = usePeopleOffer(id);

  const { status, offers, filtersController, pagination, dataUpdatedAt } = usePeopleOfferSearch({ favorite: false });

  useEffect(() => {
    // First mount, if url contains an id, load the selected offer or null
    if (singleOffer.status === 'success' && !offerDetails && !!id) {
      setOfferDetails(singleOffer.offer);
    }
  }, [singleOffer.status]);

  useEffect(() => {
    if (!!offerDetails) {
      // Data update, replace offerDetails obj with newest data
      const offer = offers.find(offer => offer.id == offerDetails?.id) || null;
      setOfferDetails(offer);
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    // Refresh URL on offerDetails change
    window.history.replaceState(
      null,
      null,
      (!!offerDetails ? JOBS_OFFERS + '/' + offerDetails.id : JOBS_OFFERS) + window.location.search,
    );
  }, [offerDetails]);

  return (
    <Layout title={t('Job Offers')}>
      <Tabs
        className={classes.tabs}
        centered
        value={filtersController.filters.favorite ? TABS.FAV : TABS.ALL}
        onChange={(_e, newTabIndex) => {
          filtersController.setFilters({ ...filtersController.filters, favorite: newTabIndex === TABS.FAV });
        }}
      >
        <Tab label={t('All')} index={TABS.ALL} />
        <Tab label={t('Favorites')} index={TABS.FAV} />
      </Tabs>

      <FiltersV2 chips={getChips(filtersController, t)} className={classes.filters}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SortSelect controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <CertificateSelect controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <SkillsSelect controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <ContractTypeSelect controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <LanguageSelect controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <CountryInput controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <StateInput controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <CityInput controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <JobOptions controller={filtersController} />
          </Grid>

          <Grid item xs={12}>
            <SavedFilters controller={filtersController} />
          </Grid>
        </Grid>
      </FiltersV2>

      <Box p={1} m={0} {...(isUpSm && { p: 2, m: 2, mt: 15 })}>
        <Grid container justify="center">
          <Hidden smDown={!!offerDetails}>
            <Grid item xs={12} {...(!!offerDetails && { md: 7 })}>
              <Container maxWidth="md">
                {(() => {
                  switch (status) {
                    case 'idle':
                    case 'loading':
                      return (
                        <Box display="flex" height="50vh" alignItems="center" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      );

                    case 'error':
                      return (
                        <Box display="flex" height="50vh" alignItems="center" justifyContent="center">
                          <Typography>{t('An error occurred during the request')}</Typography>
                        </Box>
                      );

                    case 'success':
                      if (!offers.length) {
                        return (
                          <Box display="flex" height="50vh" alignItems="center" justifyContent="center">
                            <Typography>{t('No offers found with these criteria')}</Typography>
                          </Box>
                        );
                      } else {
                        return (
                          <Grid container spacing={2}>
                            {offers.map(offer => (
                              <OfferPreview key={offer.id} offer={offer} onClick={setOfferDetails} />
                            ))}
                          </Grid>
                        );
                      }
                  }
                })()}

                {/* PROGRESS */}
                {pagination.isFetchingNextPage && (
                  <Box display="flex" height="80px" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                  </Box>
                )}

                {/* SCROLL OBSERVER */}
                <InView
                  as="div"
                  onChange={inView => !!inView && !!pagination.hasNextPage && pagination.fetchNextPage()}
                  rootMargin="300px"
                >
                  <React.Fragment />
                </InView>
              </Container>
            </Grid>
          </Hidden>

          {/* OFFERS DETAILS */}
          {!!offerDetails && <ScrollBox offerDetails={offerDetails} onClose={setOfferDetails} />}
        </Grid>
      </Box>
    </Layout>
  );
};

const ScrollBox = ({ offerDetails, onClose }) => {
  const detailsBox = useRef();
  const [scroll, setScroll] = useState(window.scrollY);
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    window.onscroll = () => {
      setScroll(window.scrollY);
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    // Return to top of details box
    if (detailsBox.current) {
      detailsBox.current.scroll(0, 0);
    }
  }, [offerDetails]);

  return (
    <Grid
      item
      xs={12}
      md={5}
      style={{
        position: 'relative',
        height: '100%',
        ...(isUpMd && { transition: 'all 0.25s', top: scroll }),
      }}
    >
      <Paper ref={detailsBox} elevation={0} {...(isUpMd && { style: { maxHeight: '80vh', overflow: 'auto' } })}>
        <OffersDetails offer={offerDetails} onClose={onClose} />
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },

  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },

  tabs: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      top: 94,
      left: 240,
      right: 0,
      zIndex: 1000,
    },

    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },

  filters: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      top: 142,
      left: 240,
      right: 0,
      zIndex: 1000,
    },

    backgroundColor: theme.palette.background.default,
  },
}));

const TABS = {
  ALL: 0,
  FAV: 1,
};

/** Define one or multiple { label: string, onDelete: fn } chip obj for each filter type */
const getChips = ({ filters, setFilters }, t) => {
  const chips = [];

  Object.keys(filters).forEach(key => {
    switch (key) {
      case 'certificates':
        return filters.certificates.map(certificate => {
          chips.push({
            label: certificate.name,
            onDelete: () =>
              setFilters({
                ...filters,
                certificates: filters.certificates.filter(_certificate => _certificate.id != certificate.id),
              }),
          });
        });

      case 'skills':
        return filters.skills.map(skill => {
          chips.push({
            label: skill.name,
            onDelete: () =>
              setFilters({
                ...filters,
                skills: filters.skills.filter(_skill => _skill.id != skill.id),
              }),
          });
        });

      case 'contractTypes':
        return filters.contractTypes.map(type => {
          chips.push({
            label: getTranslatedJobType(type, t),
            onDelete: () =>
              setFilters({
                ...filters,
                contractTypes: filters.contractTypes.filter(_type => _type != type),
              }),
          });
        });

      case 'sort':
        if (filters.sort !== 'relevance') {
          chips.push({
            label: getTranslatedSortName(filters.sort, t),
            onDelete: () =>
              setFilters({
                ...filters,
                sort: null,
              }),
          });
        }
        break;

      case 'country':
        return chips.push({
          label: filters.country.name,
          onDelete: () =>
            setFilters({
              ...filters,
              country: null,
            }),
        });

      case 'state':
        return chips.push({
          label: filters.state.name,
          onDelete: () =>
            setFilters({
              ...filters,
              state: null,
            }),
        });

      case 'city':
        return chips.push({
          label: filters.city.charAt(0).toUpperCase() + filters.city.slice(1),
          onDelete: () =>
            setFilters({
              ...filters,
              city: null,
            }),
        });

      case 'inplace':
        return chips.push({
          label: t('In office'),
          onDelete: () =>
            setFilters({
              ...filters,
              inplace: false,
            }),
        });

      case 'remote':
        return chips.push({
          label: t('Remote'),
          onDelete: () =>
            setFilters({
              ...filters,
              remote: false,
            }),
        });

      case 'remote_international':
        return chips.push({
          label: t('Remote international'),
          onDelete: () =>
            setFilters({
              ...filters,
              remote_international: false,
            }),
        });
    }
  });

  return chips;
};

export default React.memo(OffersPage);
