import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useLanguages from '../../../hooks/useLanguages';

const LanguagesSelect = ({ controller: { filters, setFilters } }) => {
  const { status, languages } = useLanguages();

  return (
    <Autocomplete
      multiple
      options={languages}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} label="Filter by language" variant="outlined" />}
      loading={status === 'loading'}
      value={filters.languages || []}
      onChange={(_e, languages) => setFilters({ ...filters, languages })}
    />
  );
};

export default React.memo(LanguagesSelect);
