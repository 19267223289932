import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getTranslatedJobType } from '../../../utils/getTranslatedEnum';
import { CONTRACT_TYPE } from '../../../consts';

const ContractTypeSelect = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple
      options={CONTRACT_TYPE}
      getOptionLabel={option => getTranslatedJobType(option, t)}
      renderInput={params => <TextField {...params} label="Filter by employment type" variant="outlined" />}
      value={filters.contractTypes || []}
      onChange={(_e, contractTypes) => setFilters({ ...filters, contractTypes })}
    />
  );
};

export default React.memo(ContractTypeSelect);
