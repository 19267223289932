import _keyBy from 'lodash/keyBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useLocation, useHistory, useParams } from 'react-router-dom';
import { useCoupons } from '../../store/hooks/coupons';
import { COUPONS } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import Layout from '../../components/App/Layout';
import CouponsTable from '../../components/Vouchers/CouponsTable';
import CouponsDetail from '../../components/Vouchers/CouponsDetail';
import Typography from '@material-ui/core/Typography';

const TABS = { NEW: 0, USED: 1 };

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const CouponsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const rootPath = COUPONS;
  const location = useLocation();
  let history = useHistory();
  const { id } = useParams();

  const detail_route_match = useRouteMatch({
    path: rootPath + '/:id/',
  });

  const [activeTab, setActiveTab] = React.useState(0);

  const coupons = useCoupons();

  const [item, setItem] = React.useState(null);
  const [shownItems, setShownItems] = React.useState([]);

  React.useEffect(() => {
    let tempItem = _keyBy(coupons.all, 'id')[id];
    if (tempItem) {
      setItem(tempItem);
    }
  }, [id, coupons.all, history]);

  const handleRowClick = React.useCallback(
    id => {
      history.push({
        pathname: rootPath + '/' + id,
        search: location.search,
      });
    },
    [history, location, rootPath],
  );

  const handleCloseDetail = React.useCallback(() => {
    history.push({
      pathname: rootPath,
      search: location.search,
    });
  }, [history, location, rootPath]);

  React.useEffect(() => {
    handleCloseDetail();
    setShownItems(activeTab === TABS.NEW ? coupons.new : coupons.old);
    // eslint-disable-next-line
  }, [activeTab, coupons])

  return (
    <React.Fragment>
      <Layout title={t('Coupons')} back={!!detail_route_match} backPath={rootPath}>
        <Tabs
          centered
          className={classes.tabs}
          value={activeTab}
          onChange={(event, newTabIndex) => {
            setActiveTab(newTabIndex);
          }}
        >
          <Tab label={t('New')} index={TABS.NEW} />
          <Tab label={t('Used')} index={TABS.USED} />
        </Tabs>

        <Box display="flex" justifyContent="space-between" pl={2} m={2} flexDirection="row">
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('My Coupons')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Coupons can be used to get discounts on your next order')}
            </Typography>
          </Box>
          {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
        </Box>

        <Box mt={4} px={2}>
          <Grid container spacing={3} display="flex">
            <Grid item xs={12} sm>
              <Card variant="outlined">
                <CardContent classes={{ root: classes.customCardRoot }}>
                  <CouponsTable rows={shownItems} onRowClick={handleRowClick} />
                </CardContent>
              </Card>
            </Grid>
            <Switch>
              <Route path={rootPath + '/:id(\\d+)'}>
                <Grid item xs={12} lg={4}>
                  <CouponsDetail item={item} onCloseClick={handleCloseDetail} />
                </Grid>
              </Route>
            </Switch>
          </Grid>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default React.memo(CouponsPage);
