import _values from 'lodash/values';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchScoreboard } from '../../store/actions/certifications';

import Layout from '../../components/App/Layout';
import GroupList from '../../components/Certifications/GroupList';

class ScorePage extends PureComponent {
  componentDidMount() {
    this.props.fetchScoreboard();
  }

  render() {
    const { scoreboard, groups, certifications } = this.props;
    return (
      <Layout title="Scoreboard">
        <GroupList scoreboard={scoreboard} groups={groups} certifications={certifications} />
      </Layout>
    );
  }
}

const mapStateToProps = ({ certifications }) => {
  return {
    scoreboard: certifications.scoreboard,
    groups: certifications.groups,
    certifications: _values(certifications.list),
  };
};

export default connect(mapStateToProps, { fetchScoreboard })(ScorePage);
