import React, { useState } from 'react';
import { Box, Typography, Drawer, makeStyles, Toolbar, Grid, Chip, Tooltip, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300,
  },
  filtersWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const FiltersV2 = ({ children, chips, ...args }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box {...args}>
      <Toolbar>
        <Grid container justify="space-between" wrap="nowrap">
          {/* CHIPS */}
          <Grid item container spacing={1} alignItems="center">
            {chips.map(chip => (
              <Grid item key={chip.label}>
                <Chip label={chip.label} onDelete={chip.onDelete} />
              </Grid>
            ))}
          </Grid>

          {/* DRAWER BUTTON */}
          <Grid item>
            <Tooltip title={t('Filters')}>
              <IconButton onClick={() => setDrawerOpen(true)} data-testid="drawer-button">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>

      {/* DRAWER */}
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box p={3} className={classes.filtersWrapper}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('Filters')}
            </Typography>
            {children}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FiltersV2;
