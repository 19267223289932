import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getActiveCertifications, getUser } from '../../store/selectors';

import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const ShareVerificationDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const certifications = useSelector(getActiveCertifications);
  const user = useSelector(getUser);

  const { verificationCode, onClose, visible } = props;

  function handleClose() {
    onClose();
  }

  function getLink(lpiId, verificationCode) {
    return `https://lpi.org/verify/${lpiId}/${verificationCode}`;
  }

  if (!verificationCode && !certifications.length) {
    return null;
  }

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth>
      <DialogTitle>{t('Share your certification')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t('Use this link to share')}{' '}
          <a
            href={getLink(user.lpiid, verificationCode || certifications[0].version_code)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('your LPI certifications')}
          </a>
        </Typography>
        <TextField
          className={classes.input}
          fullWidth
          value={getLink(user.lpiid, verificationCode || certifications[0].version_code)}
          variant="outlined"
          disabled
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '16px 0px',
  },
});

ShareVerificationDialog.propTypes = {
  visibile: PropTypes.bool,
  verificationCode: PropTypes.string,
  onClose: PropTypes.func,
};

export default ShareVerificationDialog;
