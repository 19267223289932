import { useQuery, useQueryClient, useMutation } from 'react-query';
import api from '../backend/index';

export const QUERY_KEY = 'notifications';

/* Get notification */
export const useNotification = (id, options) => {
  const req = useQuery([QUERY_KEY, id], () => api.notifications.get(id), options);

  return {
    notification: req.data?.results || null,
    ...req,
  };
};

/* Search notifications */
export const useNotifications = (params, options) => {
  const req = useQuery([QUERY_KEY, params], () => api.notifications.search(params), options);

  return {
    notifications: req.data?.results || [],
    ...req,
  };
};

/* Mutate notification */
export const useNotificationMutation = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const remove = useMutation(id => api.notifications.delete(id), {
    onSuccess: invalidateQuery,
  });

  const markAsRead = useMutation(id => api.notifications.markAsRead(id), {
    onSuccess: invalidateQuery,
  });

  const markAllAsRead = useMutation(() => api.notifications.markAllAsRead(), {
    onSuccess: invalidateQuery,
  });

  return {
    remove,
    markAsRead,
    markAllAsRead,
  };
};
