import _values from 'lodash/values';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { applyOpportunity, saveOpportunity } from '../../store/reducers/VolunteersReducer';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import Column from '../Common/TableColumn';
import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';
import Table from '../Common/Table';
import OpportunityDetail from './OpportunityDetail';
import { getTranslatedApplicationStatus } from '../../utils/getTranslatedEnum';
import { VOLUNTEER_APPLICATION_LIST } from '../../navigation/routes';

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

const AppliedOpportunitiesTable = ({ items }) => {
  const { t } = useTranslation();
  const [shownItems, setShownItems] = React.useState([]);

  const classes = useStyles();

  React.useEffect(() => {
    setShownItems(items.filter(app => !!app.opportunity));
  }, [items]);

  const location = useLocation();
  let history = useHistory();

  const { id } = useParams();
  const [item, setItem] = React.useState();

  const handleRowClick = React.useCallback(
    id => {
      history.push({
        pathname: VOLUNTEER_APPLICATION_LIST + '/' + id,
        search: location.search,
      });
    },
    [history, location],
  );

  React.useEffect(() => {
    let tempItem = items.find(i => i.id == id);
    if (tempItem) {
      setItem(tempItem.opportunity);
    }
  }, [id, items, history]);

  const handleCloseDetail = React.useCallback(() => {
    setItem(null);
    history.push({
      pathname: VOLUNTEER_APPLICATION_LIST,
      search: location.search,
    });
  }, [history, location]);

  return (
    <Grid container spacing={3} display="flex">
      <Grid item xs={12} sm>
        <Card variant="outlined">
          <CardContent classes={{ root: classes.customCardRoot }}>
            <Table rows={shownItems} onRowClick={handleRowClick}>
              <Column label={t('Title')} renderDepends={item => (item.opportunity ? item.opportunity.name : '')} />
              <Column
                label={t('Program')}
                renderDepends={item => (item.opportunity ? item.opportunity.program_name : '')}
              />
              <Column
                label={t('Location')}
                renderDepends={item => (item.opportunity ? item.opportunity.location : '')}
              />
              <Column
                label={t('Application Deadline')}
                renderDepends={item =>
                  item.opportunity
                    ? item.opportunity.deadline
                      ? new Date(item.opportunity.deadline).toLocaleDateString()
                      : ''
                    : ''
                }
              />
              <Column
                label={t('Status')}
                renderDepends={item => item.status && getTranslatedApplicationStatus(item.status)}
              />
            </Table>
          </CardContent>
        </Card>
      </Grid>
      {item && (
        <Grid item xs={12} sm={4}>
          <OpportunityDetail item={item} onCloseClick={handleCloseDetail} />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(AppliedOpportunitiesTable);
