import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { formatDate, getActivityStatus } from '../../utils';
import { useTranslation } from 'react-i18next';

const ActivityCard = styled(Paper)``;

const ActivityListItem = ({ activity }) => {
  const { t } = useTranslation();
  return (
    <Box marginBottom={1}>
      <ActivityCard>
        <Box padding={2}>
          <Box display="flex" justifyContent="space-between">
            <Box fontSize={20}>{activity.name}</Box>

            <Box bgcolor="primary.main" borderRadius="16px" color="#ffffff" paddingY="8px" paddingX="16px">
              {activity.pdu} {t('PDU')}
            </Box>
          </Box>
          <Box fontSize={12} color="text.hint">
            {activity.category_name} - {activity.sub_category_name}
          </Box>
          <Box marginTop={2} color="text.secondary">
            {activity.description}
          </Box>
          <Box marginTop={2} display="flex" justifyContent="space-between">
            <Box fontSize={12} color="text.hint">
              {t('On')} {formatDate(activity.event_date)}
            </Box>
            <Box>{getActivityStatus(activity.state)}</Box>
          </Box>
          {activity.state !== 'accepted' && (
            <Box style={{ textAlign: 'right', marginTop: 8 }}>
              <a href={`/activities/${activity.id}/edit`}>{t('Edit Activity')}</a>
            </Box>
          )}
        </Box>
      </ActivityCard>
    </Box>
  );
};

ActivityListItem.propTypes = {
  activity: PropTypes.object,
};

export default React.memo(ActivityListItem);
