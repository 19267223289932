import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useFilters from '../useFilters';
import api from '../../backend';

const QUERY_KEY = 'documents';

const initialFilters = {
  tags: [],
};

/** Get single documents, documents nested in named folders and a controller for filters  */
const useDocuments = () => {
  const [folders, setFolders] = useState([]);
  const { filters, setFilters } = useFilters(initialFilters);
  const req = useQuery([QUERY_KEY /* filters */], () => api.documents.search(filters));

  /* Nest documents it their related folders to organize them in a tree-logic component  */
  useEffect(() => {
    if (req.status === 'success') {
      let documents = [...req.data];
      const _folders = [];

      // Apply tag filters to incoming data
      if (filters.tags?.length) {
        documents = documents.filter(doc => filters.tags.every(tag => doc.tags?.includes(tag)));
      }

      documents.forEach(doc => {
        // Get index of parent folder in _folders
        const folderIndex = _folders.findIndex(folder => folder.id === doc.folder_id);

        // If found, push doc in folders.documents array, else create a new folder and put it inside
        if (folderIndex !== -1) {
          _folders[folderIndex].documents.push(doc);
        } else {
          _folders.push({
            id: doc.folder_id,
            name: doc.folder_name,
            documents: [doc],
          });
        }
      });

      setFolders(_folders);
    }
  }, [req.status, filters]);

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    controller: {
      filters,
      setFilters,
    },
    folders,
    documents: req.data || [],
    ...req,
  };
};

export default useDocuments;
