import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../backend';

const QUERY_KEY = 'helpdesk_ticket';

/** Get a single location */
const useHelpdeskTicket = id => {
  const queryClient = useQueryClient();
  const req = useQuery([QUERY_KEY, id], () => api.tickets.get(id));

  const sendMessage = useMutation(
    ({ message }) =>
      api.tickets.sendMessage(id, {
        message,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('helpdesk_tickets');
        queryClient.invalidateQueries([QUERY_KEY, id]);
      },
    },
  );

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    ticket: req.data || null,
    sendMessage,
    ...req,
  };
};

export default useHelpdeskTicket;
