import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ActivitySummaryDetailsTable from './ActivitySummaryDetailsTable';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ActivitySummaryDetailsSection = ({ section, activities }) => {
  const { t } = useTranslation();
  const activitiesBySubCategory = _groupBy(activities, 'sub_category_name');

  const totals = activities.reduce(
    (acc, cur) => ({
      current: cur.cycle_id ? acc.current + cur.pdu : acc.current,
      next: !cur.cycle_id ? acc.next + cur.pdu : acc.next,
      total: acc.total + cur.pdu,
    }),
    { current: 0, next: 0, total: 0 },
  );

  return (
    <Box marginBottom={2}>
      <Paper>
        <Box padding={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">{section}</Typography>
          <Box textAlign="right">
            <Typography variant="h5">
              {totals.total} {t('PDU')}
            </Typography>
            <Typography variant="subtitle1">
              {t('Current')}: {totals.current} {t('PDU')}
            </Typography>
            <Typography variant="subtitle1">
              {t('Unused')}: {totals.next} {t('PDU')}
            </Typography>
          </Box>
        </Box>
        {_keys(activitiesBySubCategory).map(section => (
          <Box padding={2} key={section}>
            <Typography variant="h6">{section}</Typography>
            <ActivitySummaryDetailsTable activities={activitiesBySubCategory[section]} />
          </Box>
        ))}
      </Paper>
    </Box>
  );
};

export default React.memo(ActivitySummaryDetailsSection);
