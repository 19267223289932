import _keyBy from 'lodash/keyBy';
import * as types from '../actions/activities/types';

const INITIAL_STATE = {
  pending: false,
  list: [],
  error: null,
  categories: null,
  loaded: false,
  claimDialogs: {},
};

const activitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: {
          ...state.list,
          [action.payload.id]: action.payload,
        },
      };
    case types.FETCH_ACTIVITIES:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: _keyBy(action.payload, 'id'),
        claimDialogs: _keyBy(
          action.payload.map(
            a =>
              state.claimDialogs[a.id] || {
                open: false,
                loading: false,
                id: a.id,
              },
          ),
          'id',
        ),
      };
    case types.FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case types.FETCH_ACTIVITY_CATEGORIES_SUCCESS:
      return {
        ...state,
        loaded: true,
        categories: action.payload,
      };
    case types.CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: action.payload,
        },
        claimDialogs: {
          ...state.claimDialogs,
          [action.payload.id]: {
            id: action.payload.id,
            open: false,
            loading: false,
          },
        },
      };
    case types.CREATE_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.EDIT_ACTIVITY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: action.payload,
        },
      };
    case types.OPEN_CLAIM_DIALOG:
      return {
        ...state,
        claimDialogs: {
          ...state.claimDialogs,
          [action.payload.id]: {
            ...state.claimDialogs[action.payload.id],
            open: true,
          },
        },
      };
    case types.DISMISS_CLAIM_DIALOG:
      return {
        ...state,
        claimDialogs: {
          ...state.claimDialogs,
          [action.payload.id]: {
            ...state.claimDialogs[action.payload.id],
            open: false,
          },
        },
      };
    case types.CLAIM_ACTIVITY:
      return {
        ...state,
        claimDialogs: {
          ...state.claimDialogs,
          [action.payload.activity_id]: {
            ...state.claimDialogs[action.payload.activity_id],
            loading: true,
          },
        },
      };
    case types.CLAIM_ACTIVITY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: action.payload,
        },
        claimDialogs: {
          ...state.claimDialogs,
          [action.payload.id]: {
            ...state.claimDialogs[action.payload.id],
            loading: false,
          },
        },
      };
    default:
      return state;
  }
};

export default activitiesReducer;
