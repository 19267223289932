import React from 'react';
import LogoReverse from '../../../assets/images/logo-reverse-sm.png';
import Box from '@material-ui/core/Box';

const LpiLabel = () => {
  return (
    <Box>
      <img src={LogoReverse} height="60" alt="lpi" />
    </Box>
  );
};

export default React.memo(LpiLabel);
