import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend/index';

export const QUERY_KEY = 'jobs.applications.all';

const useApplications = () => {
  const res = useQuery([QUERY_KEY], () => api.applications.search());

  /* Log fetching errors */
  useEffect(() => {
    if (res.error) console.error('Error during request:', res.error);
  }, [res.error]);

  return {
    applications: res.data?.results || [],
    ...res,
  };
};

export default useApplications;
