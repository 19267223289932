import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FileUpload from '../Forms/FileUpload';
import { CircularProgress, Typography } from '@material-ui/core';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

const validate = values => {
  const errors = {};
  if (!values.about_applicant) {
    errors['about_applicant'] = i18n.t('This field is required');
  }
  if (!values.resume_file) {
    errors['resume_file'] = i18n.t('No resume attached. Please attach a document');
  }
  if (values.resume_link && !validURL(values.resume_link)) {
    errors['resume_link'] = i18n.t('You should insert a valid URL');
  }

  return errors;
};

const OffersApplyModal = ({ open, jobName, handleSubmit, handleClose, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('Apply for ') + jobName}</DialogTitle>

      <Formik
        initialValues={{
          about_applicant: '',
          resume_link: '',
          resume_filename: '',
          resume_file: '',
          include_email: true,
          include_phone: true,
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ handleSubmit, handleChange, errors, touched, values }) => (
          <React.Fragment>
            <DialogContent dividers>
              <FormikForm>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    name="about_applicant"
                    required
                    label={t('About me')}
                    onChange={handleChange('about_applicant')}
                    value={values['about_applicant']}
                    error={touched['about_applicant'] && !!errors['about_applicant']}
                  />
                  <ErrorMessage mt="2" name="about_applicant" component="div" color="red" />
                </Box>
                <Box mb={2}>
                  <Grid item container spacing={0} justify="center">
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="include_email"
                            onChange={handleChange('include_email')}
                            checked={values['include_email']}
                            error={touched['include_email'] && errors['include_email']}
                          />
                        }
                        label={t('Include email')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="include_phone"
                            onChange={handleChange('include_phone')}
                            checked={values['include_phone']}
                            error={touched['include_phone'] && errors['include_phone']}
                          />
                        }
                        label={t('Include phone')}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={4}>
                  <FileUpload
                    label={t('Upload Resume')}
                    color="secondary"
                    name="resume_file"
                    onChange={(filename, file) => {
                      handleChange('resume_filename')(filename);
                      handleChange('resume_file')(file);
                    }}
                    value={values['resume_file']}
                    error={touched['resume_file'] && errors['resume_file']}
                  />
                  <ErrorMessage mt="2" name="resume_file" component="div" color="red" />
                </Box>
                <Box mb={4}>
                  <TextField
                    fullWidth
                    name="resume_link"
                    label={t('Resume Links (LinkedIn, Github, ...)')}
                    onChange={handleChange('resume_link')}
                    value={values['resume_link']}
                    error={touched['resume_link'] && errors['resume_link']}
                    helperText={t('Insert a valid URL (eg. ') + 'http://github.com/example)'}
                  />
                </Box>
              </FormikForm>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="default" disabled={isLoading}>
                {t('Cancel')}
              </Button>
              <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isLoading}>
                {isLoading ? <CircularProgress size={20} color="primary" /> : t('Ok')}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(OffersApplyModal);
