import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH = 'PersonalPage.Fetch';
export const fetchInfo = createRoutine(FETCH);

const INITIAL_STATE = {
  info: null,
  loading: false,
  error: null,
};

const personalPageReducer = handleActions(
  {
    [fetchInfo.TRIGGER]: state =>
      produce(state, draft => {
        draft.loading = true;
        draft.error = null;
      }),
    [fetchInfo.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
        draft.info = payload;
      }),
    [fetchInfo.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
        draft.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default personalPageReducer;
