import React from 'react';
import { Input } from '@material-ui/icons';
import { TextField } from '@material-ui/core';

const CHANGE_DELAY_MS = 1500;
let timer = null;

const CityInput = ({ controller: { filters, setFilters } }) => {
  const handleChange = event => {
    event.persist();

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => setFilters({ ...filters, city: event.target.value }), CHANGE_DELAY_MS);
  };

  return <TextField fullWidth label="City" variant="outlined" defaultValue={filters.city} onChange={handleChange} />;
};

export default CityInput;
