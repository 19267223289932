import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    fontSize: '14px',
    cursor: 'pointer',
    padding: '6px 16px',
    zIndex: 10,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const ActionButton = ({ onClick, children, ...props }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={onClick} startIcon={<AddIcon />} {...props}>
      {children}
    </Button>
  );
};

export default React.memo(ActionButton);
