import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Close from '@material-ui/icons/Close';
import { Button, CircularProgress } from '@material-ui/core';

import CopyToClipboard from '../Common/CopyToClipboard';
import LabelledValue from '../Common/LabelledValue';
import useCart from '../../hooks/Shop/useCart';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import { openCart } from '../../store/reducers/ShopReducer';
import parseError from '../../utils/parseError';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
});

const CouponsDetail = ({ item, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSuccess, cart, applyCoupon, mutationStatus } = useCart();

  const hasCouponInCart = useMemo(() => cart.lines?.items.some(item => !!item.is_reward), [cart]);

  const handleApplyCoupon = () => {
    applyCoupon(item.code)
      .then(() => {
        dispatch(setMessage(t('Coupon applied')));
        dispatch(openCart());
      })
      .catch(err => {
        dispatch(setMessage(parseError(err)));
      });
  };

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
          }}
          title={t('COUPON')}
          subheader={
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <CopyToClipboard
                title={t('Copied to clipboard!')}
                leaveDelay={1500}
                render={copy => (
                  <Link
                    href="#"
                    color="secondary"
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      copy(item.code);
                    }}
                  >
                    {item.code}
                  </Link>
                )}
              />
            </Box>
          }
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <IconButton onClick={props.onCloseClick}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <LabelledValue label={t('Program')}>{item.program_name}</LabelledValue>
          <LabelledValue label={t('Partner')}>{item.lpi_partner_name}</LabelledValue>
          <LabelledValue label={t('Expiring Date')}>{item.expiration_date}</LabelledValue>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            onClick={handleApplyCoupon}
            variant="contained"
            color="primary"
            disabled={!isSuccess || hasCouponInCart}
          >
            {(() => {
              if (!isSuccess || mutationStatus.applyCoupon === 'loading') {
                return <CircularProgress size={25} color="secondary" />;
              }

              if (hasCouponInCart) {
                return t('A coupon is already in your cart');
              }

              return t('Apply coupon');
            })()}
          </Button>
        </Box>
      </Card>
    )
  );
};

export default React.memo(CouponsDetail);
