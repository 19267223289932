import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { CHECKOUT_ORDER } from '../../../navigation/routes';
import { Link, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import useMembershipMutations from '../../../hooks/Membership/useMembershipMutations';
import { fetchUserInfo } from '../../../store/reducers/AuthReducer';
import PageTitle from '../../App/PageTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MembershipPlan from '../MembershipPlan';
import { addMonths, isBefore } from 'date-fns';
import ProductList from '../ProductList';

const MemberStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.auth.user);
  const membership = useSelector(({ memberships }) => memberships);
  const { renew } = useMembershipMutations();

  const expire = new Date(user.membership_stop);

  const handleSubmit = useCallback(
    productId => {
      renew.mutate(productId, {
        onSuccess: data => {
          dispatch(fetchUserInfo());
          history.push(CHECKOUT_ORDER.replace(':orderId', data.order_id));
        },
      });
    },
    [dispatch],
  );

  if (!user) return null;

  return (
    <Box>
      <MembershipPlan user={user} />
      {user.membership_step === 'member' && isBefore(expire, addMonths(new Date(), 3)) && (
        <Box marginTop={2}>
          <Container>
            <PageTitle title={t('Your memebership will expire soon')} />
            {user.membership_renewal_order_id ? (
              <Box>
                <Typography variant="body1">
                  {t('You can now proceed to checkout phase to renew your LPI Membership period.')}
                </Typography>
                <br />
                <Button
                  component={Link}
                  to={CHECKOUT_ORDER.replace(':orderId', user.membership_renewal_order_id)}
                  variant="contained"
                  color="primary"
                >
                  {t('PAY MEMBERSHIP DUES')}
                </Button>
              </Box>
            ) : (
              <>
                <Box marginBottom={2}>
                  <Typography variant="body1">{t('Choose your Membership renew period.')}</Typography>
                </Box>
                <ProductList
                  products={membership.products}
                  submitting={membership.submitting}
                  onSubmit={handleSubmit}
                  renew
                  disabled={renew.isLoading}
                />
              </>
            )}
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(MemberStep);
