import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_COUPONS = 'Coupons.Fetch';
export const CLAIM_COUPON = 'Coupons.Claim';
export const CREATE_REFERRAL_COUPON = 'Coupons.Referral';

export const fetchCoupons = createRoutine(FETCH_COUPONS);
export const claimCoupon = createRoutine(CLAIM_COUPON);
export const createReferralCoupon = createRoutine(CREATE_REFERRAL_COUPON);

const INITIAL_REQUEST_STATE = {
  fetchCoupons: { loading: false, error: null },
  claimCoupon: { loading: false, error: null },
  createReferralCoupon: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  claimedCoupon: null,
  createdReferral: null,
  requests: INITIAL_REQUEST_STATE,
};

const couponsReducer = handleActions(
  {
    // fetchCoupons
    [fetchCoupons.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = true;
      }),
    [fetchCoupons.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = false;
        draft.list = payload.results;
      }),
    [fetchCoupons.FAILURE]: state =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = false;
      }),
    // claimCoupon
    [claimCoupon.TRIGGER]: state =>
      produce(state, draft => {
        draft.claimedCoupon = null;
        draft.requests.claimCoupon.loading = true;
        draft.requests.claimCoupon.error = null;
      }),
    [claimCoupon.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.claimCoupon.loading = false;
        draft.claimedCoupon = payload;
      }),
    [claimCoupon.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.claimCoupon.loading = false;
        draft.requests.claimCoupon.error = payload;
      }),
    // createReferralCoupon
    [createReferralCoupon.TRIGGER]: state =>
      produce(state, draft => {
        draft.claimedCoupon = null;
        draft.requests.createReferralCoupon.loading = true;
        draft.requests.createReferralCoupon.error = null;
      }),
    [createReferralCoupon.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.createReferralCoupon.loading = false;
        draft.createdReferral = payload;
      }),
    [createReferralCoupon.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.createReferralCoupon.loading = false;
        draft.requests.createReferralCoupon.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default couponsReducer;
