export const APP_INIT = 'App.Init';
export const APP_INIT_SUCCESS = 'App.Init.Success';
export const APP_INIT_FAILURE = 'App.Init.Failure';

export const AUTH_LOGIN = 'Auth.Login';
export const AUTH_LOGIN_SUCCESS = 'Auth.Login.Success';
export const AUTH_LOGIN_FAILURE = 'Auth.Login.Failure';

export const AUTH_LOGOUT = 'Auth.Logout';
export const AUTH_LOGOUT_SUCCESS = 'Auth.Logout.Success';

export const FETCH_USER_INFO = 'User.Fetch.Info';
export const FETCH_USER_INFO_SUCCESS = 'User.Fetch.Info.Success';

export const UPDATE_USER_INFO = 'User.Update.Info';
export const UPDATE_USER_INFO_SUCCESS = 'User.Update.Info.Success';
export const UPDATE_USER_INFO_FAILURE = 'User.Update.Info.Failure';

export const ACCEPT_COC = 'Coc.Accept';
export const ACCEPT_COC_SUCCESS = 'Coc.Accept.Success';
export const ACCEPT_COC_FAILURE = 'Coc.Accept.Failure';

export const LOAD_DASHBOARD = 'Dashboard.Load';
