import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchInfo } from '../reducers/PersonalPageReducer';
import i18n from '../../locales';
import odoo from '../../backend';

function* fetchInfoSaga({ payload }) {
  try {
    const info = yield call([odoo, odoo.fetchPersonalPageInfo], payload);
    yield put(fetchInfo.success(info));
  } catch (e) {
    yield put(fetchInfo.failure(i18n.t('Wrong code')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchInfo.TRIGGER], fetchInfoSaga)]);
}
