import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const APP_INIT = 'App.Init';
export const AUTH_LOGIN = 'Auth.Login';
export const AUTH_LOGOUT = 'Auth.Logout';
export const AUTH_UPDATE = 'Auth.Update';
export const ACCEPT_COC = 'Auth.AcceptCoc';
export const AUTH_FETCH_USER = 'Auth.User.Fetch';

export const init = createRoutine(APP_INIT);
export const login = createRoutine(AUTH_LOGIN);
export const logout = createRoutine(AUTH_LOGOUT);
export const update = createRoutine(AUTH_UPDATE);
export const acceptCoc = createRoutine(ACCEPT_COC);
export const fetchUserInfo = createRoutine(AUTH_FETCH_USER);

// Initial State
const INITIAL_REQUEST_STATE = {
  login: { loading: false, error: null },
  update: { loading: false, error: null },
  acceptCoc: { loading: false, error: null },
  fetchUserInfo: { loading: false, error: null },
};

const INITIAL_STATE = {
  init: false,
  user: null,
  requests: INITIAL_REQUEST_STATE,
};

const authReducer = handleActions(
  {
    // INIT
    [init.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload.user;
        draft.profile = payload.profile;
        draft.init = true;
      }),
    [init.FAILURE]: state =>
      produce(state, draft => {
        draft.init = true;
      }),
    // LOGIN
    [login.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.login.loading = true;
        draft.requests.login.error = null;
      }),
    [login.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload.user;
        draft.profile = payload.profile;
        draft.requests.login.loading = false;
      }),
    [login.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.login.loading = false;
        draft.requests.login.error = payload;
      }),
    // LOGOUT
    [logout.TRIGGER]: state =>
      produce(state, draft => {
        draft.init = false;
        draft.user = null;
      }),

    [fetchUserInfo.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchUserInfo.loading = true;
        draft.requests.fetchUserInfo.error = null;
      }),
    [fetchUserInfo.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload;
        draft.requests.fetchUserInfo.loading = false;
      }),
    [fetchUserInfo.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchUserInfo.loading = false;
        draft.requests.fetchUserInfo.error = payload;
      }),
    // UPDATE
    [update.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.update.loading = true;
        draft.requests.update.error = null;
      }),
    [update.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload;
        draft.requests.update.loading = false;
      }),
    [update.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.update.loading = false;
        draft.requests.update.error = payload;
      }),
    // acceptCoc
    [acceptCoc.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.acceptCoc.loading = true;
        draft.requests.acceptCoc.error = null;
      }),
    [acceptCoc.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload;
        draft.requests.acceptCoc.loading = false;
      }),
    [acceptCoc.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.acceptCoc.loading = false;
        draft.requests.acceptCoc.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default authReducer;
