import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEY as CART_QUERY_KEY } from './useCart';
import api from '../../backend';

export const QUERY_KEY = 'shop.payment';
export const PAYMENT_PROVIDER = {
  STRIPE: 'stripe',
  WIRE_TRANSFER: 'transfer',
  PAYPAL: 'paypal',
};

/**
 * Get payment methods
 * @param {string} orderId Required. Get the order ID of the cart
 */
const usePayments = orderId => {
  const queryClient = useQueryClient();
  const invalidateCart = () => queryClient.invalidateQueries([CART_QUERY_KEY, orderId]);

  const req = useQuery([QUERY_KEY], () => api.payments.methods());

  /* Define mutation hooks */
  const confirmFreeOrderMutation = useMutation(() => api.payments.confirmFreeOrder({ order_id: orderId }), {
    onSuccess: invalidateCart,
  });
  const confirmTransferMutation = useMutation(orderId => api.payments.confirmTransfer({ order_id: orderId }), {
    onSuccess: invalidateCart,
  });

  /* Mutation handler */
  const confirmFreeOrder = orderId => confirmFreeOrderMutation.mutateAsync(orderId);
  const confirmTransfer = orderId => confirmTransferMutation.mutateAsync(orderId);

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    paymentMethods: req.data?.results || [],

    confirmFreeOrder,
    confirmTransfer,

    mutationStatus: {
      confirmFreeOrder: confirmFreeOrderMutation.status,
      confirmTransfer: confirmTransferMutation.status,
    },
    ...req,
  };
};

export default usePayments;
