import React from 'react';
import {
  Badge,
  Tooltip,
  IconButton,
  Popover,
  Paper,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';
import { useNotificationMutation, useNotifications } from '../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import ListIcon from '@material-ui/icons/List';

import joinComponents from '../../utils/joinComponents';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { NOTIFICATION_HISTORY } from '../../navigation/routes';
import processNotificationType from '../../utils/processNotificationType';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -4,
    top: -4,
    border: `2px solid ${theme.palette.primary}`,
    padding: '0',
  },
}))(Badge);

const NotificationsButton = () => {
  const { notifications, data } = useNotifications({ seen: false });
  const { markAllAsRead, markAsRead } = useNotificationMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const anchor = React.useRef(null);

  return (
    <>
      <Tooltip title={t('Notifications')}>
        <IconButton ref={anchor} onClick={() => setIsOpen(true)}>
          <StyledBadge badgeContent={data?.total_count} color="error">
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Popover
        open={isOpen}
        anchorEl={anchor.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Box p={2} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <List component="nav">
              {!notifications.length && <Typography gutterBottom>{t("There's no new notification now")}</Typography>}

              {joinComponents(
                notifications.map(notification => (
                  <ListItem key={notification.id} style={{ width: 250 }}>
                    <ListItemText
                      primary={processNotificationType(notification, markAsRead)}
                      secondary={format(new Date(notification.create_date), 'PPP, p')}
                    />
                  </ListItem>
                )),
                <Divider />,
              )}
            </List>

            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={t('See all')}>
                <IconButton
                  size="small"
                  onClick={() => {
                    history.push(NOTIFICATION_HISTORY);
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
              <Box mx={1} />
              <Tooltip title={t('Mark all as read')}>
                <IconButton size="small" onClick={() => markAllAsRead.mutateAsync()} disabled={!notifications.length}>
                  {markAllAsRead.isLoading ? <CircularProgress size={25} /> : <DoneAllIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default NotificationsButton;
