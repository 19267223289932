import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  fetchCart,
  cartAddItem,
  cartUpdateItem,
  cartRemoveItem,
  cartApplyCoupon,
  initCheckout,
  confirmPayment,
  fetchVoucherProducts,
  fetchCheckoutConfig,
  openCart,
  confirmFreeCart,
} from '../reducers/ShopReducer';

import i18n from '../../locales';
import odoo from '../../backend';

function* fetchCartSaga({ payload }) {
  try {
    console.log('saga -> ', payload);
    const cart = yield call([odoo.cart, odoo.cart.fetch], payload);
    yield put(fetchCart.success(cart));
  } catch (e) {
    console.log(e);
    yield put(fetchCart.failure(i18n.t('Unable to fetch cart')));
  }
}

function* addItemSaga({ payload }) {
  try {
    const cart = yield call([odoo.cart, odoo.cart.addItem], payload);
    yield put(cartAddItem.success(cart));
    yield put(openCart());
  } catch (e) {
    console.log(e);
    yield put(cartAddItem.failure(i18n.t('Unable to add item to cart')));
  }
}

function* updateItemSaga({ payload }) {
  try {
    const cart = yield call([odoo.cart, odoo.cart.updateItem], payload);
    yield put(cartUpdateItem.success(cart));
  } catch (e) {
    console.log(e);
    yield put(cartUpdateItem.failure(i18n.t('Unable to update item to cart')));
  }
}

function* removeItemSaga({ payload }) {
  try {
    const cart = yield call([odoo.cart, odoo.cart.removeItem], payload);
    yield put(cartRemoveItem.success(cart));
  } catch (e) {
    console.log(e);
    yield put(cartRemoveItem.failure(i18n.t('Unable to remove item from cart')));
  }
}

function* applyCouponSaga({ payload }) {
  try {
    const cart = yield call([odoo.cart, odoo.cart.applyCoupon], payload);
    yield put(cartApplyCoupon.success(cart));
  } catch (e) {
    console.log(e);
    yield put(cartApplyCoupon.failure(i18n.t('Unable apply coupon')));
  }
}

function* fetchCheckoutConfigSaga() {
  try {
    const config = yield call([odoo.stripe, odoo.stripe.config]);
    yield put(fetchCheckoutConfig.success(config));
  } catch (e) {
    console.log(e);
    yield put(fetchCheckoutConfig.failure(i18n.t('Error initializing chekout')));
  }
}

function* initCheckoutSaga({ payload }) {
  try {
    const cart = yield call([odoo.stripe, odoo.stripe.init], payload);
    yield put(initCheckout.success(cart));
  } catch (e) {
    console.log(e);
    yield put(initCheckout.failure(i18n.t('Error initializing chekout')));
  }
}

function* confirmPaymentSaga({ payload }) {
  try {
    const confirm = yield call([odoo.stripe, odoo.stripe.confirm], payload);
    yield put(initCheckout.success(confirm));
  } catch (e) {
    console.log(e);
    yield put(initCheckout.failure(i18n.t('Unable to confirm payment')));
  }
}

function* confirmFreeCartSaga({ payload }) {
  try {
    const confirm = yield call([odoo.cart, odoo.cart.confirmFreeCart], payload);
    yield put(initCheckout.success(confirm));
  } catch (e) {
    console.log(e);
    yield put(initCheckout.failure(i18n.t('Unable too confirm cart')));
  }
}

function* fetchVoucherProductsSaga() {
  try {
    const products = yield call([odoo.voucherProducts, odoo.voucherProducts.fetch]);
    yield put(fetchVoucherProducts.success(products.results));
  } catch (e) {
    console.log(e);
    yield put(fetchVoucherProducts.failure(i18n.t('Unable to fetch products')));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([fetchCart.TRIGGER], fetchCartSaga),
    yield takeLatest([cartAddItem.TRIGGER], addItemSaga),
    yield takeLatest([cartUpdateItem.TRIGGER], updateItemSaga),
    yield takeLatest([cartRemoveItem.TRIGGER], removeItemSaga),
    yield takeLatest([cartApplyCoupon.TRIGGER], applyCouponSaga),
    yield takeLatest([initCheckout.TRIGGER], initCheckoutSaga),
    yield takeLatest([confirmPayment.TRIGGER], confirmPaymentSaga),
    yield takeLatest([confirmFreeCart.TRIGGER], confirmFreeCartSaga),
    yield takeLatest([fetchVoucherProducts.TRIGGER], fetchVoucherProductsSaga),
    yield takeLatest([fetchCheckoutConfig.TRIGGER], fetchCheckoutConfigSaga),
  ]);
}
