import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend';

const QUERY_KEY = 'locations';

/** Get a single location */
const useLpiLocation = id => {
  const req = useQuery([QUERY_KEY, id], () => api.locations.get(id), { enabled: !!id });

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    location: req.data || {},
    ...req,
  };
};

export default useLpiLocation;
