import * as types from './types';

export const login = data => {
  return {
    type: types.AUTH_LOGIN,
    payload: data,
  };
};

export const loginSuccess = user => {
  return {
    type: types.AUTH_LOGIN_SUCCESS,
    payload: { user },
  };
};

export const loginFailure = error => {
  return {
    type: types.AUTH_LOGIN_FAILURE,
    payload: error,
  };
};

export const logout = () => {
  return {
    type: types.AUTH_LOGOUT,
  };
};

export const logoutSuccess = () => {
  return {
    type: types.AUTH_LOGOUT_SUCCESS,
  };
};

export const fetchUserInfo = () => ({
  type: types.FETCH_USER_INFO,
});

export const updateUserInfo = data => ({
  type: types.UPDATE_USER_INFO,
  payload: data,
});

export const updateUserInfoSuccess = user => ({
  type: types.UPDATE_USER_INFO_SUCCESS,
  payload: user,
});

export const updateUserInfoFailure = error => ({
  type: types.UPDATE_USER_INFO_FAILURE,
  payload: error,
});

export const fetchUserInfoSuccess = user => ({
  type: types.FETCH_USER_INFO_SUCCESS,
  payload: user,
});

export const acceptCodeOfConduct = userId => ({
  type: types.ACCEPT_COC,
  payload: userId,
});

export const acceptCodeOfConductSuccess = user => ({
  type: types.ACCEPT_COC_SUCCESS,
  payload: user,
});

export const acceptCodeOfConductFailure = error => ({
  type: types.ACCEPT_COC_FAILURE,
  payload: error,
});

export const loadDashboardData = () => ({
  type: types.LOAD_DASHBOARD,
});
