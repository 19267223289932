import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchMembershipRules } from '../../store/actions/memberships';
import { fetchActivities, editActivity } from '../../store/actions/activities';
import { getSpecCategoriesList, getSubCategoriesList, getRuleMetrics } from '../../store/selectors';
import * as routes from '../../navigation/routes';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Layout from '../../components/App/Layout';

import ActivityForm from '../../components/Activities/ActivityForm';

class EditActivityPage extends PureComponent {
  componentDidMount() {
    this.props.fetchMembershipRules();
    this.props.fetchActivities();
  }
  submit = values => {
    this.props.editActivity(this.props.activity.id, values, routes.ACTIVITIES_LIST);
  };

  render() {
    const { activity } = this.props;
    if (!activity) {
      return null;
    }

    return (
      <Layout back title="Edit Activity">
        <Card>
          <CardContent>
            <ActivityForm
              metrics={this.props.metrics}
              initialValues={activity}
              specCategories={this.props.specCategories}
              subCategories={this.props.subCategories}
              categories={this.props.categories}
              rules={this.props.rules}
              onSubmit={this.submit}
            />
          </CardContent>
        </Card>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { computedMatch } = ownProps;
  const { memberships } = state;

  return {
    specCategories: getSpecCategoriesList(state),
    subCategories: getSubCategoriesList(state),
    categories: state.activities.categories,
    metrics: getRuleMetrics(state),
    activity: state.activities.list[computedMatch.params.activityId],
    rules: memberships.rules,
  };
};

export default connect(mapStateToProps, {
  fetchMembershipRules,
  fetchActivities,
  editActivity,
})(EditActivityPage);
