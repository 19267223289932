import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';

import StatusChip from '../Common/StatusChip';
import Table from '../Common/Table';
import Column from '../Common/TableColumn';
import CopyToClipboard from '../Common/CopyToClipboard';
import { Link, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import useVouchersMutations from '../../hooks/Vouchers/useVouchersMutations';
import { setMessage } from '../../store/reducers/SnackReducer';

const VouchersTable = ({ ...props }) => {
  const [openRedeemDialog, setOpenRedeemDialog] = React.useState(false);
  const { redeem } = useVouchersMutations();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const redeemVoucher = id => {
    redeem
      .mutateAsync(id)
      .catch(err => {
        console.log(err);
        dispatch(setMessage(t('Error during the request')));
      })
      .finally(() => {
        setOpenRedeemDialog(false);
      });
  };

  return (
    <>
      <Table {...props}>
        <Column
          label={t('Status')}
          field="state"
          render={value => {
            return <StatusChip status={value?.toUpperCase()} />;
          }}
        />
        <Column label={t('Name')} field="product_id_name" />
        <Column
          label={t('Code')}
          field="code"
          render={(value, item) =>
            value ? (
              <CopyToClipboard
                title={t('Copied to clipboard!')}
                leaveDelay={1500}
                render={copy => (
                  <Link
                    href="#"
                    color="secondary"
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      copy(value);
                    }}
                  >
                    {value}
                  </Link>
                )}
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  setOpenRedeemDialog(item.id);
                }}
              >
                {t('Claim Voucher')}
              </Button>
            )
          }
        />
        <Column label={t('Expiring Date')} field="expiration_date" />
      </Table>

      <Dialog open={!!openRedeemDialog} onClose={() => setOpenRedeemDialog(false)}>
        <DialogTitle>{t('Claim Voucher')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Are you sure to claim this voucher?')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRedeemDialog(false)} variant="text">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => redeemVoucher(openRedeemDialog)}
            variant="contained"
            color="primary"
            disabled={redeem.isLoading}
          >
            {redeem.isLoading ? <CircularProgress size={25} /> : t('Claim')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(VouchersTable);
