import Axios from 'axios';
import client, { LPI_HOST } from './config';

const API_URL = `${LPI_HOST}/api/v1`;
const AUTH_URL = `${LPI_HOST}/api/people/token`;
const JOB_URL = `${LPI_HOST}/api/job/v1`;
const PARTNER_API_URL = `${LPI_HOST}/api/partners/v1/public`;
const PARTNER_URL = `${LPI_HOST}/api/partners/v1`;
const VOLUNTEER_URL = `${LPI_HOST}/api/volunteer/v1`;
const SHOP_ENDPOINT = `${LPI_HOST}/api/shop/v1`;
const UPLOAD_ENDPOINT = `${LPI_HOST}/api/upload_attachment/v1/`;
const DOCUMENTS_ENDPOINT = `${LPI_HOST}/api/v1/documents_people`;

class Resource {
  constructor(client, endpoint) {
    this.endpoint = endpoint;
    this.client = client;
  }

  get(id) {
    return this.client.get(`${this.endpoint}/${id}/`);
  }

  search(params = {}) {
    return this.client.get(this.endpoint, params);
  }

  create(data) {
    return this.client.post(this.endpoint, data);
  }

  update(id, data) {
    return this.client.put(`${this.endpoint}/${id}/`, data);
  }

  delete(id) {
    return this.client.delete(`${this.endpoint}/${id}/`);
  }
}

const CartResource = client => {
  const addItem = data => client.post(`${SHOP_ENDPOINT}/cart/add_item`, data);
  const updateItem = data => client.post(`${SHOP_ENDPOINT}/cart/update_item`, data);
  const removeItem = data => client.post(`${SHOP_ENDPOINT}/cart/remove_item`, data);
  const clear = data => client.post(`${SHOP_ENDPOINT}/cart/clear`, data);
  const applyCoupon = data => client.post(`${SHOP_ENDPOINT}/cart/apply_coupon`, data);
  const fetch = (params = {}) => client.get(`${SHOP_ENDPOINT}/cart`, params);

  // const confirmFreeCart = () => client.post(`${SHOP_ENDPOINT}/cart/confirm_free_order`);

  return {
    addItem,
    updateItem,
    removeItem,
    clear,
    // confirmFreeCart,
    applyCoupon,
    fetch,
  };
};

const PaymentsResource = client => {
  const methods = data => client.get(`${SHOP_ENDPOINT}/payment_methods`, data);
  const confirmTransfer = data => client.post(`${SHOP_ENDPOINT}/bank_transfer/confirm`, data);
  const stripeCheckoutURL = data => client.post(`${SHOP_ENDPOINT}/stripe/checkout`, data);
  const confirmFreeOrder = data => client.post(`${SHOP_ENDPOINT}/cart/confirm_free_order`, data);
  const paypalCheckoutURL = data => client.post('/paypal/checkout', data);

  return { stripeCheckoutURL, paypalCheckoutURL, confirmTransfer, confirmFreeOrder, methods };
};

const VoucherProductsResource = client => {
  const fetch = params => client.get(`${SHOP_ENDPOINT}/vouchers/`, params);
  return { fetch };
};

const UploadFileResource = client => {
  const create = data => client.postForm(UPLOAD_ENDPOINT, data);
  return { create };
};

const DocumentsResource = client => {
  const search = () => client.get(`${DOCUMENTS_ENDPOINT}/`);
  const folders = () => client.get(`${DOCUMENTS_ENDPOINT}/folders`);
  const tags = () => client.get(`${DOCUMENTS_ENDPOINT}/tags`);

  return { search, folders, tags };
};

const JobResource = client => {
  const get = id => client.get(`${JOB_URL}/jobs/${id}`);
  const offers = filters => client.get(`${JOB_URL}/jobs`, filters);
  const skills = () => client.get(`${JOB_URL}/skills`);

  const addToFavorites = id => client.post(`${JOB_URL}/favorites`, { job_id: id });
  const removeFromFavorites = id => client.delete(`${JOB_URL}/favorites/${id}`);

  return { get, offers, skills, addToFavorites, removeFromFavorites };
};

const LocationResource = client => {
  const search = params => client.get(`${API_URL}/locations/`, params);
  const get = id => client.get(`${API_URL}/locations/${id}`);
  const approve = id => client.get(`${API_URL}/locations/approve/${id}`);

  return { search, get, approve };
};

const MonitorResource = () => {
  const isServerUp = async () => {
    try {
      // Use a new axios instance to bypass custom interceptors
      const res = await Axios.get(`${LPI_HOST}/api/system/v1/monitor/liveness`, {
        timeout: 10 * 1000, // Use extended timeout to avoid false-negatives with slow connections
      });
      return res.data.status === 'OK';
    } catch (err) {
      return false;
    }
  };

  return { isServerUp };
};

class VoucherResource extends Resource {
  redeem(id) {
    return this.client.post(`${this.endpoint}/${id}/redeem`);
  }
}

class NotificationResource extends Resource {
  markAsRead(id) {
    return this.client.get(`${this.endpoint}/mark_as_read/${id}/`);
  }
  markAllAsRead() {
    return this.client.get(`${this.endpoint}/mark_as_read/`);
  }
}

class ApplicationResource extends Resource {
  withdraw(id) {
    return this.client.post(`${this.endpoint}/${id}/withdraw`);
  }
}


class HelpdeskResource extends Resource {
  sendMessage(id, data) {
    return this.client.post(`${this.endpoint}/${id}/send_message`, data);
  }
}


class LPIOdoo {
  constructor(base_url) {
    this.base_url = base_url;
    this.monitor = MonitorResource(this);
    this.users = new Resource(this, `${API_URL}/user`);
    this.membershipProducts = new Resource(this, `${API_URL}/membership_products`);
    this.membershipRequests = new Resource(this, `${API_URL}/membership_requests`);
    this.membershipRules = new Resource(this, `${API_URL}/membership_rules`);
    this.certifications = new Resource(this, `${API_URL}/certifications`);
    this.certificates = new Resource(this, `${API_URL}/certificates`);
    this.achievements = new Resource(this, `${API_URL}/achievements`);
    this.scoreboard = new Resource(this, `${API_URL}/scoreboard`);
    this.activities = new Resource(this, `${API_URL}/activities`);
    this.claims = new Resource(this, `${API_URL}/claims`);
    this.pduCategories = new Resource(this, `${API_URL}/pdu_categories`);
    this.places = new Resource(this, `${API_URL}/places`);
    this.tickets = new HelpdeskResource(this, `${LPI_HOST}/api/helpdesk/v1/tickets`);
    this.languages = new Resource(this, `${LPI_HOST}/api/public/v1/languages`);
    this.support_topics = new Resource(this, `${API_URL}/support_topics`);

    this.savedFilters = new Resource(this, `${JOB_URL}/saved_filters`);
    this.applications = new Resource(this, `${JOB_URL}/applications`);
    this.job = JobResource(this);
    this.locations = LocationResource(this);
    this.notifications = new NotificationResource(this, `${LPI_HOST}/api/v1/notifications`);

    this.vouchers = new VoucherResource(this, `${API_URL}/vouchers`);
    // Volunteers Resources
    this.volunteer = {
      programs: new Resource(this, `${VOLUNTEER_URL}/programs`),
      opportunities: new Resource(this, `${VOLUNTEER_URL}/opportunity`),
      applications: new ApplicationResource(this, `${VOLUNTEER_URL}/applications`),
      favourites: new Resource(this, `${VOLUNTEER_URL}/volunteer_favorites`),
    };
    this.coupons = new Resource(this, `${API_URL}/coupons`);
    this.benefits = new Resource(this, `${API_URL}/benefits`);
    this.claimCoupon = token => {
      return this.post(`${API_URL}/coupons/claim/?code=${token}`);
    };
    this.createReferralCoupon = data => {
      return this.post(`${API_URL}/coupons/referral`, data);
    };

    this.cart = CartResource(this);
    // this.stripe = StripeResource(this)
    this.payments = PaymentsResource(this);
    this.voucherProducts = VoucherProductsResource(this);
    this.uploadFile = UploadFileResource(this);
    this.documents = DocumentsResource(this);
  }

  headers() {
    const headers = {};
    const token = localStorage.getItem('odoo-token');
    if (token) {
      headers['Authorization'] = 'Token ' + token;
    }

    return headers;
  }

  login(login, password) {
    return new Promise((resolve, reject) => {
      client
        .post(AUTH_URL, {
          login,
          password,
        })
        .then(response => {
          if (response.data.error) {
            return reject('Username or password not valid');
          }
          localStorage.setItem('odoo-token', response.data.result.auth_token);
          resolve(response.data.result);
        })
        .catch(reject);
    });
  }

  fetchPersonalPageInfo(code) {
    return this.get(`${LPI_HOST}/api/public/v1/verification?code=${code}`);
  }

  acceptCodeOfConduct(id) {
    return this.put(`${API_URL}/user/` + id, {
      has_accepted_coc: true,
    });
  }

  logout() {
    localStorage.removeItem('odoo-token');
  }

  fetchUserInfo() {
    return this.get(`${API_URL}/user`);
  }

  updateUserInfo({ id, data }) {
    return this.put(`${API_URL}/user/` + id, data);
  }

  fetchMembershipProducts() {
    return this.get(`${API_URL}/membership_products`);
  }

  createMembershipRequest(data) {
    return this.post(`${API_URL}/membership_requests`, data);
  }

  renewMembership(data) {
    return this.post(`${API_URL}/membership_requests/renew`, data);
  }

  fetchMembershipRequest() {
    return this.get(`${API_URL}/membership_requests`);
  }

  fetchMembershipRules() {
    return this.get(`${API_URL}/membership_rules`);
  }

  fetchCertifications() {
    return this.get(`${API_URL}/certifications`);
  }

  fetchAchievements() {
    return this.get(`${API_URL}/achievements`);
  }

  fetchScoreboard() {
    return this.get(`${API_URL}/scoreboard`);
  }

  fetchActivities() {
    return this.get(`${API_URL}/activities`);
  }

  fetchActivity(id) {
    return this.get(`${API_URL}/activities/` + id);
  }

  createActivity(data) {
    return this.post(`${API_URL}/activities`, data);
  }

  editActivity(id, data) {
    return this.put(`${API_URL}/activities/` + id, data);
  }

  updateActivity(id, data) {
    return this.put(`${API_URL}/activities/` + id, data);
  }

  createClaim(data) {
    return this.post(`${API_URL}/claims`, data);
  }

  fetchPduCategories() {
    return this.get(`${API_URL}/pdu_categories`);
  }

  fetchInvite(token) {
    return this.get(`${PARTNER_API_URL}/accept_token/?token=` + token);
  }

  fetchSkills() {
    return this.get(`${PARTNER_URL}/job_skill`);
  }

  acceptInvite(token) {
    return this.post(`${PARTNER_API_URL}/accept_token`, {
      token,
      response: 'accepted',
    });
  }

  rejectInvite(token) {
    return this.post(`${PARTNER_API_URL}/accept_token`, {
      token,
      response: 'refused',
    });
  }

  get(path, params = {}) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'get',
        headers: this.headers(),
        params: params,
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  post(path, data) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'post',
        headers: this.headers(),
        data: data,
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  put(path, data) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'put',
        headers: this.headers(),
        data: data,
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  patch(path, data) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'patch',
        headers: this.headers(),
        data: data,
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(path) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'delete',
        headers: this.headers(),
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  postForm(path, data) {
    return new Promise((resolve, reject) => {
      client({
        url: path,
        method: 'post',
        headers: {
          ...this.headers(),
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
        .then(response => {
          if (response.data.error) return reject(response.data.error);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

const lpiOdoo = new LPIOdoo(API_URL);

export const partnerApi = new LPIOdoo(PARTNER_API_URL);

export default lpiOdoo;
