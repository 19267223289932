import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  FETCH_ACTIVITY,
  FETCH_ACTIVITIES,
  CREATE_ACTIVITY,
  EDIT_ACTIVITY,
  FETCH_ACTIVITY_CATEGORIES,
  CLAIM_ACTIVITY,
} from '../actions/activities/types';
import { push } from 'connected-react-router';

import {
  fetchActivitySuccess,
  fetchActivityFailure,
  fetchActivitiesSuccess,
  fetchActivitiesFailure,
  fetchActivitiesCategoriesSuccess,
  fetchActivitiesCategoriesFailure,
  createActivitySuccess,
  createActivityFailure,
  editActivitySuccess,
  editActivityFailure,
  claimActivitySuccess,
  claimActivityFailure,
  openClaimDialog,
} from '../actions/activities';
import { addNotification } from '../actions/app';

import { fetchCertifications, fetchScoreboard } from '../actions/certifications';
import odoo from '../../backend';

function* fetchActivitySaga({ payload }) {
  try {
    const activities = yield call([odoo.activities, odoo.activities.get], payload);
    yield put(fetchActivitySuccess(activities));
  } catch (error) {
    yield put(fetchActivityFailure(error));
  }
}

function* fetchActivitiesSaga() {
  try {
    const categories = yield call([odoo.pduCategories, odoo.pduCategories.search]);
    yield put(fetchActivitiesCategoriesSuccess(categories.results));
    const activities = yield call([odoo.activities, odoo.activities.search]);
    yield put(fetchActivitiesSuccess(activities.results));
  } catch (error) {
    yield put(fetchActivitiesFailure(error));
  }
}

function* createActivitySaga(action) {
  try {
    action.payload.documents = [];

    if (action.payload.document) {
      var formData = new FormData();
      formData.append('model', 'pdu.activity');
      const newDocs = action.payload.document || [];
      newDocs.forEach(newDoc => formData.append('ufile', newDoc.blob));
      const uploadedDoc = yield call([odoo.uploadFile, odoo.uploadFile.create], formData);
      if (uploadedDoc) {
        uploadedDoc.forEach(doc => action.payload.documents.push({ id: doc.id }));
      }
      delete action.payload.document;
    }

    const activity = yield call([odoo, odoo.createActivity], action.payload);
    yield put(createActivitySuccess(activity));
    yield put(addNotification({ message: 'Activity created' }));

    if (action.meta && action.meta.next) {
      yield put(push(action.meta.next));
      yield put(openClaimDialog(activity));
    }
  } catch (error) {
    yield put(createActivityFailure(error.description));
  }
}

function* editActivitySaga(action) {
  try {
    const { id, data } = action.payload;

    if (data.document) {
      var formData = new FormData();
      formData.append('model', 'pdu.activity');
      const newDocs = data.document || [];
      newDocs.forEach(newDoc => formData.append('ufile', newDoc.blob));
      const uploadedDoc = yield call([odoo.uploadFile, odoo.uploadFile.create], formData);
      if (uploadedDoc) {
        uploadedDoc.forEach(doc => data.documents.push({ id: doc.id }));
      }
      delete data.document;
    }

    const activity = yield call([odoo, odoo.editActivity], id, data);
    yield put(editActivitySuccess(activity));
    yield put(addNotification({ message: 'Activity updated' }));
    if (action.meta && action.meta.next) {
      yield put(push(action.meta.next));
    }
  } catch (error) {
    yield put(editActivityFailure(error));
  }
}

function* fetchActivitiesCategoriesSaga() {
  try {
    const categories = yield call([odoo, odoo.fetchPduCategories]);
    yield put(fetchActivitiesCategoriesSuccess(categories.results));
  } catch (error) {
    yield put(fetchActivitiesCategoriesFailure(error));
  }
}

function* claimActivitySaga(action) {
  try {
    const claim = yield call([odoo, odoo.createClaim], action.payload);
    const activity = yield call([odoo, odoo.fetchActivity], claim.activity_id);
    yield put(fetchCertifications());
    yield put(claimActivitySuccess(activity));
    yield put(fetchScoreboard());
  } catch (error) {
    yield put(claimActivityFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([FETCH_ACTIVITY], fetchActivitySaga),
    yield takeLatest([FETCH_ACTIVITIES], fetchActivitiesSaga),
    yield takeLatest([CREATE_ACTIVITY], createActivitySaga),
    yield takeLatest([EDIT_ACTIVITY], editActivitySaga),
    yield takeLatest([FETCH_ACTIVITY_CATEGORIES], fetchActivitiesCategoriesSaga),
    yield takeLatest([CLAIM_ACTIVITY], claimActivitySaga),
  ]);
}
