import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../utils';
import Label from '../Common/Label';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareVerificationDialog from './ShareVerificationDialog';
import { GrDownload } from 'react-icons/gr/';

const CertificationListItem = ({ certification }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showShareDialog, setShowShareDialog] = useState(false);

  const renderExpirationDate = () => {
    let date = certification.expire_date;
    if (certification.new_expiration_date) {
      date = certification.new_expiration_date;
    }
    return formatDate(date);
  };

  return (
    <Card>
      <div className={classes.root}>
        <CardMedia className={classes.logo} image={certification.cert_image} />
        <CardContent className={classes.content}>
          <Typography variant="h6" gutterBottom>
            {certification.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('Valid from')} {formatDate(certification.orig_award_date)}
          </Typography>
          {certification.expire_date && certification.cert_expires && (
            <Typography variant="subtitle1" gutterBottom>
              {t('Expiration date')} {renderExpirationDate()}
            </Typography>
          )}
        </CardContent>
        <div className={classes.info}>
          <Label type={certification.active ? 'success' : 'danger'}>
            {certification.active ? t('Active') : t('Inactive')}
          </Label>
          <Box display="flex" justifyContent="center" flexBasis="2px" mt={1}>
            <Button
              color="primary"
              onClick={() => window.open(certification.download_url, '_blank')}
              size="small"
              className={classes.shareButton}
              variant="contained"
              startIcon={<GetAppIcon />}
            >
              {t('Download')}
            </Button>
            <Button
              color="primary"
              onClick={() => setShowShareDialog(true)}
              size="small"
              className={classes.shareButton}
              startIcon={<ShareIcon />}
              variant="contained"
              style={{ marginLeft: '10px' }}
            >
              {t('Share')}
            </Button>
          </Box>
        </div>
      </div>
      <ShareVerificationDialog
        verificationCode={certification.version_code}
        visible={showShareDialog}
        onClose={() => setShowShareDialog(false)}
      />
    </Card>
  );
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
      flexGrow: 2,
    },
    info: {
      alignSelf: 'center',
      justifySelf: 'center',
      textAlign: 'center',
    },
    logo: {
      alignSelf: 'center',
      justifySelf: 'center',
      width: 100,
      height: 95,
    },
    shareButton: {
      marginTop: theme.spacing(),
    },
  };
});

export default React.memo(CertificationListItem);
