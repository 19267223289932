import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import * as routes from '../../navigation/routes';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useTranslation } from 'react-i18next';

const ActivityContextMenu = ({ activity }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(
    action => {
      switch (action) {
        case 'edit':
          dispatch(push(routes.ACTIVITIES_EDIT(activity.id)));
          break;
        default:
          break;
      }
      setAnchorEl(null);
    },
    [dispatch, activity.id],
  );

  return (
    activity.state !== 'accepted' && (
      <>
        <IconButton aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu id={'activity-menu-' + activity.id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {activity.state !== 'accepted' ? <MenuItem onClick={() => handleClose('edit')}>{t('Edit')}</MenuItem> : null}
        </Menu>
      </>
    )
  );
};

export default React.memo(ActivityContextMenu);
