import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

export const formatDate = (dateString, f = 'yyyy-MM-dd') => {
  if (!dateString) return '';
  const dateFns = new DateFnsUtils();
  const d = dateFns.date(dateString);
  return format(d, f, { awareOfUnicodeTokens: true });
};

const ACITVITY_STATUS = {
  accepted: 'Accepted',
  need_review: 'Under Review',
  waiting_materials: 'Supporting Materials Required',
  under_dispute: 'Under Dispute',
  rejected: 'Rejected',
};

export const getActivityStatus = status => {
  return ACITVITY_STATUS[status] || status;
};
