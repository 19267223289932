import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  btnError: {
    borderColor: theme.palette.error.main,
  },
}));

const FileUpload = ({ label, color, error, name, onChange }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [filename, setFilename] = React.useState('Choose a file');

  const handleUploadClick = event => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function() {
      setFilename(file.name);
      setProgress(0);
      onChange(file.name, reader.result.split(',')[1]);
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            clearInterval(timer);
          }
          return Math.min(oldProgress + 20, 100);
        });
      }, 100);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item direction="column">
        <Grid item>
          <Typography>{filename}</Typography>
        </Grid>
        <Grid item>
          <LinearProgress variant="determinate" value={progress} />
        </Grid>
      </Grid>
      <Grid item>
        <input name={name} type="file" className={classes.input} onChange={handleUploadClick} id="upload-file-button" />
        <label htmlFor="upload-file-button">
          <Button
            fullWidth
            className={error && classes.btnError}
            variant="outlined"
            color={color}
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            {label}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default React.memo(FileUpload);
