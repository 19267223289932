import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { JOBS_APPLIED } from '../../navigation/routes';
import {
  fetchOffers,
  searchOffers,
  fetchFavourites,
  addFavourite,
  removeFavourite,
  fetchApplications,
  applyJob,
  fetchSearches,
  addSearch,
  updateSearch,
  removeSearch,
  fetchAlerts,
} from '../reducers/JobsReducer';
import { setMessage } from '../reducers/SnackReducer';
import i18n from '../../locales';
import odoo from '../../backend';

function* fetchOffersSaga() {
  try {
    const offers = yield call([odoo.job.offers, odoo.job.offers.search]);
    yield put(fetchOffers.success(offers.results));
  } catch (e) {
    yield put(fetchOffers.failure(i18n.t('Unable to fetch jobs offers')));
  }
}

function* searchOffersSaga({ payload }) {
  try {
    const offers = yield call([odoo.job.offers, odoo.job.offers.search], payload);
    yield put(searchOffers.success(offers.results));
  } catch (e) {
    yield put(searchOffers.failure(i18n.t('Unable to search jobs offers')));
  }
}

function* fetchFavouritesSaga() {
  try {
    const favourites = yield call([odoo.job.favourites, odoo.job.favourites.search]);
    yield put(fetchFavourites.success(favourites.jobs ? favourites.jobs : []));
  } catch (e) {
    yield put(fetchFavourites.failure(i18n.t('Unable to fetch jobs')));
  }
}

function* addFavouriteSaga({ payload }) {
  try {
    const job = yield call([odoo.job.favourites, odoo.job.favourites.create], {
      job_id: payload.id,
    });
    yield put(addFavourite.success(job));
    yield put(setMessage(i18n.t(`"${payload.name}" added to favourites`)));
  } catch (e) {
    yield put(addFavourite.failure(i18n.t('Unable to add favourite')));
  }
}

function* removeFavouriteSaga({ payload }) {
  try {
    const job = yield call([odoo.job.favourites, odoo.job.favourites.delete], payload.id);
    yield put(removeFavourite.success(job));
    yield put(setMessage(i18n.t(`"${payload.name}" removed from favourites`)));
  } catch (e) {
    yield put(removeFavourite.failure(i18n.t('Unable to remove favourite')));
  }
}

function* fetchApplicationsSaga() {
  try {
    const applications = yield call([odoo.job.applications, odoo.job.applications.search]);
    yield put(fetchApplications.success(applications.results));
  } catch (e) {
    yield put(fetchApplications.failure(i18n.t('Unable to fetch jobs applications')));
  }
}

function* applyJobSaga({ payload }) {
  try {
    const { job_name, ...restPayload } = payload;
    const job = yield call([odoo.job.applications, odoo.job.applications.create], restPayload);
    yield put(applyJob.success(job));
    yield put(setMessage(i18n.t(`Your application for "${job_name}" has been submitted`)));
    yield put(push(JOBS_APPLIED + '/' + job.job_id));
  } catch (e) {
    console.log(e);
    yield put(applyJob.failure(i18n.t('Unable to apply for job')));
  }
}

function* fetchSearchesSaga() {
  try {
    const searches = yield call([odoo.job.search, odoo.job.search.search]);
    yield put(fetchSearches.success(searches));
  } catch (e) {
    yield put(fetchSearches.failure(i18n.t('Unable to fetch searches')));
  }
}

function* addSearchSaga({ payload }) {
  try {
    const searches = yield call([odoo.job.search, odoo.job.search.create], payload);
    yield put(addSearch.success(searches));
    yield put(setMessage(i18n.t(`"${payload.name}" added to saved searches`)));
  } catch (e) {
    yield put(addSearch.failure(i18n.t('Unable to add search')));
  }
}

function* updateSearchSaga({ payload }) {
  try {
    const searches = yield call([odoo.job.search, odoo.job.search.update], payload.id, payload);
    yield put(updateSearch.success(searches));
    if (payload.alert) yield put(setMessage(i18n.t(`You will receive alerts for "${payload.name}"`)));
    else yield put(setMessage(i18n.t(`You won't receive alerts for "${payload.name}"`)));
  } catch (e) {
    yield put(updateSearch.failure(i18n.t('Unable to add search')));
  }
}

function* removeSearchSaga({ payload }) {
  try {
    yield call([odoo.job.search, odoo.job.search.delete], payload.id);
    yield put(removeSearch.success(payload.id));
    yield put(setMessage(i18n.t(`"${payload.name}" removed from saved searches`)));
  } catch (e) {
    yield put(removeSearch.failure(i18n.t('Unable to remove search')));
  }
}

function* fetchAlertsSaga() {
  try {
    const alerts = yield call([odoo.job.notifications, odoo.job.notifications.search]);
    yield put(fetchAlerts.success(alerts.results));
  } catch (e) {
    yield put(fetchAlerts.failure(i18n.t('Unable to fetch jobs')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchOffers.TRIGGER], fetchOffersSaga)]);
  yield all([yield takeLatest([searchOffers.TRIGGER], searchOffersSaga)]);
  yield all([yield takeLatest([fetchFavourites.TRIGGER], fetchFavouritesSaga)]);
  yield all([yield takeLatest([addFavourite.TRIGGER], addFavouriteSaga)]);
  yield all([yield takeLatest([removeFavourite.TRIGGER], removeFavouriteSaga)]);
  yield all([yield takeLatest([fetchApplications.TRIGGER], fetchApplicationsSaga)]);
  yield all([yield takeLatest([applyJob.TRIGGER], applyJobSaga)]);
  yield all([yield takeLatest([fetchSearches.TRIGGER], fetchSearchesSaga)]);
  yield all([yield takeLatest([addSearch.TRIGGER], addSearchSaga)]);
  yield all([yield takeLatest([updateSearch.TRIGGER], updateSearchSaga)]);
  yield all([yield takeLatest([removeSearch.TRIGGER], removeSearchSaga)]);
  yield all([yield takeLatest([fetchAlerts.TRIGGER], fetchAlertsSaga)]);
}
