import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const validate = () => {
  const errors = {};
  return errors;
};

const ApplyModal = ({ open, program, opportunity, handleOk, handleClose }) => {
  const { t } = useTranslation();
  const [hasApplied, setHasApplied] = useState(false);

  function handleApply(values) {
    handleOk(values);
    setHasApplied(true);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('Apply for ') + (program ? program.name : opportunity.name)}</DialogTitle>

      {hasApplied ? (
        <>
          <DialogContent dividers>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('Thanks for applying, send back your completd onboarding docs')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                setHasApplied(false);
              }}
              color="secondary"
            >
              {t('Ok')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <Formik
          initialValues={{
            message: '',
          }}
          onSubmit={handleApply}
          validate={validate}
        >
          {({ handleSubmit, handleChange, errors, touched, values }) => (
            <React.Fragment>
              <DialogContent dividers>
                <FormikForm>
                  <Box mb={2}>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      {t('Tell us a little about yourself and why you would like to volunteer for this program')}
                    </Typography>
                    <FormControl required variant="outlined" fullWidth>
                      <TextField id="outlined-multiline-static" multiline onChange={handleChange('message')} rows={5} />
                    </FormControl>
                  </Box>
                </FormikForm>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="default">
                  {t('Cancel')}
                </Button>
                <Button onClick={handleSubmit} color="secondary">
                  {t('Ok')}
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default React.memo(ApplyModal);
