import React, { PureComponent } from 'react';

export default class PageBackground extends PureComponent {
  static defaultProps = {
    styles: {},
  };

  render() {
    const styles = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      ...this.props.styles,
    };

    return (
      <div>
        <div style={styles} />
        <div
          style={{
            position: 'relative',
            zIndex: 1,
            minHeight: '100%',
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
