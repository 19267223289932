import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import LabelledValue from '../Common/LabelledValue';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Paper } from '@material-ui/core';
import { useProfile, useProfileMutations } from '../../hooks/useProfile';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import ApprovedTrainerCard from './ApprovedTrainerCard';

const ProfileForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile, isLoading } = useProfile();
  const { updateProfile } = useProfileMutations(profile?.id);

  const contactableForJobs = useMemo(() => (profile?.contactable_jobs === 'yes' ? true : false), [profile]);

  const handleFindChange = () => {
    updateProfile
      .mutateAsync({ ...profile, visible_in_find: !profile?.visible_in_find })
      .then(() => {
        dispatch(setMessage('Profile updated'));
      })
      .catch(() => {
        dispatch(setMessage('An error occurred during the request'));
      });
  };

  const handleJobChange = () => {
    updateProfile
      .mutateAsync({ ...profile, contactable_jobs: contactableForJobs ? 'no' : 'yes' })
      .then(() => {
        dispatch(setMessage('Profile updated'));
      })
      .catch(() => {
        dispatch(setMessage('An error occurred during the request'));
      });
  };

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" width="100%">
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('Personal information')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelledValue label={t('Name')}>{profile.name}</LabelledValue>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelledValue label={t('LPI ID')}>{profile.lpiid}</LabelledValue>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelledValue label={t('Email')}>{profile.email}</LabelledValue>
          </Grid>

          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => window.open('https://lpi.org/account')} variant="contained" color="primary">
                {t('Edit')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('Public portal')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t(
                'Publish your profile on the public portal to make it visible to other users and partners. You can find your personal page in the ',
              )}
              <Link href="https://www.lpi.org" target="_blank" color="primary">
                {t('LPI Find Portal.')}{' '}
              </Link>
            </Typography>
            <Typography variant="body2">
              {t("You can read the LPI's Privacy Policy ")}
              <Link href="https://www.lpi.org" target="_blank" color="primary">
                {t('here.')}
              </Link>
              {t('Your contact information will be shared online.')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DialogActions>
              <Box marginRight={4}>
                <Typography variant="body2">
                  {t('Status')}:{' '}
                  <span style={{ color: profile?.visible_in_find ? 'green' : 'gray' }}>
                    {profile?.visible_in_find ? t('Active') : t('Disabled')}
                  </span>
                </Typography>
              </Box>

              <Button
                disabled={updateProfile.isLoading}
                onClick={handleFindChange}
                variant="contained"
                color={profile?.visible_in_find ? 'default' : 'primary'}
              >
                {profile?.visible_in_find ? t('Disable') : t('Active')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Paper>
      {profile?.is_approved_trainer && <ApprovedTrainerCard profile={profile} />}

      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('Jobs')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t(
                'Send digest emails about job opportunities that match my profile from partners in my region. Notification frequency will be daily or slower. ',
              )}
            </Typography>
            <Typography variant="body2">
              {t("You can read the LPI's Privacy Policy")}{' '}
              <Link href="https://www.lpi.org/policies#Privacy_Statement" target="_blank" color="primary">
                {t('here.')}
              </Link>{' '}
              {t('Your contact information will not be shared with these partners.')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DialogActions>
              <Box marginRight={4}>
                <Typography variant="body2">
                  {t('Send emails that match Partner job post criteria')}:{' '}
                  <span style={{ color: contactableForJobs ? 'green' : 'gray' }}>
                    {contactableForJobs ? t('Active') : t('Disabled')}
                  </span>
                </Typography>
              </Box>

              <Button
                disabled={updateProfile.isLoading}
                onClick={handleJobChange}
                variant="contained"
                color={contactableForJobs ? 'default' : 'primary'}
              >
                {contactableForJobs ? t('Disable') : t('Active')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default React.memo(ProfileForm);
