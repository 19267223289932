import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const LoadingState = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" p="5rem">
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default LoadingState;
