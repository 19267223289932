import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend';

const QUERY_KEY = 'shop.paypalCheckoutURL';

/**
 * Get the checkout URL for the given orderId
 * @param { string } orderId Required. The order ID of the cart
 */
const usePaypalCheckoutURL = orderId => {
  const req = useQuery([QUERY_KEY, orderId], () => api.payments.paypalCheckoutURL({ cart_id: orderId }));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    checkoutURL: req.data?.checkout_url,
    ...req,
  };
};

export default usePaypalCheckoutURL;
