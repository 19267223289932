import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { init } from '../../store/reducers/AuthReducer';
import AppRouter from '../../navigation';
import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router-dom';
import { SERVER_UNDER_MAINTENANCE } from '../../navigation/routes';
import ServerUnderMaintenancePage from '../../routes/ServerUnderMaintenancePage';

const Wrapper = () => {
  const dispatch = useDispatch();
  const appInitialized = useSelector(state => state.auth.init);

  useEffect(() => {
    if (!appInitialized && window.location.pathname !== SERVER_UNDER_MAINTENANCE) {
      dispatch(init());
    }
  }, [dispatch, appInitialized]);

  if (window.location.pathname === SERVER_UNDER_MAINTENANCE) return <ServerUnderMaintenancePage />;

  return (
    appInitialized && (
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppRouter />
      </QueryParamProvider>
    )
  );
};

export default Wrapper;
