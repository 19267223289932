export const FETCH_INVITE_TRIGGER = 'Partnership.Invite.Fetch.Trigger';
export const FETCH_INVITE_SUCCESS = 'Partnership.Invite.Fetch.Success';
export const FETCH_INVITE_FAILURE = 'Partnership.Invite.Fetch.Failure';

export const ACCEPT_INVITE_TRIGGER = 'Partnership.Invite.Accept.Trigger';
export const ACCEPT_INVITE_SUCCESS = 'Partnership.Invite.Accept.Success';
export const ACCEPT_INVITE_FAILURE = 'Partnership.Invite.Accept.Failure';

export const DECLINE_INVITE_TRIGGER = 'Partnership.Invite.Decline.Trigger';
export const DECLINE_INVITE_SUCCESS = 'Partnership.Invite.Decline.Success';
export const DECLINE_INVITE_FAILURE = 'Partnership.Invite.Decline.Failure';

const INITIAL_STATE = {
  invite: null,
  fetching: false,
  accepted: false,
  submitted: false,
  loading: false,
};

const partnershipReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INVITE_TRIGGER:
      return {
        ...state,
        invite: null,
        accepted: false,
        submitted: false,
        fetching: true,
      };
    case FETCH_INVITE_SUCCESS:
      return {
        ...state,
        fetching: false,
        invite: action.payload,
      };
    case FETCH_INVITE_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case ACCEPT_INVITE_TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        submitted: true,
        accepted: true,
      };
    case ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case DECLINE_INVITE_TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case DECLINE_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        submitted: true,
      };
    case DECLINE_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const fetchInvite = token => ({
  type: FETCH_INVITE_TRIGGER,
  payload: token,
});

export const fetchInviteSuccess = invite => ({
  type: FETCH_INVITE_SUCCESS,
  payload: invite,
});

export const fetchInviteFailure = error => ({
  type: FETCH_INVITE_FAILURE,
  payload: error,
});

export const acceptInvite = token => ({
  type: ACCEPT_INVITE_TRIGGER,
  payload: token,
});

export const acceptInviteSuccess = () => ({
  type: ACCEPT_INVITE_SUCCESS,
});

export const acceptInviteFailure = error => ({
  type: ACCEPT_INVITE_FAILURE,
  payload: error,
});

export const declineInvite = token => ({
  type: DECLINE_INVITE_TRIGGER,
  payload: token,
});

export const declineInviteSuccess = () => ({
  type: DECLINE_INVITE_SUCCESS,
});

export const declineInviteFailure = error => ({
  type: DECLINE_INVITE_FAILURE,
  payload: error,
});

export default partnershipReducer;
