import {
  Box,
  CircularProgress,
  Container,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/App/Layout';
import Panel from '../components/Common/Panel';
import { useNotificationMutation, useNotifications } from '../hooks/useNotifications';
import joinComponents from '../utils/joinComponents';
import { format } from 'date-fns';
import processNotificationType from '../utils/processNotificationType';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MarkunreadRoundedIcon from '@material-ui/icons/MarkunreadRounded';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { notifications } = useNotifications({ limit: 50 });
  const { markAllAsRead, markAsRead } = useNotificationMutation();

  return (
    <Layout title={t('Notifications')}>
      <Box p={2} pt={4}>
        <Container maxWidth="md">
          <Panel>
            <List component="nav">
              {!notifications.length && <Typography gutterBottom>{t("There's no notification now")}</Typography>}

              {joinComponents(
                notifications.map(notification => (
                  <ListItem
                    key={notification.id}
                    style={{ backgroundColor: notification.seen ? 'inherit' : '#ffcd0429' }}
                  >
                    <ListItemText
                      primary={processNotificationType(notification, markAsRead)}
                      secondary={format(new Date(notification.create_date), 'PPP, p')}
                    />
                    <Box style={{ marginLeft: 10 }}>
                      <Tooltip title={notification.seen ? t('Mark as unread') : t('Mark as read')}>
                        <IconButton onClick={() => markAsRead.mutateAsync(notification.id)}>
                          {notification.seen ? <DraftsRoundedIcon /> : <MarkunreadRoundedIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                )),
                <Divider />,
              )}
            </List>

            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={t('Mark all as read')}>
                <IconButton
                  size="small"
                  onClick={() => markAllAsRead.mutateAsync()}
                  disabled={!notifications.filter(notification => !notification.seen).length}
                >
                  {markAllAsRead.isLoading ? <CircularProgress size={25} /> : <DoneAllIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Panel>
        </Container>
      </Box>
    </Layout>
  );
};

export default NotificationsPage;
