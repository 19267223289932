import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 30,
    marginBottom: 30,
  },
  button: {
    marginTop: theme.spacing(),
  },
  spaced: {
    marginBottom: theme.spacing(2),
  },
}));

const init = {
  lpiid: '',
  password: '',
};

const validate = values => {
  const errors = {};
  if (!values.lpiid) {
    errors.lpiid = 'LPI ID is required';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

const LoginForm = ({ loading, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Formik initialValues={init} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Form className={classes.form}>
          <TextField
            name="lpiid"
            fullWidth
            variant="outlined"
            label={t('Email or LPI ID')}
            value={values['lpiid']}
            error={!!touched['lpiid'] && !!errors['lpiid']}
            helperText={touched['lpiid'] ? errors['lpiid'] : ''}
            onChange={handleChange('lpiid')}
            className={classes.spaced}
          />

          <TextField
            type="password"
            name="password"
            fullWidth
            variant="outlined"
            label={t('Password')}
            value={values['password']}
            error={!!touched['password'] && !!errors['password']}
            helperText={touched['password'] ? errors['password'] : ''}
            onChange={handleChange('password')}
            className={classes.spaced}
          />

          {loading ? (
            <Grid container justify="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Button
              fullWidth
              disabled={loading}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('Login')}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default React.memo(LoginForm);
