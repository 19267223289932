import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { formatDate } from '../../utils';
import membersLogo from '../../assets/images/lpi.members.logo.black.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    textAlign: 'left',
  },
  details: {
    margin: 24,
    display: "flex",
    flexDirection: "row",
  },
  stateLabel: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: '#219653',
    color: '#FFF',
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  cover: {
    width: 320,
    height: 300,
  },
}));

const MembershipPlan = ({ user }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardMedia title={user.membership_plan} className={classes.cover} image={membersLogo} />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {user.membership_plan}
            </Typography>
            <Typography varian="body1">
              {t('Membership ends on')} {formatDate(user.membership_stop, 'MMMM d, yyyy')}
            </Typography>

            <div className={classes.stateLabel}>{user.membership_state}</div>

            <Button variant="contained" href={user.membership_certificate_url}>
              {t('Download Membership Certificate')}
            </Button>
          </CardContent>
        </div>
      </Card>
    </Container>
  );
};

export default React.memo(MembershipPlan);
