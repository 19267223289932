import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  notAvailable: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#263238',
    padding: '.5rem',
  },
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
  },
  gridItem: {
    position: 'relative',
  },
  unavailableOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(55,55,55,.5)',
    pointerEvents: 'none',
    zIndex: '100',
  },
});

const ProductsGrid = ({ products, loading, handleClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return !_isEmpty(products) ? (
    <Grid container spacing={3}>
      {_values(products).map(product => (
        <Grid item xs={12} md={6} lg={3} key={product.id}>
          <Card
            onClick={() => {
              product.is_available === true && handleClick(product);
            }}
            className={classes.gridItem}
          >
            {product.is_available === false && (
              <Box display="flex" justifyContent="center" alignItems="center" className={classes.unavailableOverlay}>
                <Typography className={classes.notAvailable} variant="h5" gutterBottom color="primary">
                  {t('Product Unavailable')}
                </Typography>
              </Box>
            )}
            <CardActionArea>
              <img src={product.image} alt={product.name} className={classes.image} />
              <CardContent>
                <Typography className={classes.title} variant="caption" gutterBottom>
                  {t('Product')}
                </Typography>
                <Typography variant="h5">{product.name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : loading ? (
    <LoadingState />
  ) : (
    <EmptyState />
  );
};

export default React.memo(ProductsGrid);
