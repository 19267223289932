import React from 'react';

/** Array.join() but with React components */
const joinComponents = (components, separator, keepEmpty = false) => {
  if (!components.length) return null;

  components = components.filter(el => keepEmpty || !!el).reduce((acc, curr) => acc.concat([curr, separator]), []);
  components.pop();

  return React.Children.map(components, (child, index) => <React.Fragment key={index}>{child}</React.Fragment>);
};

export default joinComponents;
