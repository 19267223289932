import * as types from './types';

export const fetchCertifications = () => ({
  type: types.FETCH_CERTIFICATIONS,
});

export const fetchCertificationsSuccess = certifications => ({
  type: types.FETCH_CERTIFICATIONS_SUCCESS,
  payload: certifications,
});

export const fetchCertificationsFailure = error => ({
  type: types.FETCH_CERTIFICATIONS_FAILURE,
  payload: error,
});

export const fetchScoreboard = () => ({
  type: types.FETCH_SCOREBOARD,
});

export const fetchScoreboardSuccess = scoreboard => ({
  type: types.FETCH_SCOREBOARD_SUCCESS,
  payload: scoreboard,
});

export const fetchScoreboardFailure = error => ({
  type: types.FETCH_SCOREBOARD_FAILURE,
  payload: error,
});

export const fetchAchievements = () => ({
  type: types.FETCH_ACHIEVEMENTS,
});

export const fetchAchievementsSuccess = achievements => ({
  type: types.FETCH_ACHIEVEMENTS_SUCCESS,
  payload: achievements,
});

export const fetchAchievementsFailure = error => ({
  type: types.FETCH_ACHIEVEMENTS_FAILURE,
  payload: error,
});
