import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 20px;
`;
const Label = styled.div`
  font-size: 14px;
  color: #a1a1a1;
  padding-bottom: 8px;
`;
const Value = styled.div`
  font-size: 16px;
`;

const LabelledValue = ({ label, children }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </Wrapper>
  );
};

LabelledValue.propTypes = {
  label: PropTypes.string,
};

export default React.memo(LabelledValue);
