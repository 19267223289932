import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  FETCH_INVITE_TRIGGER,
  ACCEPT_INVITE_TRIGGER,
  DECLINE_INVITE_TRIGGER,
  fetchInviteSuccess,
  fetchInviteFailure,
  acceptInviteSuccess,
  acceptInviteFailure,
  declineInviteSuccess,
  declineInviteFailure,
} from '../reducers/PartnershipReducer';

import { partnerApi } from '../../backend';

function* acceptInviteSaga({ payload }) {
  try {
    const certifications = yield call([partnerApi, partnerApi.acceptInvite], payload);
    yield put(acceptInviteSuccess(certifications));
  } catch (error) {
    yield put(acceptInviteFailure(error));
  }
}

function* declineInviteSaga({ payload }) {
  try {
    yield call([partnerApi, partnerApi.rejectInvite], payload);
    yield put(declineInviteSuccess());
  } catch (error) {
    yield put(declineInviteFailure(error));
  }
}

function* fetchInviteSaga({ payload }) {
  try {
    const scoreboard = yield call([partnerApi, partnerApi.fetchInvite], payload);
    yield put(fetchInviteSuccess(scoreboard));
  } catch (error) {
    yield put(fetchInviteFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([FETCH_INVITE_TRIGGER], fetchInviteSaga),
    yield takeLatest([ACCEPT_INVITE_TRIGGER], acceptInviteSaga),
    yield takeLatest([DECLINE_INVITE_TRIGGER], declineInviteSaga),
  ]);
}
