import React from 'react';
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import EmptyState from '../../components/Common/EmptyState';

import useDocuments from '../../hooks/Documents/useDocuments';
import useDocumentTags from '../../hooks/Documents/useDocumentTags';
import Layout from '../../components/App/Layout';
import Document from '../../components/Documents/Document';
import Filters from '../../components/App/Filters/Filters';

const useStyles = makeStyles(theme => ({
  container: {
    height: '80vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ROOT_FOLDER = 'People';

export default function DocumentsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const tagReq = useDocumentTags();

  const {
    status,
    folders,
    controller: { filters, setFilters },
  } = useDocuments({ tags: [] });

  const handleChipDelete = (filterName, label) => {
    switch (filterName) {
      case 'tags':
        return setFilters({ ...filters, tags: filters.tags.filter(tag => tag !== label) });
    }
  };

  const getChipLabels = filterName => {
    switch (filterName) {
      case 'tags':
        return filters.tags;
    }
  };

  return (
    <Layout title={t('Documents')}>
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Documents from LPI')}
        </Typography>
        {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
      </Box>

      <Filters filters={filters} getChipLabels={getChipLabels} onChipDelete={handleChipDelete}>
        <Autocomplete
          multiple
          options={tagReq.tags}
          renderInput={params => <TextField {...params} label="Filter by tag" variant="outlined" />}
          loading={tagReq.status === 'loading'}
          value={filters.tags || []}
          onChange={(_e, tags) => setFilters({ ...filters, tags })}
        />
      </Filters>

      <Box p={2}>
        {(() => {
          switch (status) {
            case 'idle':
            case 'loading':
              return (
                <Box className={classes.container}>
                  <CircularProgress color="primary" />
                </Box>
              );

            case 'error':
              return (
                <Box className={classes.container}>
                  <Typography>{t('Error during the request')}</Typography>
                </Box>
              );

            case 'success':
              if (!folders.length) {
                return (
                  <Box className={classes.container}>
                    <EmptyState />
                  </Box>
                );
              } else {
                // If Partnership folder exists, put it open at the top
                const rootFolderIndex = folders.findIndex(document => document.name === ROOT_FOLDER);
                const openRootFolder = rootFolderIndex !== -1;
                if (openRootFolder) {
                  folders.unshift(folders.splice(rootFolderIndex, 1)[0]);
                }

                return folders.map((folder, index) => (
                  <Accordion key={folder.id} defaultExpanded={!index && openRootFolder}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <FolderOpenOutlinedIcon />
                        </Grid>
                        <Grid item>
                          <Typography>{folder.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">({folder.documents.length} files)</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        {folder.documents.map(document => (
                          <Document key={document.id} file={document} />
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ));
              }
          }
        })()}
      </Box>
    </Layout>
  );
}
