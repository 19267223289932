import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useSavedFilters from '../../../hooks/Jobs/useSavedFilters';
import useSavedFiltersMutations from '../../../hooks/Jobs/useSavedFiltersMutations';
import {
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../store/reducers/SnackReducer';

const SavedFilters = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState({ open: false, content: null });
  const [currentSavedFiltersObj, setCurrentSavedFiltersObj] = useState(null);

  const { status, savedFilters } = useSavedFilters();
  const { createSavedFilter, deleteSavedFilter } = useSavedFiltersMutations();

  useEffect(() => {
    // Reset form if user change filter set
    if (!!currentSavedFiltersObj && JSON.stringify(filters) != JSON.stringify(currentSavedFiltersObj.domain)) {
      setCurrentSavedFiltersObj(null);
    }
  }, [filters]);

  const handleSavedFilterChange = (_e, savedFiltersObj) => {
    setCurrentSavedFiltersObj(savedFiltersObj);

    setFilters(savedFiltersObj?.domain || {});
  };

  const handleCreateSavedFilter = event => {
    event.preventDefault();
    const name = new FormData(event.target).get('name');

    createSavedFilter
      .mutateAsync({
        name,
        filters,
      })
      .then(() => {
        setCurrentSavedFiltersObj({ name, filters });
        dispatch(setMessage(`The filter set "${name}" has been created`));
      })
      .catch(error => {
        console.error(error);
        dispatch(setMessage(`An error occurred during the request`));
      })
      .finally(() => {
        setDialog({ open: false });
      });
  };

  const handleDeleteSavedFilter = () => {
    deleteSavedFilter
      .mutateAsync(currentSavedFiltersObj.id)
      .then(() => {
        setCurrentSavedFiltersObj(null);
        dispatch(setMessage(`The filter set has been deleted`));
      })
      .catch(error => {
        console.error(error);
        dispatch(setMessage(`An error occurred during the request`));
      })
      .finally(() => {
        setDialog({ open: false });
      });
  };

  const handleOpenCreateDialog = () => {
    setDialog({
      open: true,
      content: (
        <>
          <DialogTitle>{t('Save this filters set')}</DialogTitle>
          <form onSubmit={handleCreateSavedFilter}>
            <DialogContent>
              <TextField variant="outlined" name="name" label={t('Name')} required />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => setDialog({ dialog, open: false })}>
                {t('Close')}
              </Button>
              <Button variant="contained" type="submit" color="primary">
                {t('Save filter')}
              </Button>
            </DialogActions>
          </form>
        </>
      ),
    });
  };

  const handleOpenDeleteDialog = () => {
    setDialog({
      open: true,
      content: (
        <>
          <DialogTitle>{t('Do you want to delete this filter?')}</DialogTitle>
          <DialogActions>
            <Button color="secondary" onClick={() => setDialog({ dialog, open: false })}>
              {t('Close')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleDeleteSavedFilter}>
              {t('Delete')}
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <Autocomplete
          options={savedFilters}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Saved filters" variant="outlined" />}
          loading={status === 'loading'}
          value={currentSavedFiltersObj}
          onChange={handleSavedFilterChange}
        />
      </Grid>

      <Grid item xs={2}>
        {!!currentSavedFiltersObj ? (
          <Tooltip title={t('Delete this filters set')}>
            <IconButton onClick={handleOpenDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t('Save this filters set')}>
            <IconButton onClick={handleOpenCreateDialog}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={6}></Grid>

      <Dialog open={!!dialog.open}>{dialog.content}</Dialog>
    </Grid>
  );
};

export default React.memo(SavedFilters);
