import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JOB_SORT } from '../../../consts';
import { getTranslatedSortName } from '../../../utils/getTranslatedEnum';

const SortSelect = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="sort">{t('Order by')}</InputLabel>
      <Select
        labelId="sort"
        label={t('Order by')}
        value={filters.sort || 'relevance'}
        onChange={event =>
          setFilters({ ...filters, sort: event.target.value === 'relevance' ? null : event.target.value })
        }
      >
        {JOB_SORT.map(sortName => (
          <MenuItem key={sortName} value={sortName}>
            {getTranslatedSortName(sortName, t)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortSelect;
