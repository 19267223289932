import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAppliedOpportunities } from '../../store/selectors';
import { applyOpportunity } from '../../store/reducers/VolunteersReducer';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useParams } from 'react-router-dom';
import { VOLUNTEER_OPPORTUNITIES_LIST, VOLUNTEER_PROGRAMS_LIST } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import ApplyModal from './ApplyModal';
import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';
import OpportunitiesTable from './OpportunitiesTable';
import { getTranslatedRemuneration, tActivityLocation } from '../../utils/getTranslatedEnum';
import { useAppliedOpportunities, useVolunteersMutations } from '../../hooks/Volunteers';
import { Link } from '@material-ui/core';

import UploadMaterialModal from './UploadMaterialModal';

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
  },
  alignLeft: {
    marginLeft: 'auto',
  },
});

const ProgramsGrid = ({ programs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);

  const { apply, withdraw } = useVolunteersMutations();
  const [program, setProgram] = React.useState();
  const [item, setItem] = React.useState();
  let history = useHistory();
  const { id, subId } = useParams();

  React.useEffect(() => {
    if (program) {
      setItem(program.opportunity?.find(({ id }) => id == subId));
    }
  }, [program, subId]);

  React.useEffect(() => {
    setProgram(programs?.find(p => p.id == id));
  }, [programs, id]);

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {programs.map(program => (
        <Grid item xs={12} md={6} key={program.id}>
          <Card style={{ position: 'relative' }}>
            {program.image && (
              <img src={`data:image/gif;base64,${program.image}`} alt={program.name} className={classes.image} />
            )}
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{program.name}</Typography>
              </Box>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {program.description}
              </Typography>
              <Typography variant="caption" component="p">
                <Typography variant="caption" color="textSecondary">
                  {t('Coordinators') + ': '}
                </Typography>
                {program.coordinators?.join(', ')}
              </Typography>
              <Typography variant="caption" component="p">
                <Typography variant="caption" color="textSecondary">
                  {t('Open to') + ': '}
                </Typography>
                {program.open_to}
              </Typography>
              <Typography variant="caption" component="p">
                <Typography variant="caption" color="textSecondary">
                  {t('Activity Location') + ': '}
                </Typography>
                {tActivityLocation(program.activity_location)}
              </Typography>
            </CardContent>

            <CardActions disableSpacing>
              <Box display="flex" justifyContent="flex-end" width="100%">
                {program.web_page && (
                  <Button size="small" color="secondary" href={program.web_page} target="_blank" rel="noreferrer">
                    {t('More information')}
                  </Button>
                )}
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => history.push(VOLUNTEER_OPPORTUNITIES_LIST + '?program_id=' + program.id)}
                >
                  {t('Show opportunities')}
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(ProgramsGrid);
