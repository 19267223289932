import axios from 'axios';
import { SERVER_UNDER_MAINTENANCE } from '../navigation/routes';
import api from './index';

let backendHost;

const hostname = window && window.location && window.location.hostname;

const backends = {
  'people.lpi.org': 'https://odoo.lpi.org',
  'people.preview.lpi.org': 'https://odoo.preview.lpi.org',
  'lpi-people.metaserver.it': 'https://lpi.metaserver.it',
  'people.i18n.preview.lpi.org': 'https://lpi.metaserver.it',
};

// check if hostname is in the list of odoo backends
if (hostname in backends) {
  backendHost = backends[hostname];
} else {
  if (process.env.REACT_APP_BACKEND_HOST) {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://lpi.metaserver.it';
  } else {
    if (hostname.includes('people')) {
      backendHost = hostname.replace('people', 'odoo');
    } else if (hostname.includes('members')) {
      backendHost = hostname.replace('members', 'odoo');
    } else {
      backendHost = 'odoo.lpi.org';
    }

    // add protocol based on current protocol
    backendHost = `${window.location.protocol}//${backendHost}`;
  }
}

if (window.odooBackendUrl && window.odooBackendUrl !== '' && window.odooBackendUrl != '$ODOO_EXTERNAL_API_URL') {
  backendHost = window.odooBackendUrl;
}

export const LPI_HOST = backendHost;

const client = axios.create({
  timeout: 15 * 1000,
});

client.interceptors.response.use(
  response => response,
  async error => {
    // If client is online but Odoo server is unreachable, redirect to the maintenance page
    if (navigator.onLine && !(await api.monitor.isServerUp())) {
      window.location.pathname = SERVER_UNDER_MAINTENANCE;
    }

    console.error(error);
    throw new Error(error);
  },
);

export default client;
