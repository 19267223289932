import i18n from 'i18next';

export const getTranslatedJobType = type => {
  switch (type) {
    case 'fulltime':
      return i18n.t('Full time');
    case 'parttime':
      return i18n.t('Part Time');
    case 'contract':
      return i18n.t('Contract');
    case 'volunteer':
      return i18n.t('Volunteer');
    case 'internship':
      return i18n.t('Internship');
  }
};

export const getTranslatedSortName = sort => {
  switch (sort) {
    case 'relevance':
      return i18n.t('Relevance');
    case 'newest':
      return i18n.t('Newest');
    case 'oldest':
      return i18n.t('Oldest');
  }
};

export const getTranslatedLocationStatus = status => {
  switch (status) {
    case 'verified':
      return i18n.t('Verified');
    case 'site-admin-review':
      return i18n.t('Site Admin review');
    case 'lpi-review':
      return i18n.t('LPI review');
  }
};

export const getTranslatedLocationType = value => {
  switch (value) {
    case 'test-center':
      return i18n.t('Test center');
    case 'training-center':
      return i18n.t('Training center');
    case 'training-test-center':
      return i18n.t('Training and test center');
  }
};

export const getTranslatedPartnerType = value => {
  switch (value) {
    case 'teacher':
      return i18n.t('Instructor');
    case 'student':
      return i18n.t('Student');
    case 'proctor':
      return i18n.t('Proctor');
    case 'site-admin':
      return i18n.t('Site Admin');
  }
};

export const getTranslatedRemuneration = value => {
  switch (value) {
    case 'none':
      return i18n.t('None');
    case 'meals':
      return i18n.t('Meals');
    case 'onorarium':
      return i18n.t('Onorarium');
  }
};

export const getTranslatedOpportunityStatus = value => {
  switch (value) {
    case 'active':
      return i18n.t('Active');
    case 'expired':
      return i18n.t('Expired');
    case 'suspended':
      return i18n.t('Suspended');
    case 'closed':
      return i18n.t('Closed');
  }
};

export const getTranslatedApplicationStatus = value => {
  switch (value) {
    case 'accepted':
      return i18n.t('Accepted');
    case 'pending':
      return i18n.t('Pending');
    case 'reject':
      return i18n.t('Rejected');
  }
};

export const tActivityLocation = safetyChecks => {
  switch (safetyChecks) {
    case 'online_solo':
      return i18n.t('Online');
    case 'online_group':
      return i18n.t('Online group');
    case 'in_person':
      return i18n.t('In person');
    default:
      return safetyChecks;
  }
};
