import React from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../App/PageTitle';
import Typography from '@material-ui/core/Typography';

const PendingStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t('Your request was submitted')} />
      <Typography variant="body1">
        {t(
          'Almost done! Your membership request has been submitted for approval. Linux Professional Institute staff must now review it. Check your dashboard and/or your email for further updates.',
        )}
      </Typography>
    </>
  );
};

export default React.memo(PendingStep);
