import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend';

const QUERY_KEY = 'support-topic';

/** Get a single location */
const useHelpdeskTopics = id => {
  const req = useQuery([QUERY_KEY, id], () => api.support_topics.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    topics: req.data?.results || [],
    ...req,
  };
};

export default useHelpdeskTopics;
