import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { applyOpportunity, saveOpportunity, unsaveOpportunity } from '../../store/reducers/VolunteersReducer';
import { getAppliedOpportunities, getSavedOpportunities } from '../../store/selectors';

import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorderOutlined';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ApplyModal from './ApplyModal';
import LabelledValue from '../Common/LabelledValue';
import { useAppliedOpportunities, useFavoriteOpportunities, useVolunteersMutations } from '../../hooks/Volunteers';
import { getTranslatedOpportunityStatus } from '../../utils/getTranslatedEnum';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

const OpportunityDetail = ({ item, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [hasApplied, setHasApplied] = React.useState(false);
  const { apply } = useVolunteersMutations();

  const { applications } = useAppliedOpportunities(); // useSelector(getAppliedOpportunities);

  React.useEffect(() => {
    item && setHasApplied(applications.find(a => a.opportunity?.id == item.id));
  }, [item, applications]);

  const classes = useStyles();

  const handleApplyClick = React.useCallback(() => {
    if (!hasApplied) {
      setModalOpen(true);
    }
  }, [hasApplied]);

  const handleApply = React.useCallback(
    values => {
      console.log(values);
      apply.mutateAsync(values);
    },
    [dispatch],
  );

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
          title={t('VOLUNTEER OPPORTUNITY')}
          subheader={
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" color="textPrimary">
                {item.name}
              </Typography>
              <Typography variant="subtitle1" color="textPrimary">
                {item.code}
              </Typography>
            </Box>
          }
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <IconButton onClick={props.onCloseClick}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <LabelledValue>{item.description}</LabelledValue>
          <LabelledValue label={t('Status')}>{getTranslatedOpportunityStatus(item.status)}</LabelledValue>
          <LabelledValue label={t('Location')}>{item.location}</LabelledValue>
          <LabelledValue label={t('Application Deadline')}>
            {item.deadline ? new Date(item.deadline).toLocaleDateString() : ''}
          </LabelledValue>
        </CardContent>
        <CardActions disableSpacing classes={{ root: classes.cardActions }}>
          <Box mr={'auto'}>
            <Button onClick={handleApplyClick} color="secondary" disabled={hasApplied}>
              {hasApplied ? t('Already applied') : t('Apply')}
            </Button>
          </Box>
        </CardActions>
        <ApplyModal
          open={modalOpen}
          opportunity={item}
          handleClose={() => {
            setModalOpen(false);
          }}
          handleOk={values => {
            handleApply({
              opportunity_id: item.id,
              name: item.name,
              ...values,
            });
          }}
        />
      </Card>
    )
  );
};

export default React.memo(OpportunityDetail);
