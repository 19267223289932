import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { BENEFITS } from '../../navigation/routes';
import { useBenefits } from '../../store/hooks/benefits';

import Layout from '../../components/App/Layout';
import BenefitsGrid from '../../components/Benefits/BenefitsGrid';

const BenefitsPage = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const { requests, benefits } = useBenefits();

  return (
    <Layout title={path === BENEFITS ? t('Benefits') : t('Benefit Page')} back={path !== BENEFITS} backPath={BENEFITS}>
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Exclusive offers for you')}
        </Typography>
        {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
      </Box>

      <Box mt={4} px={2}>
        <BenefitsGrid items={benefits} loading={requests.fetchBenefits.loading} />
      </Box>
    </Layout>
  );
};

export default React.memo(BenefitsPage);
