import React from 'react';
import Layout from '../../components/App/Layout';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LanguagesAutocomplete from '../../components/Forms/LanguageAutocomplete';
import { useFormik } from 'formik';
import { Controller, useForm } from 'react-hook-form';
import { useProfile, useProfileMutations } from '../../hooks/useProfile';

const TrainerInfoPage = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = useProfile();

  if (isError) {
    throw new Error(error);
  }

  return (
    <Layout title="Trainer profile">
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Manage your trainer profile information')}
        </Typography>
      </Box>

      <Container>
        <Card variant="outlined" style={{ margin: '32px 9px 32px 18px ' }}>
          <CardContent>{isLoading ? <CircularProgress /> : <TrainerForm initData={data} />}</CardContent>
        </Card>
      </Container>
    </Layout>
  );
};

const TrainerForm = ({ initData }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      languages: initData.languages,
      publicUrl: initData.trainer_public_url,
    },
  });

  const { updateProfile } = useProfileMutations(initData.id);

  const submit = values => {
    updateProfile.mutateAsync({
      ...initData,
      languages: values.languages.map(language => language.id),
      trainer_public_url: values.publicUrl,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField label={t('Public profile URL')} variant="outlined" fullWidth {...register('publicUrl')} />
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom>{t('What languages do you use in your trainings?')}</Typography>
          <Controller
            name="languages"
            control={control}
            render={({ field }) => (
              <LanguagesAutocomplete
                multiple
                label={t('Languages')}
                error={errors.languages}
                value={field.value}
                onChange={value => {
                  const _value = value.filter(
                    (value, index, self) =>
                      index === self.findIndex(t => t.place === value.place && t.name === value.name),
                  );
                  field.onChange(_value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button type="submit" variant="contained" color="primary">
          {updateProfile.isLoading ? <CircularProgress size={25} /> : t('Save')}
        </Button>
      </Box>
    </form>
  );
};

export default TrainerInfoPage;
