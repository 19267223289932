import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Layout from '../../components/App/Layout';
import { getTranslatedLocationType } from '../../utils/getTranslatedEnum';
import { LOCATIONS, LOCATION_APPROVE } from '../../navigation/routes';
import useLpiLocation from '../../hooks/Locations/useLpiLocation';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import useAttachmentMutations from '../../hooks/Attachment/useAttachmentMutations';
import useLpiLocations from '../../hooks/Locations/useLpiLocations';
import useLpiLocationMutations from '../../hooks/Locations/useLpiLocationMutations';

import { FaFile } from 'react-icons/fa';

const ApproveLocationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { createAttachment } = useAttachmentMutations();
  const [files, setFiles] = useState();
  const locationId = useRouteMatch({
    path: LOCATION_APPROVE,
  })?.params?.id;
  const { location, status } = useLpiLocation(locationId);
  const { approve } = useLpiLocationMutations();

  const isMutating = createAttachment.isLoading || approve.isLoading;

  const handleChange = ({ target }) => {
    setFiles(Array.from(target.files));
  };

  const handleSubmit = async () => {
    if (!files) {
      dispatch(setMessage(t('Please select a file to upload')));
      return;
    }

    try {
      await createAttachment.mutateAsync({
        model: 'lpi.partner.location',
        id: locationId,
        files,
      });

      await approve.mutateAsync(locationId);

      dispatch(setMessage(t('Documents successfully uploaded')));
      history.push(LOCATIONS);
    } catch (err) {
      console.log(err);
      dispatch(setMessage(t('An error occurred during the request')));
    }
  };

  return (
    <Layout title={t('Approve location')} back={true}>
      <Container maxWidth="lg">
        {(() => {
          switch (status) {
            case 'idle':
            case 'loading':
              return (
                <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              );
            case 'error':
              return (
                <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                  <Typography>{t('Cannot find this location')}</Typography>
                </Box>
              );
            case 'success':
              if (location.status !== 'site-admin-review')
                return (
                  <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                    <Typography>{t('This location has already been approved')}</Typography>
                  </Box>
                );
              else
                return (
                  <Paper style={{ padding: 20, margin: 20, marginTop: 40 }} elevation={0}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h3">
                          {t('Partner info')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {!!location.lpi_partner?.name && (
                          <Typography>
                            <strong>{t('Partner name')}</strong>: {location.lpi_partner.name}
                          </Typography>
                        )}
                        {!!location.lpi_partner?.bio && (
                          <Typography>
                            <strong>{t('Bio')}</strong>: {location.lpi_partner.bio}
                          </Typography>
                        )}
                        {!!location.lpi_partner?.email && (
                          <Typography>
                            <strong>{t('Email')}</strong>:{' '}
                            <a href={`mailto:${location.lpi_partner.email}`}>{location.lpi_partner.email}</a>
                          </Typography>
                        )}
                        {!!location.lpi_partner?.phone && (
                          <Typography>
                            <strong>{t('Phone')}</strong>: {location.lpi_partner.phone}
                          </Typography>
                        )}
                        {!!location.lpi_partner?.website && (
                          <Typography>
                            <strong>{t('Website')}</strong>: {location.lpi_partner.website}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="flex-end">
                          {location.lpi_partner.image_url && (
                            <img
                              style={{ maxWidth: '100%', padding: 10 }}
                              src={location.lpi_partner.image_url}
                              alt="partner logo"
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box py={2}>
                          <Divider />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5" component="h3">
                          {t('Location info')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography>
                          <strong>{t('Name')}</strong>: {location.name}
                        </Typography>
                        <Typography>
                          <strong>{t('Type')}</strong>: {getTranslatedLocationType(location.type, t)}
                        </Typography>
                        {!!location.description && (
                          <Typography>
                            <strong>{t('Description')}</strong>: {location.description}
                          </Typography>
                        )}
                        {!!location.country?.name && (
                          <Typography>
                            <strong>{t('Country')}</strong>: {location.country.name}
                          </Typography>
                        )}
                        {!!location.state?.name && (
                          <Typography>
                            <strong>{t('State')}</strong>: {location.state.name}
                          </Typography>
                        )}
                        {!!location.address && (
                          <Typography>
                            <strong>{t('Address')}</strong>: {location.address}
                          </Typography>
                        )}
                        {!!location.city && (
                          <Typography>
                            <strong>{t('City')}</strong>: {location.city}
                          </Typography>
                        )}
                        {!!location.zip && (
                          <Typography>
                            <strong>{t('Zip code')}</strong>: {location.zip}
                          </Typography>
                        )}
                      </Grid>

                      {location.attachments && location.attachments.length && (
                        <>
                          <Grid item xs={12}>
                            <Box py={2}>
                              <Divider />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="h5" component="h3">
                              {t('Documents')}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <List dense>
                              {location.attachments?.map(attachment => {
                                return (
                                  <ListItem key={attachment.url}>
                                    <ListItemIcon>
                                      <FaFile />
                                    </ListItemIcon>
                                    <Link
                                      href={attachment.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      color="textPrimary"
                                    >
                                      <ListItemText primary={attachment.filename} />
                                    </Link>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <Box py={2}>
                          <Divider />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5" component="h3" gutterBottom>
                          {t('Submit your files')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Paper
                          style={{ marginBottom: 10, padding: 10, borderRadius: 1, backgroundColor: '#FFF8E4' }}
                          elevation={1}
                        >
                          <strong>{!!files ? t('Loaded files') : t('Upload signed documents here')}</strong>
                          <ul>{!!files && files.map(file => <li key={file.name}>{file.name}</li>)}</ul>

                          <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" component="label">
                              {t('Browse')}
                              <input type="file" multiple hidden onChange={handleChange} />
                            </Button>
                          </Box>
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isMutating}>
                            {isMutating ? <CircularProgress size={24} /> : t('Submit')}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                );
          }
        })()}
      </Container>
    </Layout>
  );
};
export default React.memo(ApproveLocationPage);
