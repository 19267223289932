import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify, parse } from 'query-string';

import clean from '../utils/clean';

const filtersReducer = (_p, nextFilters) => {
  // Clean & sort properties
  return clean(nextFilters);
};

/**
 * Create a filter controller
 * It store state in search query, retrieve them when reloading the page and optimize the object for react-query caching system
 * @param {Record<string, any} initialFilters Initial filters
 */
const useFilters = initialFilters => {
  const history = useHistory();
  const [filters, setFilters] = useReducer(filtersReducer, initialFilters);

  /* Parse filters from search query at mount */
  useEffect(() => {
    const parsed = parse(history.location.search, { arrayFormat: 'bracket-separator' });
    if (parsed !== initialFilters) setFilters(parsed);
  }, []);

  /* Push stringified filters in the search query */
  useEffect(() => {
    history.replace({
      search: stringify(filters, { arrayFormat: 'bracket-separator' }),
    });
  }, [filters]);

  return {
    filters,
    setFilters,
  };
};

export default useFilters;
