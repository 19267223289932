import React, { memo, useMemo } from 'react';
import { Box, Grid, Typography, Chip, Paper, Tooltip, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorderOutlined';

import joinComponents from '../../utils/joinComponents';
import { getTranslatedJobType } from '../../utils/getTranslatedEnum';
import usePeopleOfferSearchMutations from '../../hooks/Jobs/usePeopleOfferSearchMutations';
import useApplications from '../../hooks/Jobs/useApplications';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'all 0.25s',

    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',

      '& .highlight': {
        textDecoration: 'underline',
      },
    },
  },

  applicationChip: {
    color: '#f9f9f9',
    backgroundColor: theme.palette.success.main,
    border: 'none',
    marginRight: theme.spacing(1),
  },
}));

const OfferPreview = ({ offer, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToFavorites, removeFromFavorites } = usePeopleOfferSearchMutations();
  const { status, applications } = useApplications();
  const hasApplied = useMemo(() => !!applications.find(appl => appl.job_id == offer.id), [applications, status]);

  const isMutating = addToFavorites.status === 'loading' || removeFromFavorites.status === 'loading';

  const handleFavoriteClick = event => {
    event.stopPropagation();
    if (offer.favorite) {
      removeFromFavorites.mutateAsync(offer.id);
    } else {
      addToFavorites.mutateAsync(offer.id);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={0} className={classes.root} onClick={() => onClick(offer)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* ORGANIZATION NAME & FAVORITE STAR */}
            <Box display="flex">
              <Typography variant="caption" style={{ flexGrow: 1 }}>
                {offer.organization || ''}
              </Typography>

              {!!hasApplied && (
                <Chip variant="outlined" size="small" label={t('Applied')} className={classes.applicationChip} />
              )}

              <Tooltip title={offer.favorite ? t('Remove from favorites') : t('Add to favorites')}>
                {(() => {
                  if (isMutating) return <CircularProgress size={25} color="primary" />;

                  if (offer.favorite) {
                    return <StarIcon color="primary" onClick={handleFavoriteClick} />;
                  } else {
                    return <StarOutlineIcon color="primary" onClick={handleFavoriteClick} />;
                  }
                })()}
              </Tooltip>
            </Box>
          </Grid>

          {/* TITLE */}
          <Grid item xs={12}>
            <Typography className="highlight" variant="h6">
              {offer.name}
            </Typography>
          </Grid>

          {/* LOCATION + WORKPLACE */}
          {(!!offer.country ||
            !!offer.state ||
            !!offer.city ||
            offer.remote ||
            offer.remote_international ||
            offer.inplace) && (
            <Grid item xs={12}>
              {joinComponents(
                [
                  (!!offer.country || !!offer.city) && (
                    <Typography display="inline" variant="subtitle2">
                      {[offer.country?.name, offer.state?.name, offer.city].filter(el => !!el).join(', ')}
                    </Typography>
                  ),

                  !!offer.remote && (
                    <Typography display="inline" variant="subtitle2">
                      {t('Remote')}
                    </Typography>
                  ),

                  !!offer.remote_international && (
                    <Typography display="inline" variant="subtitle2">
                      {t('Remote international')}
                    </Typography>
                  ),

                  !!offer.inplace && (
                    <Typography display="inline" variant="subtitle2">
                      {t('In office')}
                    </Typography>
                  ),
                ],
                <> | </>,
              )}
            </Grid>
          )}

          {/* SALARY + TYPE */}
          {(!!offer.contract_type || !!offer.salary) && (
            <Grid item xs={12}>
              {joinComponents(
                [
                  !!offer.contract_type && (
                    <Typography display="inline" variant="caption">
                      {getTranslatedJobType(offer.contract_type, t)}
                    </Typography>
                  ),

                  !!offer.salary && (
                    <Typography display="inline" variant="caption">
                      {offer.salary}
                    </Typography>
                  ),
                ],
                <> | </>,
              )}
            </Grid>
          )}

          {/* DESCRIPTION */}
          <Grid item xs={12}>
            <Box py={2}>
              <Typography variant="body2">
                {offer.description?.length > 160 ? offer.description.slice(0, 157) + '...' : offer.description}
              </Typography>
            </Box>
          </Grid>

          {/* CERTIFICATIONS */}
          {!!offer.certificates?.length && (
            <Grid item xs={12} lg={6}>
              <Box pb={2}>
                <Typography gutterBottom variant="subtitle2">
                  {t('Required certifications')}
                </Typography>
                {offer.certificates.map(certification => (
                  <Chip
                    style={{ margin: '0 0 3px 3px' }}
                    color="primary"
                    key={certification.id}
                    label={certification.name}
                  />
                ))}
              </Box>
            </Grid>
          )}

          {/* SKILLS */}
          {!!offer.skills?.length && (
            <Grid item xs={12} lg={6}>
              <Box pb={2}>
                <Typography gutterBottom variant="subtitle2">
                  {t('Skills')}
                </Typography>
                {offer.skills.map(skill => (
                  <Chip style={{ margin: '0 0 3px 3px' }} color="primary" key={skill.id} label={skill.name} />
                ))}
              </Box>
            </Grid>
          )}

          {/* START DATE */}
          {!!offer.job_start_date && (
            <Grid item xs={12}>
              <Typography variant="caption">
                {t('Start date')}: {new Date(offer.job_start_date).toLocaleDateString()}
              </Typography>
            </Grid>
          )}

          {/* PUBLICATION DATE */}
          {!!offer.publish_date && (
            <Grid item xs={12}>
              <Typography variant="caption">
                {t('Publication date')}: {new Date(offer.publish_date).toLocaleDateString()}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default memo(OfferPreview);
