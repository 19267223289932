import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const PROGRAMS_FETCH = 'Volunteers.FetchPrograms';
export const OPPORTUNITIES_FETCH = 'Volunteers.FetchOpportunities';
export const OPPORTUNITY_APPLY = 'Volunteers.ApplyOpportunity';
export const OPPORTUNITY_SAVE = 'Volunteers.SaveOpportunity';
export const OPPORTUNITY_UNSAVE = 'Volunteers.UnsaveOpportunity';

export const fetchPrograms = createRoutine(PROGRAMS_FETCH);
export const fetchOpportunities = createRoutine(OPPORTUNITIES_FETCH);
export const applyOpportunity = createRoutine(OPPORTUNITY_APPLY);
export const saveOpportunity = createRoutine(OPPORTUNITY_SAVE);
export const unsaveOpportunity = createRoutine(OPPORTUNITY_UNSAVE);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetchPrograms: { loading: false, error: null },
  fetchOpportunities: { loading: false, error: null },
  applyOpportunity: { loading: false, error: null },
  saveOpportunity: { loading: false, error: null },
  unsaveOpportunity: { loading: false, error: null },
};

const INITIAL_STATE = {
  programs: {},
  appliedOpportunities: [],
  savedOpportunities: [],
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const volunteersReducer = handleActions(
  {
    [fetchPrograms.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchPrograms.loading = true;
        draft.requests.fetchPrograms.error = null;
      }),
    [fetchPrograms.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchPrograms.loading = false;
        draft.programs = _keyBy(payload, 'id');
      }),
    [fetchPrograms.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchPrograms.loading = false;
        draft.requests.fetchPrograms.error = payload;
      }),

    [fetchOpportunities.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchOpportunities.loading = true;
        draft.requests.fetchOpportunities.error = null;
      }),
    [fetchOpportunities.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchOpportunities.loading = false;
        draft.appliedOpportunities = _keyBy(payload.appliedOpportunities, 'id');
        draft.savedOpportunities = _keyBy(payload.savedOpportunities, 'id');
      }),
    [fetchOpportunities.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchOpportunities.loading = false;
        draft.requests.fetchOpportunities.error = payload;
      }),

    [applyOpportunity.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.applyOpportunity.loading = true;
        draft.requests.applyOpportunity.error = null;
      }),
    [applyOpportunity.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyOpportunity.loading = false;
        draft.appliedOpportunities = _keyBy(payload, 'id');
      }),
    [applyOpportunity.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyOpportunity.loading = false;
        draft.requests.applyOpportunity.error = payload;
      }),

    [saveOpportunity.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.saveOpportunity.loading = true;
        draft.requests.saveOpportunity.error = null;
      }),
    [saveOpportunity.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.saveOpportunity.loading = false;
      }),
    [saveOpportunity.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.saveOpportunity.loading = false;
        draft.requests.saveOpportunity.error = payload;
      }),

    [unsaveOpportunity.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.unsaveOpportunity.loading = true;
        draft.requests.unsaveOpportunity.error = null;
      }),
    [unsaveOpportunity.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.unsaveOpportunity.loading = false;
      }),
    [unsaveOpportunity.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.unsaveOpportunity.loading = false;
        draft.requests.unsaveOpportunity.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default volunteersReducer;
