import * as types from './types';

export const fetchMembershipProducts = () => ({
  type: types.FETCH_MEMEBERSHIP_PRODUCTS,
});

export const fetchMembershipProductsSuccess = membershipProducts => ({
  type: types.FETCH_MEMEBERSHIP_PRODUCTS_SUCCESS,
  payload: membershipProducts,
});

export const fetchMembershipProductsFailure = error => ({
  type: types.FETCH_MEMEBERSHIP_PRODUCTS_FAILURE,
  payload: error,
});

export const createMembershipRequest = productId => ({
  type: types.CREATE_MEMEBERSHIP_REQUEST,
  payload: productId,
});

export const createMembershipRequestSuccess = membershipRequest => ({
  type: types.CREATE_MEMEBERSHIP_REQUEST_SUCCESS,
  payload: membershipRequest,
});

export const createMembershipRequestFailure = error => ({
  type: types.CREATE_MEMEBERSHIP_REQUEST_FAILURE,
  payload: error,
});

export const fetchMembershipRequest = () => ({
  type: types.FETCH_MEMEBERSHIP_REQUEST,
});

export const fetchMembershipRequestSuccess = membershipRequest => ({
  type: types.FETCH_MEMEBERSHIP_REQUEST_SUCCESS,
  payload: membershipRequest,
});

export const fetchMembershipRequestFailure = error => ({
  type: types.FETCH_MEMEBERSHIP_REQUEST_FAILURE,
  payload: error,
});

export const fetchMembershipRules = () => ({
  type: types.FETCH_MEMEBERSHIP_RULES,
});

export const fetchMembershipRulesSuccess = rules => ({
  type: types.FETCH_MEMEBERSHIP_RULES_SUCCESS,
  payload: rules,
});

export const fetchMembershipRulesFailure = error => ({
  type: types.FETCH_MEMEBERSHIP_RULES_FAILURE,
  payload: error,
});
