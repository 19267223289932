import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';
import { QUERY_KEY } from './usePeopleOfferSearch';

const usePeopleOfferSearchMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const addToFavorites = useMutation(id => api.job.addToFavorites(id), {
    onSuccess: invalidateQuery,
  });

  const removeFromFavorites = useMutation(id => api.job.removeFromFavorites(id), {
    onSuccess: invalidateQuery,
  });

  return {
    addToFavorites,
    removeFromFavorites,
  };
};

export default usePeopleOfferSearchMutations;
