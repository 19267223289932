import { takeLatest, put, all, call, take } from 'redux-saga/effects';

import {
  FETCH_MEMEBERSHIP_PRODUCTS,
  CREATE_MEMEBERSHIP_REQUEST,
  FETCH_MEMEBERSHIP_REQUEST,
  FETCH_MEMEBERSHIP_RULES,
} from '../actions/memberships/types';
import {
  fetchMembershipProductsSuccess,
  fetchMembershipProductsFailure,
  createMembershipRequestSuccess,
  createMembershipRequestFailure,
  fetchMembershipRequestSuccess,
  fetchMembershipRequestFailure,
  fetchMembershipRulesSuccess,
  fetchMembershipRulesFailure,
} from '../actions/memberships';

import { fetchUserInfo } from '../reducers/AuthReducer';

import odoo from '../../backend';

function* fetchMembershipProductsSaga() {
  try {
    const membershipProducts = yield call([odoo, odoo.fetchMembershipProducts]);
    yield put(fetchMembershipProductsSuccess(membershipProducts.results));
  } catch (error) {
    yield put(fetchMembershipProductsFailure(error));
  }
}

function* fetchMembershipRulesSaga() {
  try {
    const membershipRules = yield call([odoo, odoo.fetchMembershipRules]);
    yield put(fetchMembershipRulesSuccess(membershipRules.results));
  } catch (error) {
    yield put(fetchMembershipRulesFailure(error));
  }
}

function* createMembershipRequestSaga(action) {
  const data = {
    product_id: action.payload,
  };

  try {
    const request = yield call([odoo, odoo.createMembershipRequest], data);
    yield put(fetchUserInfo());
    yield take(fetchUserInfo.success().type);
    yield put(createMembershipRequestSuccess(request));
  } catch (error) {
    yield put(createMembershipRequestFailure(error));
  }
}

function* fetchMembershipRequestSaga() {
  try {
    const request = yield call([odoo, odoo.fetchMembershipRequest]);
    yield put(fetchMembershipRequestSuccess(request));
  } catch (error) {
    yield put(fetchMembershipRequestFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([FETCH_MEMEBERSHIP_PRODUCTS], fetchMembershipProductsSaga),
    yield takeLatest([FETCH_MEMEBERSHIP_RULES], fetchMembershipRulesSaga),
    yield takeLatest([CREATE_MEMEBERSHIP_REQUEST], createMembershipRequestSaga),
    yield takeLatest([FETCH_MEMEBERSHIP_REQUEST], fetchMembershipRequestSaga),
  ]);
}
