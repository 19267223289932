import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import ActivityListItem from './ActivityListItem';

const ActivityList = ({ activities }) => {
  return (
    activities && (
      <Box>
        {activities.map(a => (
          <ActivityListItem activity={a} key={`act_${a.id}`} />
        ))}
      </Box>
    )
  );
};

ActivityList.propTypes = {
  activities: PropTypes.array,
};

export default React.memo(ActivityList);
