import React, { useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import * as routes from '../../../navigation/routes';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../../store/hooks/alerts';

import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import ActivitiesIcon from '@material-ui/icons/Assignment';
import ArchiveIcon from '@material-ui/icons/Archive';
import CertificationsIcon from '@material-ui/icons/School';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MembershipsIcon from '@material-ui/icons/CardMembership';
import OffersIcon from '@material-ui/icons/Flag';
import RulesIcon from '@material-ui/icons/SmsFailed';
import VouchersIcon from '@material-ui/icons/LocalOffer';
import OpportunitiesIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonIcon from '@material-ui/icons/Person';
import ProgramsIcon from '@material-ui/icons/Layers';
import FolderIcon from '@material-ui/icons/Folder';
import BenefitsIcon from '@material-ui/icons/CardGiftcard';
import ApartmentIcon from '@material-ui/icons/Apartment';

import NavList from './NavList';
import NavButton from './NavButton';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/selectors';
import { Person } from '@material-ui/icons';

export const getCategories = ({ t, user }) => [
  {
    label: t('Profile'),
    pages: [
      {
        label: t('Certifications'),
        icon: <CertificationsIcon />,
        to: routes.CERTIFICATIONS_LIST,
      },
      {
        label: t('PDU'),
        icon: <ActivitiesIcon />,
        to: routes.ACTIVITIES_LIST,
      },
      {
        label: t('Public profile'),
        icon: <PersonIcon />,
        to: routes.PERSONAL_PAGE.replace(':code', user?.lpiid),
        external: true,
      },
    ],
  },
  {
    label: t('Site Admin'),
    renderCondition: !!user?.is_site_admin,
    pages: [
      {
        label: t('Locations'),
        icon: <ApartmentIcon />,
        to: routes.LOCATIONS,
      },
    ],
  },
  {
    label: t('Trainer'),
    renderCondition: !!user?.is_approved_trainer,
    pages: [
      {
        label: t('Trainer profile'),
        icon: <Person />,
        to: routes.TRAINER_INFO,
      },
    ],
  },
  {
    label: t('Membership'),
    pages: [
      {
        label: t('Plans'),
        icon: <MembershipsIcon />,
        to: routes.MEMBERSHIP_LIST,
      },
      {
        label: t('Rules'),
        icon: <RulesIcon />,
        to: routes.MEMBERSHIP_RULES,
      },
    ],
  },
  {
    label: t('Shop'),
    pages: [
      {
        label: t('Vouchers'),
        icon: <VouchersIcon />,
        to: routes.VOUCHERS,
      },
      {
        label: t('Coupons'),
        icon: <VouchersIcon />,
        to: routes.COUPONS,
      },
      {
        label: t('Benefits'),
        icon: <BenefitsIcon />,
        to: routes.BENEFITS,
      },
    ],
  },
  {
    label: t('Volunteer'),
    pages: [
      {
        label: t('Programs'),
        icon: <ProgramsIcon />,
        to: routes.VOLUNTEER_PROGRAMS_LIST,
      },
      {
        label: t('Opportunities'),
        icon: <OpportunitiesIcon />,
        to: routes.VOLUNTEER_OPPORTUNITIES_LIST,
      },
      {
        label: t('Applications'),
        icon: <EventAvailableIcon />,
        to: routes.VOLUNTEER_APPLICATION_LIST,
      },
    ],
  },
  {
    label: t('Jobs'),
    beta: true,
    pages: [
      {
        label: t('Offers'),
        icon: alerts =>
          alerts ? (
            <Grid item>
              <Badge color="secondary" badgeContent={Object.keys(alerts).length}>
                <OffersIcon />
              </Badge>
            </Grid>
          ) : (
            <OffersIcon />
          ),
        to: routes.JOBS_OFFERS,
      },
      {
        label: t('Applications'),
        icon: <ArchiveIcon />,
        to: routes.JOBS_APPLIED,
      },
    ],
  },
];

const Menu = () => {
  const location = useLocation();
  const user = useSelector(getUser);
  const { t } = useTranslation();
  console.log(user);
  const categories = React.useMemo(() => getCategories({ t, user }), [t]);

  const { alerts } = useAlerts();

  const activeCategory = categories.find(category =>
    category.pages.some(page =>
      matchPath(location.pathname, {
        path: page.to,
        exact: false,
        strict: false,
      }),
    ),
  );

  const [openList, setOpenList] = useState(activeCategory?.label);

  return (
    <>
      <NavButton label={t('Dashboard')} icon={<DashboardIcon />} to={routes.HOME} testid="Dashboard" />
      <NavButton label={t('Documents')} icon={<FolderIcon />} to={routes.ALL_DOCUMENTS} testid="Documents" />

      {categories.map(({ label, pages, renderCondition }, index) => {
        if (typeof renderCondition === 'undefined' || !!renderCondition)
          return (
            <NavList key={index} label={label} open={openList} setOpen={setOpenList} testid={label.replace(' ', '-')}>
              {pages.map(page => (
                <NavButton
                  key={page.label}
                  label={page.label}
                  icon={typeof page.icon === 'function' ? page.icon(alerts) : page.icon}
                  to={page.to}
                  beta={page.beta}
                  testid={`${label.replace(' ', '-')}-${page.label.replace(' ', '-')}`}
                />
              ))}
            </NavList>
          );
        else return null;
      })}
    </>
  );
};

export default React.memo(Menu);
