import * as types from '../actions/app/types';

const INITIAL_STATE = {
  current: null,
  show: false,
  queue: [],
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APP_ADD_NOTIFICATION: {
      return {
        ...state,
        current: !state.current ? action.payload : state.current,
        show: !state.current ? true : state.show,
        queue: [...state.queue, action.payload],
      };
    }
    case types.APP_HIDE_NOTIFICATION: {
      return {
        ...state,
        show: false,
      };
    }
    case types.APP_REMOVE_NOTIFICATION: {
      state.queue.shift();
      let next = state.queue.length > 0 ? state.queue[0] : null;

      return {
        ...state,
        current: next,
        show: Boolean(next),
        queue: [...state.queue],
      };
    }
    default:
      return state;
  }
};

export default notificationsReducer;
