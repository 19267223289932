import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, fetchVoucherProducts, fetchCheckoutConfig, openCart, closeCart } from '../reducers/ShopReducer';
import { selectCart, getVoucherProducts, selectCheckoutConfig, getCartIsOpen } from '../selectors/shop';

export const useCheckoutConfig = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectCheckoutConfig);

  React.useEffect(() => {
    dispatch(fetchCheckoutConfig());
  }, [dispatch]);

  return config;
};

export const useCart = orderId => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);

  React.useEffect(() => {
    console.log('fetch', orderId);
    if (orderId) {
      dispatch(fetchCart({ order_id: orderId }));
    } else {
      dispatch(fetchCart());
    }
  }, [dispatch, orderId]);

  return cart;
};

export const useVoucherProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getVoucherProducts);

  React.useEffect(() => {
    dispatch(fetchVoucherProducts());
  }, [dispatch]);

  return products;
};

export const useCartButton = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getCartIsOpen);

  const handleOpenCart = () => dispatch(openCart());
  const handleCloseCart = () => dispatch(closeCart());

  return { isOpen, openCart: handleOpenCart, closeCart: handleCloseCart };
};
