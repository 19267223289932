import { Grid, Typography, makeStyles, Chip, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Placeholder from '../../assets/images/file-placeholder.png';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fcf7e2',
    },
    overflow: 'hidden',
  },
  thumbnail: {
    width: 'auto',
    height: '100%',
    maxHeight: 80,
  },
  tags: {
    height: 20,
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: theme.palette.primary.main,
      height: 20,
    },
  },
}));

const Document = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState(file.thumbnail);

  return (
    <Grid item container xs={12} md={6}>
      <Grid
        item
        container
        spacing={2}
        wrap="nowrap"
        onClick={() => window.open(file.url_link)}
        className={classes.link}
      >
        <Grid item>
          <img className={classes.thumbnail} src={imageSrc} onError={() => setImageSrc(Placeholder)} />
        </Grid>
        <Grid item container direction="column" wrap="nowrap" zeroMinWidth>
          <Grid item zeroMinWidth>
            <Typography noWrap>{file.name}</Typography>
            <Typography noWrap variant="caption">
              {t('Creation date')}: {new Date(file.create_date).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item container className={classes.tags}>
            {file.tags.map(tag => (
              <Chip key={tag} label={tag} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Document;
