import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CHECKOUT_ORDER } from '../../../navigation/routes';

import useMembershipMutations from '../../../hooks/Membership/useMembershipMutations';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PageTitle from '../../App/PageTitle';
import ProductList from '../ProductList';
import { fetchUserInfo } from '../../../store/reducers/AuthReducer';

export default function RenewStep({ renewal_order, renewing }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const membership = useSelector(({ memberships }) => memberships);

  const { renew } = useMembershipMutations();

  const handleSubmit = useCallback(
    productId => {
      renew.mutate(productId, {
        onSuccess: data => {
          dispatch(fetchUserInfo());
          history.push(CHECKOUT_ORDER.replace(':orderId', data.order_id));
        },
      });
    },
    [dispatch],
  );

  return (
    <Box>
      {renewing ? (
        <Box>
          <PageTitle title={t('Your memebership is renewing!')} />
          <Typography variant="body1">{t('Your membership will be active again in some minutes.')}</Typography>
        </Box>
      ) : (
        <Box>
          <PageTitle title={t('Your memebership has expired!')} />

          {renewal_order ? (
            <Box>
              <Typography variant="body1">
                {t('You can now proceed to checkout phase to renew your LPI Membership period.')}
              </Typography>
              <br />
              <Button
                component={Link}
                to={CHECKOUT_ORDER.replace(':orderId', renewal_order)}
                variant="contained"
                color="primary"
              >
                {t('PAY MEMBERSHIP DUES')}
              </Button>
            </Box>
          ) : (
            !renew.isSuccess && (
              <ProductList
                products={membership.products}
                submitting={membership.submitting}
                onSubmit={handleSubmit}
                renew
                disabled={renew.isLoading}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
}
