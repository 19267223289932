import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfo } from '../reducers/PersonalPageReducer';
import { getPersonalPageInfo } from '../selectors';

const usePersonalPageInfo = code => {
  const dispatch = useDispatch();
  const info = useSelector(getPersonalPageInfo);

  React.useEffect(() => {
    dispatch(fetchInfo(code));
  }, [dispatch, code]);

  return { info };
};

export { usePersonalPageInfo };
