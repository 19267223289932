import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';
import { QUERY_KEY } from './useApplications';

const useApplicationsMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const apply = useMutation(({ offerId, payload }) => api.applications.create({ job_id: offerId, ...payload }), {
    onSuccess: invalidateQuery,
  });

  // const revoke = useMutation(({ offerId, payload }) => api.job.apply({ job_id: offerId, ...payload }), {
  //   onSuccess: invalidateQuery,
  // });

  return {
    apply,
  };
};

export default useApplicationsMutations;
