import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusChip from '../Common/StatusChip';

import Table from '../Common/Table';
import Column from '../Common/TableColumn';
import CopyToClipboard from '../Common/CopyToClipboard';
import Link from '@material-ui/core/Link';

const CouponsTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column
        label={t('Status')}
        field="state"
        render={value => {
          return <StatusChip status={value.toUpperCase()} />;
        }}
      />
      <Column label={t('Discount')} field="program_name" />
      <Column
        label={t('Code')}
        field="code"
        render={value => (
          <CopyToClipboard
            title={t('Copied to clipboard!')}
            leaveDelay={1500}
            render={copy => (
              <Link
                href="#"
                color="secondary"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  copy(value);
                }}
              >
                {value}
              </Link>
            )}
          />
        )}
      />
      <Column label={t('Partner')} field="lpi_partner_name" />
      <Column label={t('Expiring Date')} field="expiration_date" />
    </Table>
  );
};

export default React.memo(CouponsTable);
