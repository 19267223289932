import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend';

const QUERY_KEY = 'document.tags';

/** Get all available document tags  */
const useDocumentTags = () => {
  const req = useQuery(QUERY_KEY, api.documents.tags);

  /* Log errors */
  useEffect(() => {
    if (req.error) console.error(req.error);
  }, [req.error]);

  return {
    tags: req.data?.results || [],
    ...req,
  };
};

export default useDocumentTags;
