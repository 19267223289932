import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const OFFERS_FETCH = 'Jobs.FetchOffers';
export const OFFERS_SEARCH = 'Jobs.SearchOffers';
export const FAVOURITES_FETCH = 'Jobs.FetchFavourites';
export const FAVOURITES_ADD = 'Jobs.AddFavourites';
export const FAVOURITES_REMOVE = 'Jobs.RemoveFavourites';
export const APPLICATIONS_FETCH = 'Jobs.FetchApplications';
export const APPLY_JOB = 'Jobs.ApplyJob';
export const SEARCHES_FETCH = 'Jobs.FetchSearches';
export const SEARCHES_ADD = 'Jobs.AddSearches';
export const SEARCHES_UPDATE = 'Jobs.UpdateSearches';
export const SEARCHES_REMOVE = 'Jobs.RemoveSearches';
export const ALERTS_FETCH = 'Jobs.FetchAlerts';

export const fetchOffers = createRoutine(OFFERS_FETCH);
export const searchOffers = createRoutine(OFFERS_SEARCH);
export const fetchFavourites = createRoutine(FAVOURITES_FETCH);
export const addFavourite = createRoutine(FAVOURITES_ADD);
export const removeFavourite = createRoutine(FAVOURITES_REMOVE);
export const fetchApplications = createRoutine(APPLICATIONS_FETCH);
export const applyJob = createRoutine(APPLY_JOB);
export const fetchSearches = createRoutine(SEARCHES_FETCH);
export const addSearch = createRoutine(SEARCHES_ADD);
export const updateSearch = createRoutine(SEARCHES_UPDATE);
export const removeSearch = createRoutine(SEARCHES_REMOVE);
export const fetchAlerts = createRoutine(ALERTS_FETCH);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetchOffers: { loading: false, error: null },
  searchOffers: { loading: false, error: null },
  fetchFavourites: { loading: false, error: null },
  addFavourite: { loading: false, error: null },
  removeFavourite: { loading: false, error: null },
  fetchApplications: { loading: false, error: null },
  applyJob: { loading: false, error: null },
  fetchSearches: { loading: false, error: null },
  addSearch: { loading: false, error: null },
  updateSearch: { loading: false, error: null },
  removeSearch: { loading: false, error: null },
  fetchAlerts: { loading: false, error: null },
};

const INITIAL_STATE = {
  offers: [],
  favourites: [],
  applications: [],
  searches: [],
  alerts: [],
  filtered: [],
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const jobsReducer = handleActions(
  {
    [fetchOffers.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchOffers.loading = true;
        draft.requests.fetchOffers.error = null;
      }),
    [fetchOffers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchOffers.loading = false;
        draft.offers = _keyBy(payload, 'id');
      }),
    [fetchOffers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchOffers.loading = false;
        draft.requests.fetchOffers.error = payload;
      }),

    [searchOffers.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.searchOffers.loading = true;
        draft.requests.searchOffers.error = null;
      }),
    [searchOffers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.searchOffers.loading = false;
        draft.filtered = _keyBy(payload, 'id');
      }),
    [searchOffers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.searchOffers.loading = false;
        draft.requests.searchOffers.error = payload;
      }),

    [fetchFavourites.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchFavourites.loading = true;
        draft.requests.fetchFavourites.error = null;
      }),
    [fetchFavourites.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchFavourites.loading = false;
        draft.favourites = payload;
      }),
    [fetchFavourites.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchFavourites.loading = false;
        draft.requests.fetchFavourites.error = payload;
      }),

    [addFavourite.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.addFavourite.loading = true;
        draft.requests.addFavourite.error = null;
      }),
    [addFavourite.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addFavourite.loading = false;
        draft.favourites = payload.jobs;
      }),
    [addFavourite.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addFavourite.loading = false;
        draft.requests.addFavourite.error = payload;
      }),

    [removeFavourite.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.removeFavourite.loading = true;
        draft.requests.removeFavourite.error = null;
      }),
    [removeFavourite.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeFavourite.loading = false;
        draft.favourites = payload.jobs;
      }),
    [removeFavourite.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeFavourite.loading = false;
        draft.requests.removeFavourite.error = payload;
      }),

    [fetchApplications.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchApplications.loading = true;
        draft.requests.fetchApplications.error = null;
      }),
    [fetchApplications.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchApplications.loading = false;
        draft.applications = _keyBy(payload, 'job_id');
      }),
    [fetchApplications.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchApplications.loading = false;
        draft.requests.fetchApplications.error = payload;
      }),
    [applyJob.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.applyJob.loading = true;
        draft.requests.applyJob.error = null;
      }),
    [applyJob.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyJob.loading = false;
        draft.applications[payload.job_id] = payload;
      }),
    [applyJob.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.applyJob.loading = false;
        draft.requests.applyJob.error = payload;
      }),

    // SEARCHES

    [fetchSearches.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchSearches.loading = true;
        draft.requests.fetchSearches.error = null;
      }),
    [fetchSearches.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchSearches.loading = false;
        draft.searches = _keyBy(payload, 'id');
      }),
    [fetchSearches.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchSearches.loading = false;
        draft.requests.fetchSearches.error = payload;
      }),

    [addSearch.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.addSearch.loading = true;
        draft.requests.addSearch.error = null;
      }),
    [addSearch.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addSearch.loading = false;
        draft.searches[payload.id] = payload;
      }),
    [addSearch.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.addSearch.loading = false;
        draft.requests.addSearch.error = payload;
      }),

    [updateSearch.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.updateSearch.loading = true;
        draft.requests.updateSearch.error = null;
      }),
    [updateSearch.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateSearch.loading = false;
        draft.searches[payload.id] = payload;
      }),
    [updateSearch.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateSearch.loading = false;
        draft.requests.updateSearch.error = payload;
      }),

    [removeSearch.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.removeSearch.loading = true;
        draft.requests.removeSearch.error = null;
      }),
    [removeSearch.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeSearch.loading = false;
        delete draft.searches[payload];
      }),
    [removeSearch.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.removeSearch.loading = false;
        draft.requests.removeSearch.error = payload;
      }),

    [fetchAlerts.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetchAlerts.loading = true;
        draft.requests.fetchAlerts.error = null;
      }),
    [fetchAlerts.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchAlerts.loading = false;
        draft.alerts = payload;
      }),
    [fetchAlerts.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchAlerts.loading = false;
        draft.requests.fetchAlerts.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default jobsReducer;
