import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const LABEL_COLORS = {
  success: green[500],
  danger: red[500],
  info: grey[500],
  warning: yellow[500],
};

const TEXT_SIZES = {
  small: 10,
  normal: 12,
  big: 16,
};

const PADDING_SIZES = theme => ({
  small: {
    paddingTop: theme.spacing() / 2,
    paddingBottom: theme.spacing() / 2,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  normal: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing() * 2,
    paddingRight: theme.spacing() * 2,
  },
  big: {
    paddingTop: theme.spacing() * 1.5,
    paddingBottom: theme.spacing() * 1.5,
    paddingLeft: theme.spacing() * 2.5,
    paddingRight: theme.spacing() * 2.5,
  },
});

const Label = ({ children, type = 'success', size = 'normal' }) => {
  const classes = useStyles(type, size);

  return <div className={classes.container}>{children}</div>;
};

const useStyles = (type, size) =>
  makeStyles(theme => {
    return {
      container: {
        textAlign: 'center',
        textTransform: 'uppercase',
        backgroundColor: LABEL_COLORS[type],
        color: '#fff',
        fontSize: TEXT_SIZES[size],
        ...PADDING_SIZES(theme)[size],
      },
    };
  })();

export default React.memo(Label);
