import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCertificationGroups } from '../../store/selectors';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import ActivityTableRow from './ActivityTableRow';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';

const ActivityTable = ({ activities, pagination }) => {
  const { t } = useTranslation();
  const groups = useSelector(getCertificationGroups);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = useCallback(
    (event, page) => {
      setPage(page);
    },
    [setPage],
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      setRowsPerPage(event.target.value);
    },
    [setRowsPerPage],
  );

  return (
    <>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Title')}</TableCell>
            <TableCell>{t('Status')}</TableCell>
            <Hidden smDown>
              <TableCell>{t('Date')}</TableCell>
              <TableCell>{t('Category')}</TableCell>
            </Hidden>
            <TableCell align="right">{t('PDU')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(activity => (
            <ActivityTableRow key={activity.id} activity={activity} groups={groups} />
          ))}
        </TableBody>
      </Table>
      {pagination ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={activities.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
};

ActivityTable.defaultProps = {
  pagination: true,
};

export default React.memo(ActivityTable);
