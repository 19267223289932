import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingActivities, getAcceptedActivities, getUser } from '../../store/selectors';
import { loadDashboardData } from '../../store/actions/auth';
import { init } from '../../store/reducers/AuthReducer';

import Layout from '../../components/App/Layout';
import { Link } from 'react-router-dom';
import * as routes from '../../navigation/routes';

import Stats from '../../components/Dashboard/Stats';
import Steps from '../../components/Dashboard/Steps';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ActivityList from '../../components/Activities/ActivityList';
import RecommendedActions from '../../components/Dashboard/RecommendedActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const DashboadPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const acceptedActivities = useSelector(getAcceptedActivities);
  const pendingActivities = useSelector(getPendingActivities);
  console.log('dashboadPage');
  React.useEffect(() => {
    console.log('reload dashboadPage');

    dispatch(loadDashboardData());
    dispatch(init());
  }, [dispatch]);

  return (
    <Layout title="Dashboard">
      <Box mt={4} px={2}>
        <Box marginTop={1}>
          <Stats />
        </Box>

        <Steps />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4}>
            <Box fontSize={24} marginBottom={2}>
              {t('Recommended Actions')}
            </Box>
            <RecommendedActions />
          </Grid>
          {pendingActivities.length === 0 && acceptedActivities.length === 0 ? (
            <Grid item xs={12} sm={12} lg={8}>
              {user.can_submit_pdus ? (
                <Box marginTop={2} display="flex" flexDirection="column" alignItems="center">
                  <Box marginBottom={1}>
                    <Typography variant="h4">{t('You have no PDUs!')}</Typography>
                  </Box>

                  <Box marginBottom={1}>
                    <Typography variant="body1">
                      {t("Claim PDUs for your current cycle! Don't wait for the last minute.")}
                    </Typography>
                  </Box>

                  <Button variant="contained" color="primary" component={Link} to={routes.ACTIVITIES_CREATE}>
                    {t('Add PDU now')}
                  </Button>
                </Box>
              ) : (
                <Box marginTop={2} display="flex" flexDirection="column" alignItems="center">
                  <Box marginBottom={1}>
                    <Typography variant="h4">{t('Get Certified!')}</Typography>
                  </Box>

                  <Box marginBottom={1}>
                    <Typography variant="body1">{t('Get certified to access our membership program.')}</Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          ) : (
            <>
              {pendingActivities.length !== 0 && (
                <Grid item xs={12} sm={6} lg={4}>
                  <Box fontSize={24} marginBottom={2}>
                    {t('PDUs Under Review')}
                  </Box>
                  <ActivityList activities={pendingActivities.slice(0, 5)} />
                </Grid>
              )}

              {acceptedActivities.length !== 0 && (
                <Grid item xs={12} sm={6} lg={4}>
                  <Box fontSize={24} marginBottom={2}>
                    {t('Accepted PDUs')}
                  </Box>
                  <ActivityList activities={acceptedActivities.slice(0, 5)} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Layout>
  );
};

export default React.memo(DashboadPage);
