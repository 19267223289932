import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/App/PrivateRoute';
import ScrollToTop from '../components/App/ScrollToTop';
import * as routes from '../navigation/routes';
import ActivitiesPage from '../routes/Activities/ActivitiesPage';
import ActivitiesSummaryPage from '../routes/Activities/ActivitiesSummaryPage';
import ActivityDetailPage from '../routes/Activities/ActivityDetailPage';
import AddActivityPage from '../routes/Activities/AddActivityPage';
import EditAcvitityPage from '../routes/Activities/EditAcvitityPage';
import BenefitsPage from '../routes/Benefits/BenefitsPage';
import CertificationPage from '../routes/Certifications/CertificationPage';
import CouponClaimPage from '../routes/Coupons/CouponClaimPage';
import CouponReferralPage from '../routes/Coupons/CouponReferralPage';
import DocumentsPage from '../routes/Documents/DocumentsPage';
import ArchivePage from '../routes/Jobs/ArchivePage';
import OffersPage from '../routes/Jobs/OffersPage';
import LocationsPage from '../routes/SiteAdmin/LocationsPage';
import MembershipsPage from '../routes/Memberships/MembershipsPage';
import RulesPage from '../routes/Memberships/RulesPage';
import ScorePage from '../routes/Memberships/ScorePage';
import NotFoundPage from '../routes/NotFoundPage';
import InvitePage from '../routes/Partnership/InvitePage';
import CartPage from '../routes/Shop/CartPage';
import CheckoutPage from '../routes/Shop/CheckoutPage';
import VoucherProductListPage from '../routes/Shop/Voucher/VoucherProductListPage';
import VoucherProductPage from '../routes/Shop/Voucher/VoucherProductPage';
import SupportPage from '../routes/SupportPage';
import CodeOfConductPage from '../routes/Users/CodeOfConductPage';
import DashboardPage from '../routes/Users/DashboardPage';
import LoginPage from '../routes/Users/LoginPage';
import PersonalPage from '../routes/Users/PersonalPage';
import ProfilePage from '../routes/Users/ProfilePage';
import OpportunitiesPage from '../routes/Volunteers/OpportunitiesPage';
import ProgramsPage from '../routes/Volunteers/ProgramsPage';
import CouponsPage from '../routes/Vouchers/CouponsPage';
import VouchersPage from '../routes/Vouchers/VouchersPage';
import ApproveLocationPage from '../routes/SiteAdmin/ApproveLocationPage';
import NotificationsPage from '../routes/NotificationsPage';
import TrainerInfoPage from '../routes/Trainer/TrainerInfoPage';
import ApplicationsPage from '../routes/Volunteers/ApplicationsPage';
import TicketsPage from '../routes/Support/TicketsPage';
import TicketDetailPage from '../routes/Support/TicketDetailPage';
import TicketCreatePage from '../routes/Support/TicketCreatePage';

const AppRouter = () => {
  return (
    <ScrollToTop>
      <Switch>
        <PrivateRoute exact path={routes.HOME} component={DashboardPage} />
        <PrivateRoute exact path={routes.CODE_OF_CONDUCT} component={CodeOfConductPage} />
        <PrivateRoute exact path={routes.ACTIVITIES_SUMMARY} component={ActivitiesSummaryPage} />
        <PrivateRoute exact path={routes.ACTIVITIES_LIST} component={ActivitiesPage} />
        <PrivateRoute exact path={routes.ACTIVITIES_CREATE} component={AddActivityPage} />
        <PrivateRoute exact path={routes.ACTIVITIES_EDIT()} component={EditAcvitityPage} />
        <PrivateRoute exact path={routes.ACTIVITIES_DETAIL()} component={ActivityDetailPage} />
        <PrivateRoute exact path={routes.MEMBERSHIP_SCORE} component={ScorePage} />
        <PrivateRoute exact path={routes.MEMBERSHIP_LIST} component={MembershipsPage} />
        <PrivateRoute exact path={routes.MEMBERSHIP_RULES} component={RulesPage} />
        <PrivateRoute exact path={routes.CERTIFICATIONS_LIST} component={CertificationPage} />
        <PrivateRoute exact path={routes.VOLUNTEER_PROGRAMS_LIST} component={ProgramsPage} />
        <PrivateRoute exact path={routes.VOLUNTEER_PROGRAMS_DETAIL_ITEM} component={ProgramsPage} />
        <PrivateRoute exact path={routes.VOLUNTEER_PROGRAMS_DETAIL} component={ProgramsPage} />
        <PrivateRoute exact path={routes.VOLUNTEER_OPPORTUNITIES_LIST} component={OpportunitiesPage} />
        <PrivateRoute exact path={routes.VOLUNTEER_APPLICATION_LIST} component={ApplicationsPage} />
        <PrivateRoute path={routes.VOLUNTEER_APPLICATION_DETAIL} component={ApplicationsPage} />
        <PrivateRoute path={routes.VOLUNTEER_OPPORTUNITIES_DETAIL} component={OpportunitiesPage} />
        <PrivateRoute exact path={routes.JOBS_OFFERS} component={OffersPage} />
        <PrivateRoute path={routes.JOBS_OFFERS_DETAIL} component={OffersPage} />
        <PrivateRoute exact path={routes.JOBS_APPLIED} component={ArchivePage} />
        <PrivateRoute path={routes.JOBS_APPLIED_DETAIL} component={ArchivePage} />
        <PrivateRoute path={routes.VOUCHERS_DETAIL} component={VouchersPage} />
        <PrivateRoute exact path={routes.VOUCHERS} component={VouchersPage} />
        <PrivateRoute path={routes.COUPON_CLAIM} component={CouponClaimPage} />
        <PrivateRoute path={routes.COUPON_REFERRAL} component={CouponReferralPage} />
        <PrivateRoute path={routes.COUPONS_DETAIL} component={CouponsPage} />
        <PrivateRoute exact path={routes.COUPONS} component={CouponsPage} />
        <PrivateRoute path={routes.BENEFITS_DETAIL} component={BenefitsPage} />
        <PrivateRoute exact path={routes.BENEFITS} component={BenefitsPage} />
        <PrivateRoute path={routes.PROFILE_PAGE} component={ProfilePage} />
        <PrivateRoute path={routes.LOCATIONS} component={LocationsPage} />
        <PrivateRoute path={routes.LOCATION_APPROVE} component={ApproveLocationPage} />
        <Route path={routes.PARTNER_INVITE} component={InvitePage} />
        <Route path={routes.LOGIN} component={LoginPage} />
        <Route path={routes.PERSONAL_PAGE} component={PersonalPage} />
        <PrivateRoute path={routes.CART} component={CartPage} />
        <PrivateRoute path={routes.CHECKOUT_ORDER} component={CheckoutPage} />
        <PrivateRoute path={routes.CHECKOUT} component={CheckoutPage} />
        <PrivateRoute path={routes.VOUCHER_PRODUCT_DETAILS} component={VoucherProductPage} />
        <PrivateRoute exact path={routes.VOUCHER_PRODUCT_LIST} component={VoucherProductListPage} />
        <PrivateRoute path={routes.ALL_DOCUMENTS} component={DocumentsPage} />
        <PrivateRoute path={routes.NOTIFICATION_HISTORY} component={NotificationsPage} />
        <PrivateRoute path={routes.TRAINER_INFO} component={TrainerInfoPage} />
        <PrivateRoute path={routes.HELPDESK_TICKET_CREATE} component={TicketCreatePage} />
        <PrivateRoute path={routes.HELPDESK_TICKET_DETAILS} component={TicketDetailPage} />
        <PrivateRoute path={routes.HELPDESK_TICKETS} component={TicketsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  );
};

export default React.memo(AppRouter);
