const parseError = err => {
  console.log(JSON.stringify(err, null, 2));
  if (err?.message && typeof err.message === 'string') {
    return err.message;
  }

  if (err?.response?.data?.message && typeof err.response.data.message === 'string') {
    return err.response.data.message;
  }

  if (err?.error && typeof err.error === 'string') {
    return err.error;
  }

  if (typeof err === 'string') {
    return err;
  }

  return 'An error occurred';
};

export default parseError;
