import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/selectors';
import { acceptCoc } from '../../../store/reducers/AuthReducer';
import ProductList from '../ProductList';

import PageTitle from '../../App/PageTitle';
import COCForm from '../COCForm';
import { Box, Typography } from '@material-ui/core';

const COCStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const membership = useSelector(({ memberships }) => memberships);

  const user = useSelector(getUser);
  const handleAcceptCOC = useCallback(() => dispatch(acceptCoc(user.id)), [dispatch, user]);

  return (
    <Box>
      <Box paddingBottom={4}>
        <PageTitle title={t('Thanks for considering membership in the Linux Professional Institute (LPI)')} />

        <Typography>
          {t('In order to become a member of LPI, you will need to accept the following Member Agreement')}
        </Typography>
      </Box>
      <Box paddingBottom={5}>
        <COCForm onAccept={handleAcceptCOC} />
      </Box>
      <Box paddingBottom={5}>
        <ProductList products={membership.products} disabled />
      </Box>
    </Box>
  );
};

export default React.memo(COCStep);
