export const FETCH_CERTIFICATIONS = 'Certifications.Fetch';
export const FETCH_CERTIFICATIONS_SUCCESS = 'Certifications.Fetch.Success';
export const FETCH_CERTIFICATIONS_FAILURE = 'Certifications.Fetch.Failure';

export const FETCH_SCOREBOARD = 'Scoreboard.Fetch';
export const FETCH_SCOREBOARD_SUCCESS = 'Scoreboard.Fetch.Success';
export const FETCH_SCOREBOARD_FAILURE = 'Scoreboard.Fetch.Failure';

export const FETCH_ACHIEVEMENTS = 'Achievements.Fetch';
export const FETCH_ACHIEVEMENTS_SUCCESS = 'Achievements.Fetch.Success';
export const FETCH_ACHIEVEMENTS_FAILURE = 'Achievements.Fetch.Failure';
