import React from 'react';
import _debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

const useFilters = transform => {
  const location = useLocation();
  const history = useHistory();

  let URLfilters = queryString.parse(location.search);
  if (transform) {
    URLfilters = transform(URLfilters);
  }

  const [currentFilters, setCurrentFilters] = React.useState(URLfilters);

  const setOne = React.useCallback(
    (field, value) => {
      let tempFilters = { ...currentFilters };
      if (value === '') {
        delete tempFilters[field];
      } else {
        tempFilters[field] = value;
      }
      setCurrentFilters({ ...tempFilters });
    },
    [currentFilters],
  );

  const clear = React.useCallback(() => setCurrentFilters({}), [setCurrentFilters]);

  const [wrapper, setWrapper] = React.useState({
    current: currentFilters,
    set: setCurrentFilters,
    setOne: setOne,
    clear: clear,
  });

  const debounced = React.useRef(_debounce(_current => history.push({ search: queryString.stringify(_current) }), 100));

  React.useEffect(() => {
    debounced.current(currentFilters);
  }, [currentFilters]);

  React.useEffect(() => {
    setWrapper({
      current: currentFilters,
      set: setCurrentFilters,
      setOne: setOne,
      clear: clear,
    });
  }, [currentFilters, setCurrentFilters, setOne, clear]);

  return wrapper;
};

export { useFilters };
