import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { formatDate } from '../../utils';

import { CERTIFICATION_LOGOS } from '../../consts';
import { withTranslation } from 'react-i18next';

class GroupListItem extends PureComponent {
  getActiveUntilDate = () => {
    const { scoreboard, certifications } = this.props;

    const lastExpiration = certifications.reduce(
      (acc, cur) => (!acc || cur.expire_date > acc ? cur.expire_date : acc),
      null,
    );

    return lastExpiration > scoreboard.cycle_end ? lastExpiration : scoreboard.cycle_end;
  };

  render() {
    const { t } = this.props;
    const { scoreboard, certifications, classes } = this.props;

    const trackStatus = certifications.reduce((acc, cur) => (cur.active ? cur.active : acc), false);

    if (!scoreboard.group_name) {
      return null;
    }

    return (
      <Card className={classes.container} style={!trackStatus ? { opacity: 0.5 } : {}}>
        <CardHeader
          title={scoreboard.group_name}
          titleTypographyProps={{ variant: 'body1' }}
          subheader={`PDU ${scoreboard.pdu}/${scoreboard.target}`}
        />
        <CardMedia className={classes.media} image={CERTIFICATION_LOGOS['LPIC-1']} />
        <CardContent>
          <Typography variant="subtitle1" color="textSecondary">
            {t('Certifications')}
          </Typography>

          {certifications.map(c => (
            <Typography variant="body1" key={c.name}>
              {c.name}
            </Typography>
          ))}

          <div className={classes.infos} style={{ marginTop: 24 }}>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Track Status')}
            </Typography>
            <Typography variant="body1">{trackStatus ? 'Active' : 'Inactive'}</Typography>
          </div>
          {trackStatus ? (
            <div className={classes.infos}>
              <Typography variant="subtitle1" color="textSecondary">
                {t('Active Until')}
              </Typography>
              <Typography variant="body1">{formatDate(this.getActiveUntilDate(), 'MMMM d, yyyy')}</Typography>
            </div>
          ) : null}
        </CardContent>
      </Card>
    );
  }
}

const useStyles = withStyles(() => ({
  container: {
    marginBottom: 20,
  },
  infoContainer: {
    display: 'flex',
    flex: 1,
  },
  media: {
    height: 160,
  },
  infos: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  categoriesContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
  },
  categoryItem: {
    marginBottom: 10,
  },
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default withTranslation()(useStyles(GroupListItem));
