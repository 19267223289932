import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  common: {
    color: '#ffffff',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
  },

  active: {
    backgroundColor: '#00B35F',
  },
  expired: {
    backgroundColor: '#00B35F',
  },
  suspended: {
    backgroundColor: '#CFD8DC',
  },
  closed: {
    backgroundColor: '#CFD8DC',
  },
}));

const StatusChip = ({ status }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const colorClass = status ? classes[status.toLowerCase()] : '';
  return <Chip className={`${classes['common']} ${colorClass}`} label={t(status.toUpperCase())} />;
};

export default React.memo(StatusChip);
