import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend';

export const QUERY_KEY = 'locations';

/**
 * Get all available locations
 */
const useLpiLocations = () => {
  const req = useQuery([QUERY_KEY], () => api.locations.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    locations: req.data?.results || [],
    ...req,
  };
};

export default useLpiLocations;
