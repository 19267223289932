import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  FETCH_USER_INFO,
  UPDATE_USER_INFO,
  ACCEPT_COC,
  LOAD_DASHBOARD,
} from '../actions/auth/types';
import {
  loginFailure,
  loginSuccess,
  logoutSuccess,
  fetchUserInfoSuccess,
  updateUserInfoSuccess,
  updateUserInfoFailure,
  acceptCodeOfConductSuccess,
  acceptCodeOfConductFailure,
} from '../actions/auth';

import { fetchCertifications, fetchAchievements, fetchScoreboard } from '../../store/actions/certifications';
import { fetchActivities } from '../../store/actions/activities';
import { fetchMembershipRequest } from '../../store/actions/memberships';

import odoo from '../../backend';

function* loginSaga(action) {
  const { lpiid, password } = action.payload;

  try {
    yield call([odoo, odoo.login], lpiid, password);
    const userInfo = yield call([odoo, odoo.fetchUserInfo]);
    yield put(loginSuccess(userInfo));
    yield put(push('/'));
  } catch (error) {
    yield put(loginFailure('Unable to login with provided credentials'));
  }
}

function* fetchUserInfoSaga() {
  try {
    const userInfo = yield call([odoo, odoo.fetchUserInfo]);
    yield put(fetchUserInfoSuccess(userInfo));
  } catch (error) {
    console.log(error);
  }
}

function* updateUserInfoSaga({ payload }) {
  try {
    const userInfo = yield call([odoo, odoo.updateUserInfo], payload);
    yield put(updateUserInfoSuccess(userInfo));
  } catch (error) {
    yield put(updateUserInfoFailure(error));
  }
}

function* acceptCodeOfConductSaga(action) {
  try {
    const userInfo = yield call([odoo, odoo.acceptCodeOfConduct], action.payload);
    yield put(acceptCodeOfConductSuccess(userInfo));
  } catch (error) {
    yield put(acceptCodeOfConductFailure(error));
  }
}

function* logoutSaga() {
  yield call([odoo, odoo.logout]);
  localStorage.clear();
  yield put(logoutSuccess());
}

function* loadDashboardDataSaga() {
  yield call(fetchUserInfoSaga);

  yield all([
    yield put(fetchCertifications()),
    yield put(fetchScoreboard()),
    yield put(fetchActivities()),
    yield put(fetchMembershipRequest()),
    yield put(fetchAchievements()),
  ]);
}

export const _fetchUserInfoSaga = fetchUserInfoSaga;

export default function* root() {
  yield all([
    yield takeLatest([AUTH_LOGIN], loginSaga),
    yield takeLatest([FETCH_USER_INFO], fetchUserInfoSaga),
    yield takeLatest([UPDATE_USER_INFO], updateUserInfoSaga),
    yield takeLatest([AUTH_LOGOUT], logoutSaga),
    yield takeLatest([ACCEPT_COC], acceptCodeOfConductSaga),
    yield takeLatest([LOAD_DASHBOARD], loadDashboardDataSaga),
  ]);
}
