import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Table = ({ rows, onRowClick, children, ...props }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(children[0].props.field);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <>
      {props.Filters}
      <TableContainer>
        <MaterialTable className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {children.map((col, index) =>
                React.cloneElement(col, {
                  key: index,
                  activeOrder: orderBy === col.props.field,
                  order: order,
                  sortHandler: createSortHandler,
                }),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    key={rowIndex}
                    onClick={() => onRowClick(row.id, row)}
                    role="checkbox"
                    tabIndex={-1}
                    classes={{ hover: classes.rowHover }}
                  >
                    {children.map((col, index) => (
                      <TableCell
                        key={col.props.field || index}
                        component={index === 0 ? 'th' : undefined}
                        scope={index === 0 ? 'row' : undefined}
                        align={col.props.align}
                      >
                        {col.props.renderDepends
                          ? col.props.renderDepends(row)
                          : col.props.render
                          ? col.props.render(row[col.props.field], row)
                          : row[col.props.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default React.memo(Table);
