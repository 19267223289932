import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

class PageTitle extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {this.props.title}
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {this.props.subtitle}
        </Typography>
      </div>
    );
  }
}

const styles = {
  container: {
    marginBottom: 20,
  },
  title: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  substitle: {
    textAlign: 'center',
  },
};

export default withStyles(styles)(PageTitle);
