import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';

import { formatDate, getActivityStatus } from '../../utils';

import ActivityContextMenu from './ActivityContextMenu';

const ActivityTableRow = ({ activity, groups }) => {
  return (
    <TableRow key={activity.id}>
      <TableCell component="th" scope="row">
        {activity.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {getActivityStatus(activity.state)}
      </TableCell>
      <Hidden smDown>
        <TableCell>{formatDate(activity.event_date)}</TableCell>
        <TableCell>
          {activity.category_name} - {activity.sub_category_name}
        </TableCell>
      </Hidden>
      <TableCell align="right">{activity.pdu}</TableCell>

      <TableCell align="right">
        <ActivityContextMenu groups={groups} activity={activity} />
      </TableCell>
    </TableRow>
  );
};

export default ActivityTableRow;
