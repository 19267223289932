export const HOME = '/';
export const LOGIN = '/login';
export const CODE_OF_CONDUCT = '/code-of-conduct';
export const SERVER_UNDER_MAINTENANCE = '/server-under-maintenance';

export const PERSONAL_PAGE = '/m/:code';
export const LOCATIONS = '/locations';
export const LOCATION_APPROVE = '/location/:id';

export const ACTIVITIES_LIST = '/activities';
export const ACTIVITIES_SUMMARY = '/activities/ssummary';
export const ACTIVITIES_CREATE = '/activities/add';
export const ACTIVITIES_DETAIL = (id = ':activityId') => `/activities/${id}`;
export const ACTIVITIES_EDIT = (id = ':activityId') => `/activities/${id}/edit`;

export const CERTIFICATIONS_LIST = '/certifications';

export const MEMBERSHIP_SCORE = '/score';
export const MEMBERSHIP_LIST = '/memberships';
export const MEMBERSHIP_RULES = '/memberships/rules';

// Jobs routes
export const JOBS_OFFERS = '/offers';
export const JOBS_OFFERS_DETAIL = '/offers/:id';
export const JOBS_APPLIED = '/applications';
export const JOBS_APPLIED_DETAIL = '/applications/:id';

// Vouchers routes
export const VOUCHERS = '/vouchers';
export const VOUCHERS_DETAIL = '/vouchers/:id';
export const COUPON_CLAIM = '/coupons/claim/:token';
export const COUPON_REFERRAL = '/coupons/referral/:token';
export const COUPONS = '/coupons';
export const COUPONS_DETAIL = '/coupons/:id';

// Volunteers routes
export const VOLUNTEER_PROGRAMS_LIST = '/volunteer/programs';
export const VOLUNTEER_PROGRAMS_DETAIL = '/volunteer/programs/:id';
export const VOLUNTEER_PROGRAMS_DETAIL_ITEM = '/volunteer/programs/:id/:subId';
export const VOLUNTEER_OPPORTUNITIES_LIST = '/volunteer/opportunities';
export const VOLUNTEER_APPLICATION_LIST = '/volunteer/applications';
export const VOLUNTEER_APPLICATION_DETAIL = '/volunteer/applications/:id';
export const VOLUNTEER_OPPORTUNITIES_DETAIL = '/volunteer/opportunities/:id';

// Trainer routes
export const TRAINER_INFO = '/trainer/info';

// User routes
export const PROFILE_PAGE = '/profile';

export const PARTNER_INVITE = '/partnership/invite/:token';
export const CLAIMS_LIST = '/claims';
export const SCOREBOARD = '/scoreboard';

// Benefits routes
export const BENEFITS = '/benefits';
export const BENEFITS_DETAIL = '/benefits/:id';

// Documents
export const ALL_DOCUMENTS = '/documents';

// Shop routes
export const CART = '/cart';

export const CHECKOUT = '/checkout';
export const CHECKOUT_ORDER = '/checkout/:orderId';
export const CHECKOUT_TRANSFER = '/checkout/bank-transfer';
export const CHECKOUT_PAYPAL = '/checkout/paypal';
export const CHECKOUT_CARD = '/checkout/credit-card';
export const CHECKOUT_SUCCESS = '/checkout/success';
export const CHECKOUT_FAILURE = '/checkout/failure';

export const VOUCHER_PRODUCT_LIST = '/products/voucher';
export const VOUCHER_PRODUCT_DETAILS = '/products/voucher/:productId';

export const SUPPORT = '/support';
export const NOTIFICATION_HISTORY = '/notifications';

// Helpdesk routes
export const HELPDESK_TICKETS = '/support';
export const HELPDESK_TICKET_DETAILS = '/support/tickets/:id';
export const HELPDESK_TICKET_CREATE = '/support/create';