import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import logo from '../../assets/images/logo.png';
import { useCallback } from 'react';
import { acceptInvite, fetchInvite, declineInvite } from '../../store/reducers/PartnershipReducer';

import NotFoundPage from '../NotFoundPage';
import { useTranslation } from 'react-i18next';
import { getTranslatedPartnerType } from '../../utils/getTranslatedEnum';

const InvitePage = ({ classes }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const invite = useSelector(state => state.partnership.invite);
  const fetching = useSelector(state => state.partnership.fetching);
  const submitted = useSelector(state => state.partnership.submitted);

  const handleAccept = useCallback(() => {
    dispatch(acceptInvite(params.token));
  }, [dispatch, params]);

  const handleReject = useCallback(() => {
    dispatch(declineInvite(params.token));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(fetchInvite(params.token));
  }, [dispatch, params]);

  return invite ? (
    <Grid container justify="center">
      <Grid item className={classes.container}>
        <img src={logo} alt="Linux Professional Institute" className={classes.logo} />

        <Typography variant="h2">{t('You have been invited')}</Typography>

        {!submitted ? (
          <>
            <Typography variant="h5" className={classes.message}>
              {invite.lpi_partner_id_name}
              {t(' has registered you as a ')}
              {getTranslatedPartnerType(invite.partner_type)}
              {'. '}
              {t('Please confirm.')}
            </Typography>

            <Box>
              <Button
                onClick={handleAccept}
                size="large"
                color="primary"
                variant="contained"
                className={classes.acceptButton}
              >
                Accept
              </Button>

              <Button onClick={handleReject} variant="outlined" size="large">
                Decline
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="h5" className={classes.message}>
            {t('Your registration is complete. Click ')}
            <Link href="/login" color="secondary">
              {t('here')}
            </Link>
            {t(' to login, or ')}
            <Link href="/login" color="secondary">
              {t('here')}
            </Link>
            {t(' to return to LPI portal.')}
          </Typography>
        )}
      </Grid>
    </Grid>
  ) : (
    !fetching && <NotFoundPage />
  );
};

const styles = {
  container: {
    marginTop: 50,
    padding: 16,
  },
  logo: {
    height: 50,
    marginBottom: 50,
  },
  message: {
    marginTop: 10,
    marginBottom: 30,
  },
  acceptButton: {
    marginRight: 10,
  },
};

export default withStyles(styles)(InvitePage);
