import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Box, Hidden, Typography } from '@material-ui/core';

import Column from '../Common/TableColumn';
import EmptyState from '../Common/EmptyState';
import OffersDetail from '../Jobs/OffersDetail';
import Table from '../Common/Table';
// json data
import locations from '../../assets/res/locations';
// import certifications from "../../assets/res/certifications"

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

const ApplicationsTable = ({ items, rootPath }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [item, setItem] = React.useState();
  let history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    let tempItem = items.find(application => application.id == id);
    if (tempItem) {
      setItem(tempItem);
    }
  }, [id, items, history]);

  const handleRowClick = React.useCallback(
    id => {
      history.push(rootPath + '/' + id);
    },
    [history, rootPath],
  );

  const handleCloseDetail = React.useCallback(() => {
    history.push(rootPath);
  }, [history, rootPath]);

  const getCountryName = id => (locations.find(el => el.id === id) || { name: '' }).name;

  const getStateName = id =>
    (
      locations.flatMap(el => el.states).find(el => el.id === id) || {
        name: '',
      }
    ).name;

  const locName = item =>
    [item.location, getStateName(item.state_id), getCountryName(item.country)].filter(chunk => chunk).join(', ');

  return (
    <Box mt={4} px={2}>
      {(() => {
        return _values(items).length > 0 ? (
          <Grid container spacing={3} display="flex">
            <Hidden mdDown={!!id}>
              <Grid item xs={12} md={!!id ? 8 : 12}>
                <Card variant="outlined">
                  <CardContent classes={{ root: classes.customCardRoot }}>
                    <Table rows={_values(items)} onRowClick={handleRowClick}>
                      <Column label={t('Title')} field="name" />
                      <Column
                        label={t('Certifications')}
                        field="certificates"
                        render={values => {
                          return (
                            values && (
                              <Grid container spacing={1}>
                                {values.map(function(item, index) {
                                  return (
                                    <Grid item key={index}>
                                      <Chip size="small" label={item.name} />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )
                          );
                        }}
                      />
                      <Column
                        label={t('Location')}
                        render={(_, offer) => (
                          <Typography display="inline">
                            {[offer.country?.name, offer.state?.name, offer.city].filter(el => !!el).join(', ')}
                          </Typography>
                        )}
                      />
                      <Column
                        label={t('Expiring Date')}
                        field="job_end_date"
                        render={date => (!!date ? new Date(date).toLocaleDateString() : t('Not defined'))}
                      />
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Hidden>
            <Switch>
              <Route path={rootPath + '/:id(\\d+)'}>
                <Grid item xs={12} md={4}>
                  {!!item && <OffersDetail offer={item} onClose={handleCloseDetail} />}
                </Grid>
              </Route>
            </Switch>
          </Grid>
        ) : (
          <EmptyState />
        );
      })()}
    </Box>
  );
};

export default React.memo(ApplicationsTable);
