import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchCoupons, claimCoupon, createReferralCoupon } from '../reducers/CouponsReducer';
import odoo from '../../backend';
import i18n from '../../locales';

function* fetchCouponsSaga() {
  try {
    const coupons = yield call([odoo.coupons, odoo.coupons.search]);
    yield put(fetchCoupons.success(coupons));
  } catch (e) {
    yield put(fetchCoupons.failure('Unable to fetch coupons'));
  }
}

function* claimCouponSaga({ payload }) {
  try {
    const { coupon_id } = yield call(odoo.claimCoupon, payload);
    yield put(claimCoupon.success(coupon_id));
  } catch (e) {
    console.log(e);
    yield put(claimCoupon.failure(i18n.t('Coupon not found or already taken')));
  }
}

function* createReferralCouponSaga({ payload }) {
  try {
    const { coupon_id } = yield call(odoo.createReferralCoupon, payload);
    yield put(createReferralCoupon.success(coupon_id));
  } catch (e) {
    console.log(e);
    yield put(createReferralCoupon.failure(i18n.t('Cannot generate coupon with this referral link')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchCoupons.TRIGGER], fetchCouponsSaga)]);
  yield all([yield takeLatest([claimCoupon.TRIGGER], claimCouponSaga)]);
  yield all([yield takeLatest([createReferralCoupon.TRIGGER], createReferralCouponSaga)]);
}
