import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  subheader: {
    color: '#CFD8DC',
    background: '#37474f',
  },
  betaChip: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#ededed',
    backgroundColor: '#999',
    marginLeft: '1rem',
  },
}));

const NavList = ({ label, icon, children, open, setOpen, testid, beta = false }) => {
  const classes = useStyles();
  const handleClick = () => setOpen(open === label ? null : label);

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.subheader} data-testid={testid}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} />
        {beta && <Chip label="BETA" size="small" className={classes.betaChip} />}
        {open === label ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open === label} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

NavList.propTypes = {
  label: PropTypes.string.isRequired,
  open: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  icon: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.node),
  testid: PropTypes.string,
  beta: PropTypes.bool,
};

export default React.memo(NavList);
