import React, { useEffect } from 'react';
import api from '../backend/index';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const ServerUnderMaintenancePage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    // Ping server every 2 seconds until is up, then go back
    (async () => {
      while (!(await api.monitor.isServerUp())) {
        await new Promise(res => setTimeout(res, 2000));
      }
      history.goBack();
    })();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
    >
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h5" gutterBottom>
          {t('Please wait')}
        </Typography>
        <Typography align="center">{t('The service is temporarily under maintenance')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" fontSize="lg">
          {t('You will be redirected when the service comes back online.')} <br />
          {t('We are sorry for the inconvenience.')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ServerUnderMaintenancePage;
