import React from 'react';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from '../../navigation/routes';

const PrivateRoute = ({ component: Component, render, path, user, exact, ...rest }) => {
  const location = useLocation();

  if (user === null) {
    return <Redirect to={`${routes.LOGIN}?next=${location.pathname}`} />;
  }

  return <Route exact={exact} path={path} render={render || (() => <Component {...rest} />)} />;
};

const mapStateToProps = ({ auth, router }) => {
  return {
    locationPath: router.location.pathname,
    user: auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
