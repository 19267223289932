import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { VOLUNTEER_OPPORTUNITIES_LIST, VOLUNTEER_PROGRAMS_LIST } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import Column from '../Common/TableColumn';
import EmptyState from '../Common/EmptyState';
import Table from '../Common/Table';
import OpportunityDetail from './OpportunityDetail';
import StatusChip from './StatusChip';
import { usePrograms } from '../../hooks/Volunteers';

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

const OpportunitiesTable = ({ program, rows }) => {
  const { t } = useTranslation();
  const { programs } = usePrograms();
  const classes = useStyles();

  const location = useLocation();
  let history = useHistory();

  const { id } = useParams();
  const [item, setItem] = React.useState();

  React.useEffect(() => {
    let tempItem = rows.find(i => i.id == id);
    if (tempItem) {
      setItem(tempItem);
    }
  }, [id, rows, history]);

  const handleRowClick = React.useCallback(
    _subId => {
      history.push({
        pathname: VOLUNTEER_OPPORTUNITIES_LIST + '/' + _subId,
        search: location.search,
      });
    },
    [history, location, program],
  );

  return !!rows.length ? (
    <Grid container spacing={3} display="flex">
      <Grid item xs={12} sm>
        <Card variant="outlined">
          <CardContent classes={{ root: classes.customCardRoot }}>
            <Table rows={_values(rows)} onRowClick={handleRowClick}>
              <Column label={t('Status')} field="status" render={value => value && <StatusChip status={value} />} />
              <Column label={t('Program')} field="program" render={id => programs.find(p => p.id == id)?.name} />
              <Column label={t('Title')} field="name" />
              <Column label={t('Location')} field="location" />
              <Column
                label={t('Application Deadline')}
                field="deadline"
                render={v => (v ? new Date(v).toLocaleDateString() : '')}
              />
            </Table>
          </CardContent>
        </Card>
      </Grid>
      {item && (
        <Grid item xs={12} sm={4}>
          <OpportunityDetail
            item={item}
            onCloseClick={() => {
              setItem(null);
              history.push({
                pathname: VOLUNTEER_OPPORTUNITIES_LIST,
                search: location.search,
              });
            }}
            onAddFavourite={() => {}}
            onRemoveFavourite={() => {}}
            onApply={() => {}}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <EmptyState />
  );
};

export default React.memo(OpportunitiesTable);
