import React from 'react';

import Box from '@material-ui/core/Box';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Hidden } from '@material-ui/core';

const Arrow = () => {
  return (
    <Box display="block" paddingX={4}>
      <Hidden smDown>
        <ArrowForward fontSize="large" />
      </Hidden>
      <Hidden mdUp>
        <ArrowDownward fontSize="large" />
      </Hidden>
    </Box>
  );
};

export default React.memo(Arrow);
