import * as types from '../actions/memberships/types';

const INITIAL_STATE = {
  pending: false,
  list: [],
  products: [],
  rules: [],
  submittingRequest: false,
  error: null,
  request: null,
};

const membershipsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_MEMEBERSHIP_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case types.CREATE_MEMEBERSHIP_REQUEST_SUCCESS:
      return {
        ...state,
        submittingRequest: false,
        request: action.payload,
        error: null,
      };
    case types.CREATE_MEMEBERSHIP_REQUEST_FAILURE:
      return {
        ...state,
        submittingRequest: false,
        error: action.payload,
      };
    case types.FETCH_MEMEBERSHIP_RULES:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_MEMEBERSHIP_RULES_SUCCESS:
      return {
        ...state,
        pending: false,
        rules: action.payload,
      };
    case types.FETCH_MEMEBERSHIP_RULES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case types.FETCH_MEMEBERSHIP_REQUEST_SUCCESS:
      return {
        ...state,
        request: action.payload,
      };
    default:
      return state;
  }
};

export default membershipsReducer;
