import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchVouchers } from '../reducers/VouchersReducer';
import odoo from '../../backend';

function* fetchVouchersSaga() {
  try {
    const vouchers = yield call([odoo.vouchers, odoo.vouchers.search]);
    yield put(fetchVouchers.success(vouchers));
  } catch (e) {
    yield put(fetchVouchers.failure('Unable to fetch vouchers'));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchVouchers.TRIGGER], fetchVouchersSaga)]);
}
