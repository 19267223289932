import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';
import { QUERY_KEY } from './useVouchers';

const useVoucherMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const redeem = useMutation(id => api.vouchers.redeem(id), {
    onSuccess: invalidateQuery,
  });

  return {
    redeem,
  };
};

export default useVoucherMutations;
