import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { init, login, logout, update, acceptCoc, fetchUserInfo } from '../reducers/AuthReducer';
import { HOME } from '../../navigation/routes';
import i18n from '../../locales';
import odoo from '../../backend';
import { setMessage } from '../reducers/SnackReducer';

function* initSaga() {
  try {
    const user = yield call([odoo, odoo.fetchUserInfo]);

    yield put(init.success({ user }));
  } catch (e) {
    yield put(init.failure());
  }
}

function* loginSaga({ payload }) {
  try {
    const { lpiid, password, next } = payload;
    yield call([odoo, odoo.login], lpiid, password);
    const user = yield call([odoo, odoo.fetchUserInfo]);

    yield put(login.success({ user }));
    yield put(push(next || HOME));
  } catch (e) {
    yield put(login.failure(i18n.t('Incorrect username or password')));
  }
}

function* logoutSaga() {
  yield put(push(HOME));
  localStorage.clear();
}

function* updateSaga({ payload }) {
  try {
    const info = yield call([odoo, odoo.updateUserInfo], payload);

    yield put(update.success(info));
    yield put(setMessage(i18n.t('Profile updated')));
  } catch (e) {
    yield put(update.failure(i18n.t('Unable to update info')));
  }
}

function* acceptCocSaga({ payload }) {
  try {
    const userInfo = yield call([odoo, odoo.acceptCodeOfConduct], payload);

    yield put(acceptCoc.success(userInfo));
    yield put(setMessage(i18n.t('Code of Conduct accepted')));
  } catch (e) {
    yield put(acceptCoc.failure(i18n.t('Unable to accept COC')));
  }
}

function* fetchUserInfoSaga() {
  try {
    const userInfo = yield call([odoo, odoo.fetchUserInfo]);
    yield put(fetchUserInfo.success(userInfo));
  } catch (error) {
    yield put(fetchUserInfo.failure(error));
  }
}

export default function* root() {
  yield all([yield takeLatest([init.TRIGGER], initSaga)]);
  yield all([yield takeLatest([login.TRIGGER], loginSaga)]);
  yield all([yield takeLatest([logout.TRIGGER], logoutSaga)]);
  yield all([yield takeLatest([update.TRIGGER], updateSaga)]);
  yield all([yield takeLatest([acceptCoc.TRIGGER], acceptCocSaga)]);
  yield all([yield takeLatest([fetchUserInfo.TRIGGER], fetchUserInfoSaga)]);
}
