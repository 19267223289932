import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'clipboard-copy';

const CopyToClipboard = ({ title, leaveDelay, render }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(title);

  const onCopy = content => {
    setTooltip(title);
    try {
      copy(content);
    } catch {
      setTooltip('Cannot access clipboard');
    }
    setShowTooltip(true);
  };

  return (
    <Tooltip open={showTooltip} title={tooltip} leaveDelay={leaveDelay} onClose={() => setShowTooltip(false)}>
      {render(onCopy)}
    </Tooltip>
  );
};

export default React.memo(CopyToClipboard);
