import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUser, getActiveCertifications, getInactiveCertifications, getPDUStatus } from '../../store/selectors';

import * as routes from '../../navigation/routes';

import Box from '@material-ui/core/Box';
import RecommendedActionItem from './RecommendedActionItem';

const RecommendedActions = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const request = useSelector(state => state.memberships.request);
  const pduStatus = useSelector(getPDUStatus);
  const activeCertifications = useSelector(getActiveCertifications);
  const inactiveCertifications = useSelector(getInactiveCertifications);

  return (
    <Box>
      {activeCertifications.length === 0 && inactiveCertifications.length === 0 && (
        <RecommendedActionItem
          href="http://lpi.org/"
          action={t('Visit LPI Website')}
          title={t('Get Certified')}
          content={t(
            'To access LPI Membership Program you need a Professional Level Certification from us. Go and get one now!',
          )}
        />
      )}
      {user.membership_step === 'payment' && request && request.order_id ? (
        <RecommendedActionItem
          route={routes.CHECKOUT_ORDER.replace(':orderId', request.order_id)}
          action={t('Pay membership dues')}
          title={t('Request approved')}
          content={t('Membership has been approved.')}
        />
      ) : null}

      {user.membership_step === 'eligible' && (
        <RecommendedActionItem
          action={t('Request Membership')}
          route={routes.MEMBERSHIP_LIST}
          title={t('Congratulations!')}
          content={t('You are eligible to request membership.')}
        />
      )}

      {user.membership_step === 'expired' && (
        <RecommendedActionItem
          action={t('Renew Your Membership')}
          route={routes.MEMBERSHIP_LIST}
          title={t('Your membership has expired!')}
          content={t('Pay your membership due and mantain active your certifications.')}
        />
      )}

      {user.membership_step !== 'eligible' && user.can_submit_pdus && (
        <RecommendedActionItem
          action={t('Submit PDUs')}
          route={routes.ACTIVITIES_CREATE}
          title={t('Add PDUs')}
          content={
            pduStatus === 'inactive' && activeCertifications.length === 0
              ? t(
                  "Claim PDUs here! You need 20 PDUs to be eligible for membership. PDUs can't be older than one year from when you apply for membership.",
                )
              : pduStatus === 'achieved'
              ? t('Get ahead on the next cycle’s PDUs by submitting extra PDUs in the current cycle')
              : t("Claim PDUs for your current cycle!  Don't wait for the last minute.")
          }
        />
      )}

      {user.membership_step === 'inlegibile' ? (
        <RecommendedActionItem
          action={t('Get Certified')}
          href="https://www.lpi.org/"
          title=""
          content={t('Only active certification holders are eligible for membership. Get certified today and join.')}
        />
      ) : null}

      <RecommendedActionItem
        action={t('Check out')}
        route={routes.BENEFITS}
        title={t('Community Benefits')}
        content={t('Check out some of the Community benefits')}
      />
    </Box>
  );
};

RecommendedActions.propTypes = {
  user: PropTypes.object,
  pduStatus: PropTypes.string,
};

export default React.memo(RecommendedActions);
