import React from 'react';

import CertificationListItem from './CertificationListItem';

const CertificationList = ({ certifications }) => {
  return (
    <div>
      {certifications.map(certification => (
        <CertificationListItem key={certification.id} certification={certification} />
      ))}
    </div>
  );
};

export default React.memo(CertificationList);
