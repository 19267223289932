import React, { useEffect, useState, useMemo } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import usePlaces from '../../../hooks/usePlaces';
import { useTranslation } from 'react-i18next';

const StateInput = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();
  const { places, status } = usePlaces();

  const country = useMemo(() => !!filters.country && places.find(place => filters.country.id == place.id), [
    filters.country,
  ]);

  const states = useMemo(() => {
    if (!!country) {
      /* Display states of selected country */
      return country.states;
    } else {
      if (!!places.length) {
        /* Display all the states */
        return places.reduce((acc, place) => {
          if (!!place.states?.length) {
            acc = acc.concat(place.states);
          }
          return acc;
        }, []);
      } else return [];
    }
  }, [filters.country, places]);

  // TODO set state to null if country change

  return (
    <Autocomplete
      value={filters.state || null}
      options={states}
      loading={status === 'loading'}
      getOptionLabel={option => option.name || ''}
      onChange={(_e, value) => {
        if (value) setFilters({ ...filters, state: { id: value.id, name: value.name } });
        else setFilters({ ...filters, state: null });
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('State')}
          margin="none"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};

export default React.memo(StateInput);
