import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { VOLUNTEER_OPPORTUNITIES_LIST } from '../../navigation/routes';
import {
  fetchPrograms,
  fetchOpportunities,
  applyOpportunity,
  saveOpportunity,
  unsaveOpportunity,
} from '../reducers/VolunteersReducer';
import { setMessage } from '../reducers/SnackReducer';
import i18n from '../../locales';
import odoo from '../../backend';

function* fetchProgramsSaga() {
  try {
    const opportunities = yield call([odoo.volunteer.opportunities, odoo.volunteer.opportunities.search]);
    const programs = yield call([odoo.volunteer.programs, odoo.volunteer.programs.search]);

    const programsWithOpps = programs.results.map(program => {
      program['opportunity'] = _keyBy(
        _filter(_cloneDeep(opportunities), function(o) {
          return _includes(program['opportunity'], o.id);
        }),
        'id',
      );
      return program;
    });
    yield put(fetchPrograms.success(programsWithOpps));
  } catch (e) {
    yield put(fetchPrograms.failure(i18n.t('Unable to fetch volunteer programs')));
  }
}

function* fetchOpportunitiesSaga() {
  try {
    const opportunities = yield call([odoo.volunteer.opportunities, odoo.volunteer.opportunities.search]);
    const favourites = yield call([odoo.volunteer.favourites, odoo.volunteer.favourites.search]);

    var savedOpportunities = _filter(_cloneDeep(opportunities.results), function(o) {
      return _includes(favourites.opportunities, o.id);
    });

    const applications = yield call([odoo.volunteer.applications, odoo.volunteer.applications.search]);

    const appliedOpportunities = applications.map(application => {
      application['opportunity'] = _find(opportunities, function(o) {
        return o.id === application['opportunity'];
      });
      return application;
    });

    yield put(fetchOpportunities.success({ appliedOpportunities, savedOpportunities }));
  } catch (e) {
    yield put(fetchOpportunities.failure(i18n.t('Unable to fetch volunteer opportunities')));
  }
}

function* applyOpportunitySaga({ payload }) {
  try {
    const opportunity = yield call([odoo.volunteer.applications, odoo.volunteer.applications.create], {
      program_id: payload.program_id,
      opportunity_id: payload.opportunity_id,
      timeshift: payload.timeshift,
    });
    yield put(applyOpportunity.success(opportunity));
    yield put(setMessage(i18n.t(`Successfully applied to "${payload.name}"`)));
    if (opportunity.opportunity) {
      yield put(push(VOLUNTEER_OPPORTUNITIES_LIST + '/' + opportunity.id));
    }
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to apply for opportunity')));
    yield put(applyOpportunity.failure(i18n.t('Unable to apply for opportunity')));
  }
}

function* saveOpportunitySaga({ payload }) {
  try {
    yield call([odoo.volunteer.favourites, odoo.volunteer.favourites.create], {
      opportunity_id: payload.id,
    });
    yield put(saveOpportunity.success());
    yield call(fetchOpportunitiesSaga);
    yield put(setMessage(i18n.t(`"${payload.name}" saved`)));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to save opportunity')));
    yield put(saveOpportunity.failure(i18n.t('Unable to save opportunity')));
  }
}

function* unsaveOpportunitySaga({ payload }) {
  try {
    yield call([odoo.volunteer.favourites, odoo.volunteer.favourites.delete], payload.id);
    yield put(saveOpportunity.success());
    yield call(fetchOpportunitiesSaga);
    yield put(setMessage(i18n.t(`"${payload.name}" removed from saved`)));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to remove opportunity from saved')));
    yield put(saveOpportunity.failure(i18n.t('Unable to remove opportunity from saved')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchPrograms.TRIGGER], fetchProgramsSaga)]);
  yield all([yield takeLatest([fetchOpportunities.TRIGGER], fetchOpportunitiesSaga)]);
  yield all([yield takeLatest([applyOpportunity.TRIGGER], applyOpportunitySaga)]);
  yield all([yield takeLatest([saveOpportunity.TRIGGER], saveOpportunitySaga)]);
  yield all([yield takeLatest([unsaveOpportunity.TRIGGER], unsaveOpportunitySaga)]);
}
