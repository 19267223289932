import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOpportunities } from '../../store/reducers/VolunteersReducer';
import { getVolunteerRequests } from '../../store/selectors';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import Layout from '../../components/App/Layout';
import AppliedOpportunitiesTable from '../../components/Volunteers/AppliedOpportunitiesTable';
import { useAppliedOpportunities } from '../../hooks/Volunteers';
import EmptyState from '../../components/Common/EmptyState';
import LoadingState from '../../components/Common/LoadingState';

const ApplicationsPage = () => {
  const { t } = useTranslation();

  const { applications, isLoading } = useAppliedOpportunities();

  return (
    <Layout title={t('Opportunities')}>
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Your applications')}
        </Typography>
        {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
      </Box>

      <Box mt={4} px={2}>
        {isLoading ? <LoadingState /> : <AppliedOpportunitiesTable items={applications} />}
        {!applications && !isLoading && <EmptyState />}
      </Box>
    </Layout>
  );
};

export default React.memo(ApplicationsPage);
