import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/App/Layout';
import { getActivities } from '../../store/selectors';
import { fetchActivity } from '../../store/actions/activities';
import { Box, Container, Typography, Chip } from '@material-ui/core';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';

const STATES = {
  need_review: 'Needs Review',
};

const ActivityDetailPage = ({ computedMatch }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activityId } = computedMatch.params;
  const activity = useSelector(getActivities)[activityId];

  useEffect(() => {
    dispatch(fetchActivity(activityId));
  }, [dispatch, activityId]);

  return (
    activity && (
      <Layout title={activity.name}>
        <Container>
          <Box>
            <Chip label={STATES[activity.state]} />
          </Box>
          <Box paddingY={2}>
            <Typography variant="h3">{activity.name}</Typography>
            <Typography variant="subtitle1">
              {t('Submitted on ')}
              {formatDate(activity.create_date)}
            </Typography>
          </Box>

          <Box paddingY={2}>
            <Typography variant="body1">{activity.description}</Typography>
          </Box>
        </Container>
        <Box></Box>
      </Layout>
    )
  );
};

export default React.memo(ActivityDetailPage);
