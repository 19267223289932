import React, { memo } from 'react';
import { Box, CircularProgress, Grid, Typography, Button, Dialog } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { CHECKOUT_SUCCESS, CHECKOUT_FAILURE, CHECKOUT } from '../../../navigation/routes';
import usePayments, { PAYMENT_PROVIDER } from '../../../hooks/Shop/usePayments';

const TransferCheckout = ({ cart }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { paymentMethods, status, confirmTransfer, mutationStatus } = usePayments(cart.id);

  const handleConfirmPayment = () => {
    confirmTransfer(cart.id)
      .then(() => {
        history.push([CHECKOUT_SUCCESS, cart.id].join('/'));
      })
      .catch(error => {
        console.error(error);
        history.push([CHECKOUT_FAILURE, cart.id].join('/'));
      });
  };

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Box display="flex" justifyContent="center" height="100%" alignItems="center">
          <CircularProgress />
        </Box>
      );

    case 'error':
      return (
        <Grid container direction="column">
          <Typography style={{ color: red[500] }} align="center" gutterBottom variant="h6">
            {t('Error loading wire transfer details')}
          </Typography>
          <Typography align="center">{t('Try again')}</Typography>
        </Grid>
      );

    case 'success': {
      const transferDataHTML = paymentMethods.find(method => method.provider === PAYMENT_PROVIDER.WIRE_TRANSFER)
        ?.message;

      if (transferDataHTML) {
        return (
          <Dialog open={true}>
            <Box display="flex" flexDirection="column" p={3}>
              <div dangerouslySetInnerHTML={{ __html: transferDataHTML }} />

              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmPayment}
                fullWidth
                disabled={mutationStatus.confirmTransfer === 'loading'}
                style={{ marginBottom: 5 }}
              >
                {mutationStatus.confirmTransfer === 'loading' ? <CircularProgress size={25} /> : t('Confirm payment')}
              </Button>
              <Button variant="contained" onClick={() => history.push([CHECKOUT, cart.id].join('/'))} fullWidth>
                {t('Back to payment methods')}
              </Button>
            </Box>
          </Dialog>
        );
      } else {
        return (
          <Grid container direction="column">
            <Typography style={{ color: red[500] }} align="center" gutterBottom variant="h6">
              {t('Bank transfer is not accepted')}
            </Typography>
            <Typography align="center">{t('Try with an other payment method')}</Typography>
          </Grid>
        );
      }
    }
  }
};

export default memo(TransferCheckout);
