import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../backend/index';

export const QUERY_KEY = 'profile.detail';

/**
 * Get profile data
 */
export const useProfile = (config = {}) => {
  const req = useQuery([QUERY_KEY], () => api.fetchUserInfo(), config);

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    profile: req.data || null,
    ...req,
  };
};

export const useProfileMutations = id => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const updateProfile = useMutation(data => api.updateUserInfo({ id, data }), {
    onSuccess: invalidateQuery,
  });

  return {
    updateProfile,
  };
};
