import * as types from './types';

export const addNotification = notification => {
  return {
    type: types.APP_ADD_NOTIFICATION,
    payload: {
      key: new Date().getTime(),
      notification,
    },
  };
};

export const hideNotification = () => {
  return {
    type: types.APP_HIDE_NOTIFICATION,
  };
};

export const removeNotification = () => {
  return {
    type: types.APP_REMOVE_NOTIFICATION,
  };
};
