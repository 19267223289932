import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';

import ProductListItem from './ProductListItem';

class ProductList extends PureComponent {
  static defaultProps = {
    products: [],
    onSubmit: () => {},
    submitting: false,
    disabled: false,
    renew: false,
  };

  render() {
    return (
      <Grid container spacing={2} justify="center">
        {this.props.products.map(product => (
          <ProductListItem
            key={product.id}
            submitting={this.props.submitting}
            onSubmit={this.props.onSubmit}
            product={product}
            disabled={this.props.disabled}
            renew={this.props.renew}
          />
        ))}
      </Grid>
    );
  }
}

export default ProductList;
