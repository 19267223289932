import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchMembershipRules } from '../../store/actions/memberships';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Layout from '../../components/App/Layout';
import RulesTable from '../../components/Memberships/RulesTable';
import { Typography, Box } from '@material-ui/core';

import { Translation, withTranslation } from 'react-i18next';

import { EDUCATION_SLUG, COMMUNITY_SLUG, EXPERIENCE_SLUG } from '../../consts';

const SECTIONS = [
  {
    title: 'Education',
    slug: EDUCATION_SLUG,
  },
  {
    title: 'Community',
    slug: COMMUNITY_SLUG,
  },
  {
    title: 'Experience',
    slug: EXPERIENCE_SLUG,
  },
];

class RulesPage extends PureComponent {
  componentDidMount() {
    this.props.fetchMembershipRules();
  }

  render() {
    const { classes, rules, pendingRequest, t } = this.props;
    return (
      <Layout title="Membership Rules">
        <Box mt={4} mb={2} px={2}>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader title="What is a PDU?" />
            <CardContent>
              <Typography paragraph>
                <Translation>
                  {t =>
                    t(
                      'PDU stands for a Professional Development Unit and LPI uses it to maintain ongoing professional development.',
                    )
                  }
                </Translation>
              </Typography>
              <Typography paragraph>
                {t(
                  'In order to maintain your LPI credentials, you will need to maintain a specific number of PDUs which can be earned through participating in industry and community events, continuing education, and work and volunteer experience.',
                )}
              </Typography>

              <Typography variant="h6" color="textSecondary">
                {t('How I can earn PDUs?')}
              </Typography>

              <Typography paragraph>
                {t('For details how the PDU system works, please visit:')}
                <a href="https://www.lpi.org/member/pdu-procedures-and-policies">
                  https://www.lpi.org/member/pdu-procedures-and-policies
                </a>
              </Typography>
            </CardContent>
          </Card>
          {pendingRequest
            ? null
            : SECTIONS.map(s => (
                <div key={s.title}>
                  <Typography variant="h5" className={classes.sectionTitle}>
                    {s.title}
                  </Typography>
                  {_keys(rules[s.slug]).map(label => (
                    <ExpansionPanel key={label}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">{label}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <RulesTable rules={rules[s.slug][label]} />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </div>
              ))}
        </Box>
      </Layout>
    );
  }
}

const styles = {
  sectionTitle: {
    marginBottom: 16,
    marginTop: 16,
  },
};

const mapStateToProps = ({ memberships }) => {
  const rulesByCategory = {};

  rulesByCategory[EDUCATION_SLUG] = memberships.rules.filter(r => r.category_slug === EDUCATION_SLUG);
  rulesByCategory[COMMUNITY_SLUG] = memberships.rules.filter(r => r.category_slug === COMMUNITY_SLUG);
  rulesByCategory[EXPERIENCE_SLUG] = memberships.rules.filter(r => r.category_slug === EXPERIENCE_SLUG);

  rulesByCategory[EDUCATION_SLUG] = _groupBy(rulesByCategory[EDUCATION_SLUG], 'sub_category_name');
  rulesByCategory[COMMUNITY_SLUG] = _groupBy(rulesByCategory[COMMUNITY_SLUG], 'sub_category_name');
  rulesByCategory[EXPERIENCE_SLUG] = _groupBy(rulesByCategory[EXPERIENCE_SLUG], 'sub_category_name');

  return {
    pendingRequest: memberships.pending,
    rules: rulesByCategory,
  };
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps, { fetchMembershipRules })(RulesPage)));
