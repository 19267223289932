import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchBenefits } from '../reducers/BenefitsReducer';
import odoo from '../../backend';

function* fetchBenefitsSaga() {
  try {
    const benefits = yield call([odoo.benefits, odoo.benefits.search]);
    yield put(fetchBenefits.success(benefits));
  } catch (e) {
    yield put(fetchBenefits.failure('Unable to fetch benefits'));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchBenefits.TRIGGER], fetchBenefitsSaga)]);
}
