import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupons } from '../reducers/CouponsReducer';
import { getCoupons } from '../selectors';

const useCoupons = () => {
  const dispatch = useDispatch();
  const coupons = useSelector(getCoupons);
  const [wrapper, setWrapper] = React.useState({ all: [], new: [], old: [] });

  React.useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const filterIsNew = React.useCallback(
    isNew =>
      coupons.filter(item =>
        isNew ? !['used', 'expired'].includes(item.state) : ['used', 'expired'].includes(item.state),
      ),
    [coupons],
  );

  React.useEffect(() => {
    setWrapper({
      all: coupons,
      new: filterIsNew(true),
      old: filterIsNew(false),
    });
  }, [coupons, filterIsNew]);

  return wrapper;
};
export { useCoupons };
