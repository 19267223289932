import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const RecommendedActionItem = ({ action, href, route, title, content }) => {
  return (
    <Box marginBottom={1}>
      <Card>
        <CardContent>
          {title && (
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          )}

          {content && <Typography>{content}</Typography>}
        </CardContent>

        <CardActions>
          {href ? (
            <Button href={href} target="_blank" variant="contained">
              {action}
            </Button>
          ) : route ? (
            <Button component={Link} to={route} variant="contained">
              {action}
            </Button>
          ) : null}
        </CardActions>
      </Card>
    </Box>
  );
};

RecommendedActionItem.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  route: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default React.memo(RecommendedActionItem);
