import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import LabelledValue from '../Common/LabelledValue';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, Paper } from '@material-ui/core';
import { useProfile, useProfileMutations } from '../../hooks/useProfile';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import TextField from '@material-ui/core/TextField';

const ApprovedTrainerCard = ({ profile }) => {
  const { t } = useTranslation();
  const { updateProfile } = useProfileMutations(profile?.id);
  const dispatch = useDispatch();

  const [publicUrl, setPublicUrl] = React.useState(profile.trainer_public_url);

  const handleSavePublicUrl = () => {
    updateProfile
      .mutateAsync({ ...profile, trainer_public_url: publicUrl })
      .then(() => {
        dispatch(setMessage('Profile updated'));
      })
      .catch(() => {
        dispatch(setMessage('An error occurred during the request'));
      });
  };

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{t('Approved Trainer')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{t('Congratulations, you are an LPI Approved trainer.')}</Typography>
          <Box marginTop={2}>
            <Typography variant="body2">{t('Here you can download you Approved Trainer certificate')}</Typography>
          </Box>
          <Box marginY={2}>
            <Link href={profile?.trainer_certificate_url} target="_blank" color="primary">
              <Button variant="contained">{t('Download certificate  ')}</Button>
            </Link>
          </Box>
          <Typography variant="body2">
            {t('You can can also add a public URL that will be published in your bio to help people reach you')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex">
            <TextField
              fullWidth
              variant="outlined"
              label={t('Public url')}
              value={publicUrl}
              onChange={ev => setPublicUrl(ev.target.value)}
            />
            <Button
              variant="contained"
              disabled={publicUrl === profile.trainer_public_url}
              onClick={handleSavePublicUrl}
            >
              {t('Save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ApprovedTrainerCard;
