import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import usePlaces from '../../../hooks/usePlaces';
import { useTranslation } from 'react-i18next';

const CountryInput = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();
  const { places, status } = usePlaces();

  return (
    <Autocomplete
      value={filters.country || null}
      options={places}
      loading={status === 'loading'}
      getOptionLabel={option => option.name || ''}
      onChange={(_e, value) => {
        if (value) setFilters({ ...filters, country: { id: value.id, name: value.name } });
        else setFilters({ ...filters, country: null });
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('Country')}
          margin="none"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};

export default React.memo(CountryInput);
