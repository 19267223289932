import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import useAttachmentMutations from '../../hooks/Attachment/useAttachmentMutations';

const UploadMaterialModal = ({ program }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [files, setFiles] = useState([]);
  const { createAttachment } = useAttachmentMutations();

  function handleClose() {
    setFiles([]);
    setOpen(false);
    setHasApplied(false);
  }

  async function handleUpload() {
    //handleOk(values);
    await createAttachment.mutateAsync({
      model: 'volunteer.applications',
      id: program.application_id,
      files,
    });
    setHasApplied(true);
  }

  const handleChange = ({ target }) => {
    setFiles(Array.from(target.files));
  };

  return (
    <>
      <Button size="small" color="secondary" onClick={() => setOpen(true)}>
        {t('Completed Onboarding Materials')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>{t('Upload materials for ') + program.name}</DialogTitle>

        {hasApplied ? (
          <>
            <DialogContent dividers>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {t('Thanks for for uploading the document, our team will be back to you soon.')}
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t('Close')}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent dividers>
              <strong>{files.length > 0 ? t('Loaded files') : t('Choose file to upload')}</strong>
              <ul>{files.length > 0 && files.map(file => <li key={file.name}>{file.name}</li>)}</ul>

              <Button variant="contained" component="label">
                {t('Browse')}
                <input type="file" hidden onChange={handleChange} />
              </Button>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="default">
                {t('Cancel')}
              </Button>
              <Button onClick={handleUpload} color="secondary" disabled={files.length == 0}>
                {t('Ok')}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default React.memo(UploadMaterialModal);
