import membersLogo from './assets/images/lpi.members.logo.black.svg';
import LE1 from './assets/images/LE-1.png';
import OTDTE from './assets/images/OT-DTE.png';
import LPIC1 from './assets/images/LPIC-1.png';
import LPIC2 from './assets/images/LPIC-2.png';
import LPIC3 from './assets/images/LPIC-3.png';
import LPIC3_LEGACY from './assets/images/LPIC-3-legacy-Core.jpg';
import LPIC3_LEGACY_MIXED from './assets/images/LPIC-3-legacy-Mixed.jpg';
import UCP1 from './assets/images/UCP-1-legacy.jpg';
// import LPI304 from "./assets/images/LPI-304.png"
import LPIC3_SECURITY from './assets/images/LPIC-3-303-Security.jpg';
import BSDA from './assets/images/BSDA.png';

export const CERTIFICATION_LOGOS = {
  members: membersLogo,
  'UCP-1': UCP1,
  'LPIC-1': LPIC1,
  'LPIC-2': LPIC2,
  'LPIC-3': LPIC3_LEGACY,
  'LPIC-3 (300)': LPIC3_LEGACY_MIXED,
  'LPIC-3 (301)': LPIC3_LEGACY_MIXED,
  'LPIC-3 (302)': LPIC3_LEGACY_MIXED,
  'LPIC-3 (303)': LPIC3_SECURITY,
  'LPIC-3 (304)': LPIC3,
  LPI304: LPIC3_LEGACY_MIXED,
  'LPI-300': LPIC3_LEGACY_MIXED,
  'LPI-301': LPIC3_LEGACY_MIXED,
  'LPI-302': LPIC3_LEGACY_MIXED,
  'LPI-303': LPIC3_SECURITY,
  'LPI-304': LPIC3,
  BSDA: BSDA,
  'OT-DTE': OTDTE,
  LE: LE1,
  'LE-1': LE1,
};

export const CERTIFICATION_TITLES = {
  'LPIC-1': 'System Administrator',
  'LPIC-2': 'Linux Engineer',
  'LPIC-3': 'Linux Enterprise Professional',
  'OT-DTE': 'Devops Tools Engineer',
  'LE-1': 'Linux Essentials',
};

export const CONTRACT_TYPE = ['fulltime', 'parttime', 'contract', 'volunteer', 'internship'];
export const JOB_SORT = ['relevance', 'newest', 'oldest'];

export const EDUCATION_SLUG = 'education';
export const COMMUNITY_SLUG = 'community';
export const EXPERIENCE_SLUG = 'experience';
