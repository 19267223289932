import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCertifications } from '../../../store/actions/certifications';
import { getScoreboard, getActiveCertifications, getInactiveCertifications } from '../../../store/selectors';
import LpiLabel from './LpiLabel';
import Box from '@material-ui/core/Box';

import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const RequirementsStep = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const score = useSelector(getScoreboard);
  const activeCertifications = useSelector(getActiveCertifications);
  const inactiveCertifications = useSelector(getInactiveCertifications);

  React.useEffect(() => {
    dispatch(fetchCertifications());
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingY={2}>
      {!(
        activeCertifications.length === 0 &&
        inactiveCertifications.length > 0 &&
        score.pdu + score.remaining_pdu >= 20
      ) && (
        <Box
          display="flex"
          flexDirection="row"
          borderRadius="40px"
          alignItems="center"
          paddingRight={4}
          paddingLeft={2}
          bgcolor={
            activeCertifications.length > 0
              ? 'black'
              : activeCertifications.length > 0 && inactiveCertifications.length === 0
              ? 'primary.main'
              : 'text.hint'
          }
          height="80px"
        >
          <LpiLabel />
          <Box marginLeft={2} color="#fff">
            {t('Active')} <br /> {t('Professional')}
            <br /> {t('Certification')}
          </Box>
        </Box>
      )}

      {!(
        activeCertifications.length === 0 &&
        inactiveCertifications.length > 0 &&
        score.pdu + score.remaining_pdu >= 20
      ) &&
        activeCertifications.length === 0 &&
        inactiveCertifications.length > 0 && (
          <Box width="100%" marginY={1} display="flex" flexDirection="row" alignItems="center">
            <Box bgcolor="#333" height="1px" flexGrow={1} />
            <Box marginX={1} fontWeight="bold">
              OR
            </Box>
            <Box bgcolor="#333" height="1px" flexGrow={1} />
          </Box>
        )}

      {activeCertifications.length === 0 && inactiveCertifications.length > 0 && (
        <Box width="100%" display="flex" flexDirection="row" alignItems="center">
          <Box
            display="flex"
            flexDirection="row"
            borderRadius="40px"
            alignItems="center"
            paddingRight={4}
            paddingLeft={2}
            bgcolor={activeCertifications.length === 0 && inactiveCertifications.length > 0 ? 'black' : 'text.hint'}
            height="80px"
          >
            <LpiLabel />
            <Box marginLeft={2} color="#fff">
              {t('Inactive')} <br /> {t('Professional')}
              <br /> {t('Certification')}
            </Box>
          </Box>

          <AddIcon />

          <Box
            display="flex"
            flexDirection="row"
            borderRadius="40px"
            alignItems="center"
            paddingRight={4}
            paddingLeft={2}
            bgcolor={
              activeCertifications.length === 0 && score.pdu + score.remaining_pdu >= 20
                ? 'black'
                : activeCertifications.length === 0 && inactiveCertifications.length > 0
                ? 'text.hint'
                : 'primary.main'
            }
            height="80px"
          >
            <Box
              marginLeft={2}
              color="#fff"
              display="flex"
              alignItems="center"
              flexDirection="column"
              fontWeight="bold"
              fontSize={18}
            >
              20 <br /> {t('PDUS')}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(RequirementsStep);
