import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

class NumberField extends PureComponent {
  handleChange = value => {
    this.props.onChange(value);
  };

  render() {
    return <TextField {...this.props} value={`${this.props.value}`} onChange={this.handleChange} type="number" />;
  }
}

NumberField.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NumberField;
