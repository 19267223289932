import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import api from '../../backend';
import useParamsState from '../useParamsState';

export const QUERY_KEY = 'jobs.offers.search';
export const PAGE_SIZE = 10; // default 25

/* Transform filters object into a valid params object  */
const mapFiltersToParams = filters => {
  const param = {};

  Object.keys(filters).forEach(key => {
    switch (key) {
      case 'favorite':
        if (filters.favorite) param.favorite = true;
        break;

      case 'certificates':
        param.certificates = [];
        filters.certificates.forEach(certificate => param.certificates.push(certificate.id));
        break;

      case 'languages':
        param.languages = [];
        filters.languages.forEach(language => param.languages.push(language.id));
        break;

      case 'skills':
        param.skills = [];
        filters.skills.forEach(skill => param.skills.push(skill.name));
        break;

      case 'contractTypes':
        param.contract_type = filters.contractTypes;
        break;

      case 'country':
        param.country_id = filters.country.id;
        break;

      case 'state':
        param.state_id = filters.state.id;
        break;

      default:
        param[key] = filters[key];
    }
  });

  param.page_size = PAGE_SIZE;

  return param;
};

const usePeopleOfferSearch = (initialFilters = {}) => {
  const [filters, setFilters] = useParamsState(initialFilters);

  const res = useInfiniteQuery(
    [QUERY_KEY, filters],
    ({ pageParam = 0 }) => api.job.offers(mapFiltersToParams({ ...filters, page: pageParam })),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.total_count / PAGE_SIZE > pages.length) return lastPage.page + 1;
      },
    },
  );

  /* Keep previous partners in a single array every new page fetched to prevent re-renders */
  const offers = useMemo(() => {
    if (res.data) {
      return res.data.pages.reduce((acc, page) => {
        page.results.forEach(offer => acc.push(offer));
        return acc;
      }, []);
    } else {
      return [];
    }
  }, [res.dataUpdatedAt]);

  /* Log fetching errors */
  useEffect(() => {
    if (res.error) console.error('Error during request:', res.error);
  }, [res.error]);

  return {
    offers,

    filtersController: {
      filters,
      setFilters,
    },

    pagination: {
      hasNextPage: res.hasNextPage,
      fetchNextPage: res.fetchNextPage,
      isFetchingNextPage: res.isFetchingNextPage,
    },

    ...res,
  };
};

export default usePeopleOfferSearch;
