import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { withTranslation } from 'react-i18next';

class COCForm extends PureComponent {
  render() {
    const { classes, t } = this.props;
    return (
      <Card className={classes.cocContainer}>
        <CardHeader title="Membership Agreement" />
        <CardContent className={classes.textContainer}>
          <Typography variant="h5">Linux Professional Institute Member Agreement (the "Agreement")</Typography>
          <Typography variant="caption" style={{ margin: '16px  0px' }}>
            Version 1.0
          </Typography>
          <Typography variant="body1" style={{ margin: '16px  0px' }}>
            <b>
              NOTE: The membership program is in alpha testing, pending board approval and will be generally available
              later this year.
            </b>
          </Typography>
          Here are the terms and conditions that govern your membership in Linux Professional Institute ("LPI"). By
          virtue of your application and LPI’s acceptance, you acknowledge, for the duration of your membership, that:
          <ol>
            <li className={classes.li}>
              You will comply with all{' '}
              <a href="https://www.lpi.org/policies" rel="noopener noreferrer" target="_blank">
                policies
              </a>
              ,{' '}
              <a href="https://www.lpi.org/bylaws" rel="noopener noreferrer" target="_blank">
                bylaws
              </a>
              ,{' '}
              <a href="https://www.lpi.org/conduct" rel="noopener noreferrer" target="_blank">
                code of conduct
              </a>
              , and procedures of LPI as presently existing and as may be amended from time to time.
            </li>
            <li className={classes.li}>
              You accept that LPI’s Board of Directors or their appointed representative body may, in its sole
              discretion, determine whether your membership is in good standing in accordance with LPI's{' '}
              <a href="https://www.lpi.org/policies" rel="noopener noreferrer" target="_blank">
                policies
              </a>
              ,{' '}
              <a href="https://www.lpi.org/bylaws" rel="noopener noreferrer" target="_blank">
                bylaws
              </a>
              ,{' '}
              <a href="https://www.lpi.org/conduct" rel="noopener noreferrer" target="_blank">
                code of conduct
              </a>
              , and procedures.
            </li>

            <li className={classes.li}>
              Membership is yours alone and may not be transferred, sold or otherwise reassigned. The membership term is
              for one or three years, based on the date you are accepted as a member and payment of dues.
            </li>

            <li className={classes.li}>
              In order to maintain status as an active LPI member and certificate holder, you must either:
              <ol>
                <li className={classes.li}>
                  obtain an eligible LPI certification according to that program’s renewal interval; or
                </li>
                <li className={classes.li}>
                  achieve a sufficient number of Professional Development Units ("PDUs") per three (3) year cycle in
                  accordance with the{' '}
                  <a
                    href="https://www.lpi.org/member/pdu-procedures-and-policies"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    LPI PDU policy
                  </a>
                  , as may be amended from time to time. LPI, in its sole discretion, will determine the nature of
                  activities which qualify for PDUs and the number of PDUs required to maintain status as an active LPI
                  member.
                </li>
              </ol>
            </li>

            <li className={classes.li}>
              If maintaining your membership status through PDUs, you will submit your achievements which qualify for
              PDUs as well as any appropriate evidence as determined by the LPI PDU policy. You commit that your PDU
              submissions accurately and truthfully describe activities done by you alone. If required by LPI, you will
              include appropriate evidence of the PDU-earning activity. All PDU submissions may be subject to audit by
              LPI, which may require you to provide supplemental evidence.
            </li>

            <li className={classes.li}>
              You may submit activities not listed in the LPI PDU Policy for consideration. Acceptance and allocation of
              PDUs for such activities will be handled on a case-by-case basis at the sole discretion of LPI.
            </li>

            <li className={classes.li}>
              You have reviewed the{' '}
              <a href="https://www.lpi.org/conduct" rel="noopener noreferrer" target="_blank">
                LPI Code of Ethics and Professional Conduct (“LPI Code”)
              </a>{' '}
              and agree to abide by its provisions, as may be amended from time to time. You agree that the LPI Board of
              Directors may, in its sole discretion, identify and determine breaches of the LPI Code and assign
              disciplinary remedies to you for such breach, up to and including immediate termination of membership.
            </li>

            <li className={classes.li}>
              You will pay the appropriate member dues according to rates and renewal dates as established by LPI. You
              will be notified in writing of all dues payable at least one month in advance of your renewal date. If you
              do not pay dues within three calendar months of the renewal date, LPI may immediately terminate
              membership.
            </li>

            <li className={classes.li}>
              For the duration of your membership you are given a limited license, without warranty, to use the
              trademarked LPI name, badges and logos appropriate to membership and certifications ("LPI Marks") solely
              for the purpose of identifying your membership in LPI. You acknowledge that LPI is the sole owner of all
              intellectual property related to the LPI Marks. You may not modify the LPI Marks or use them for any
              purpose other than to identify your membership. If you misuse LPI Marks, this will be considered a breach
              of the LPI Code and may result in immediate termination of your membership. Upon termination, cancellation
              or suspension of your membership this permission ends, and you must immediately cease using the LPI Marks.
              You may not use the LPI Marks in any way so as to represent our endorsement or approval of any product or
              service offered by you. You acknowledge that LPI has an absolute right to terminate, cancel, suspend or
              withdraw the license granted to you in this section at any time.
            </li>

            <li className={classes.li}>
              The LPI website and its related software (“the Portal”) is provided to you to help you check your
              membership status, manage your PDUs, and other functions as may be developed. These services are provided
              without warranty of any kind. Except for your own personal information or where explicitly noted
              otherwise, the Portal and its data is protected by copyright and may not be reproduced without prior
              written authorization from LPI.
            </li>

            <li className={classes.li}>
              You will not through your own actions or through the use of any device or hardware disrupt or damage any
              component of the LPI website, including the Portal. You are responsible for maintaining secure passwords
              and will not share your account(s) on the Portal with third parties. All data stored on the Portal is
              subject to the{' '}
              <a href="https://www.lpi.org/policies#Privacy_Statement" rel="noopener noreferrer" target="_blank">
                LPI Privacy Policy
              </a>
              .
            </li>

            <li className={classes.li}>
              In no event shall LPI, its directors, officers, employees, contractors or partners be liable to you or to
              any third party for any damages or losses related to this Agreement or your membership in LPI, regardless
              of the cause of such damages or losses, including if the cause is LPI’s own negligence and even if LPI is
              advised of the possibility of such damages. You will indemnify, defend and hold harmless LPI and any of
              its directors, officers, employees, contractors or partners against any claims or legal action resulting
              from your membership, however caused.
            </li>

            <li className={classes.li}>
              Any notices required to be given by LPI regarding membership, including governance notices such as member
              meetings and elections, shall be done by email to the address listed in your profile in the Portal. Notice
              will be deemed to be given twenty-four (24) hours after the message is sent unless the sending party is
              notified that the email address is invalid. You are responsible for maintaining a valid and accessible
              address in your member profile.
            </li>

            <li className={classes.li}>
              This Agreement shall be interpreted and enforced in accordance with the laws of Ontario, Canada. If any
              part of this Agreement is held or rendered invalid or illegal, the remainder of this Agreement continues
              to apply.
            </li>
          </ol>
        </CardContent>
        <CardActions>
          <Button onClick={this.props.onAccept}>Accept</Button>
        </CardActions>
      </Card>
    );
  }
}

const styles = theme => ({
  cocContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 800,
      margin: '0 auto',
    },
  },
  textContainer: {
    textAlign: 'left',
    borderTop: '1px solid #ededed',
    borderBottom: '1px solid #ededed',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 600,
      overflow: 'auto',
    },
  },
  li: {
    marginTop: 16,
  },
});

COCForm.propTypes = {
  onAccept: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(COCForm));
