import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ActivitiesReducer from './reducers/ActivitiesReducer';
import AuthReducer, { logout } from './reducers/AuthReducer';
import CertificationReducer from './reducers/CertificationsReducer';
import JobsReducer from './reducers/JobsReducer';
import MembershipsReducer from './reducers/MembershipsReducer';
import NotificationsReducer from './reducers/NotificationsReducer';
import SnackReducer from './reducers/SnackReducer';
import PersonalPageReducer from './reducers/PersonalPageReducer';
import LegacyAuthReducer from './reducers/LegacyAuthReducer';
import VouchersReducer from './reducers/VouchersReducer';
import VolunteersReducer from './reducers/VolunteersReducer';
import CouponsReducer from './reducers/CouponsReducer';
import BenefitsReducer from './reducers/BenefitsReducer';
import ShopReducer from './reducers/ShopReducer';
import PartnershipReducer from './reducers/PartnershipReducer';

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    activities: ActivitiesReducer,
    auth: AuthReducer,
    certifications: CertificationReducer,
    jobs: JobsReducer,
    memberships: MembershipsReducer,
    notifications: NotificationsReducer,
    snack: SnackReducer,
    personalPage: PersonalPageReducer,
    legacyAuth: LegacyAuthReducer,
    vouchers: VouchersReducer,
    volunteers: VolunteersReducer,
    coupons: CouponsReducer,
    benefits: BenefitsReducer,
    partnership: PartnershipReducer,
    shop: ShopReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === logout.TRIGGER) {
      state = undefined;
    }

    return appReducer(state, action);
  };

  return rootReducer;
};
