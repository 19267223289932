import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useSkills from '../../../hooks/Jobs/useSkills';

const SkillsSelect = ({ controller: { filters, setFilters } }) => {
  const { status, skills } = useSkills();

  return (
    <Autocomplete
      multiple
      options={skills}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} label="Filter by skill" variant="outlined" />}
      loading={status === 'loading'}
      value={filters.skills || []}
      onChange={(_e, skills) => setFilters({ ...filters, skills })}
    />
  );
};

export default React.memo(SkillsSelect);
