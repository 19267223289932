import { useMutation } from 'react-query';
import api from '../../backend';

const useAttachmentMutations = () => {
  /* Define mutation hooks */
  const createAttachment = useMutation(({ model, id, files }) => {
    const formData = new FormData();
    formData.append('model', model);
    formData.append('res_id', id);
    files.forEach(file => formData.append('ufile', file));

    return api.uploadFile.create(formData);
  });

  return {
    createAttachment,
  };
};

export default useAttachmentMutations;
