import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/selectors';
import RequirementsStep from './Steps/RequirementsStep';
import BasicStep from './Steps/BasicStep';
import Arrow from './Steps/Arrow';

import AgreeIcon from '@material-ui/icons/Description';
import RequestIcon from '@material-ui/icons/Feedback';
import WaitIcon from '@material-ui/icons/AccessTime';
import PayIcon from '@material-ui/icons/CreditCard';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';
import { MEMBERSHIP_LIST, CHECKOUT_ORDER } from '../../navigation/routes';

const STEPS = {
  ineligible: 1,
  eligible: 2,
  pending: 3,
  payment: 4,
  member: 5,
};

const Steps = () => {
  const user = useSelector(getUser);
  const current = STEPS[user.membership_step];
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const request = useSelector(state => state.memberships.request);

  return (
    user.membership_step !== 'member' && (
      <Box
        marginTop={4}
        marginBottom={7}
        display="flex"
        flexDirection={isSmall ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
      >
        <RequirementsStep active={current > 0} />

        <Arrow />

        <BasicStep
          active={current > 1 && user.has_accepted_coc}
          current={current === 2 && !user.has_accepted_coc}
          label="Agree"
          icon={<AgreeIcon fontSize="large" />}
          href={MEMBERSHIP_LIST}
        />

        <Arrow />

        <BasicStep
          active={current > 2}
          current={current === 2 && user.has_accepted_coc}
          label="Request"
          icon={<RequestIcon fontSize="large" />}
          href={MEMBERSHIP_LIST}
        />

        <Arrow />

        <BasicStep active={current > 3} current={current === 3} label="Wait" icon={<WaitIcon fontSize="large" />} />

        <Arrow />

        <BasicStep
          active={current > 4}
          current={current === 4}
          label="Pay"
          icon={<PayIcon fontSize="large" />}
          href={request?.order_id && CHECKOUT_ORDER.replace(':orderId', request.order_id)}
        />
      </Box>
    )
  );
};

export default React.memo(Steps);
