import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { createReferralCoupon } from '../../store/reducers/CouponsReducer';
import { getCreateReferralCouponRequests } from '../../store/selectors';
import { COUPONS } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
// import Box from "@material-ui/core/Box"
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/images/logo-white.png';
import PageBackground from '../../components/App/PageBackground';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 100,
    [theme.breakpoints.up('sm')]: {
      width: 700,
    },
  },
  logoBox: {
    padding: 20,
    backgroundColor: theme.palette.primary.main,
  },
  messageBox: {
    padding: 20,
    backgroundColor: '#fff',
    textAlign: 'center',
  },
}));

const CouponClaimPage = () => {
  const dispatch = useDispatch();
  const requests = useSelector(getCreateReferralCouponRequests);
  const classes = useStyles();

  const { t } = useTranslation();
  const { token } = useParams();

  React.useEffect(() => {
    if (token) {
      dispatch(
        createReferralCoupon({
          referrer_url: document.referrer,
          token: token,
        }),
      );
    }
  }, [token, dispatch]);

  return (
    <PageBackground styles={{ backgroundColor: '#ededed' }}>
      <Grid container justify="center">
        <Grid item>
          <Grid container direction="column" className={classes.container}>
            <Grid item xs={12} className={classes.logoBox}>
              <img src={logo} alt="Linux Professional Institute" />
            </Grid>
            <Grid item xs={12} className={classes.messageBox}>
              {requests.loading ? (
                <Skeleton variant="text" />
              ) : (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    {requests.error ? t('Coupon not found or already taken') : t('Your coupon has been claimed!')}
                  </Typography>
                  {!requests.error && (
                    <Button
                      component={Link}
                      color="secondary"
                      // to={`${COUPONS}/${couponId}`}
                      to={COUPONS}
                    >
                      {t('GO TO COUPONS')}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageBackground>
  );
};

export default React.memo(CouponClaimPage);
