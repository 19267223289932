import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

export const SET = 'Snack.Set';
export const DEL = 'Snack.Del';

export const setMessage = createAction(SET);
export const delMessage = createAction(DEL);

const INITIAL_STATE = {
  message: null,
};

const snackReducer = handleActions(
  {
    [setMessage]: (state, { payload }) =>
      produce(state, draft => {
        draft.message = payload;
      }),
    [delMessage]: state =>
      produce(state, draft => {
        draft.message = null;
      }),
  },
  INITIAL_STATE,
);

export default snackReducer;
