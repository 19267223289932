import React, { useState } from 'react';
import { Box, Typography, Drawer, makeStyles, Toolbar, Grid, Chip, Tooltip, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 250,
  },
  filtersWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Filters = ({ children, filters, getChipLabels, onChipDelete, ...args }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box {...args}>
      <Toolbar>
        <Grid container justify="space-between" wrap="nowrap">
          {/* CHIPS */}
          <Grid item container spacing={1} alignItems="center">
            {Object.keys(filters).map(filterName =>
              getChipLabels(filterName).map(label => (
                <Grid item key={label}>
                  <Chip label={label} onDelete={() => onChipDelete(filterName, label)} />
                </Grid>
              )),
            )}
          </Grid>

          {/* DRAWER BUTTON */}
          <Grid item>
            <Tooltip title={t('Filters')}>
              <IconButton onClick={() => setDrawerOpen(true)} data-testid="drawer-button">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>

      {/* DRAWER */}
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box p={3} className={classes.filtersWrapper}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('Filters')}
            </Typography>
            {children}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  getChipLabels: PropTypes.func.isRequired,
  onChipDelete: PropTypes.func.isRequired,
};

export default Filters;
