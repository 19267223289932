import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';

const RejectActivityTable = ({ activities, pagination }) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <React.Fragment>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Title')}</TableCell>
            <TableCell>{t('Reject Date')}</TableCell>
            <TableCell>{t('Reason')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map(activity => (
            <RejectActivityTableRow key={activity.id} activity={activity} />
          ))}
        </TableBody>
      </Table>
      {pagination ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={activities.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </React.Fragment>
  );
};
RejectActivityTable.propTypes = {
  activities: PropTypes.array,
};

const RejectActivityTableRow = ({ activity }) => {
  return (
    <TableRow>
      <TableCell>{activity.name}</TableCell>
      <TableCell>{formatDate(activity.reject_date)}</TableCell>
      <TableCell>{activity.reject_reason}</TableCell>
    </TableRow>
  );
};
RejectActivityTableRow.propTypes = {
  activity: PropTypes.object,
};

export default React.memo(RejectActivityTable);
