import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';

import GroupListItem from '../../components/Certifications/GroupListItem';

class GroupList extends PureComponent {
  render() {
    const { scoreboard, certifications } = this.props;
    return (
      <Grid container spacing={4}>
        <GroupListItem scoreboard={scoreboard} certifications={certifications} />
      </Grid>
    );
  }
}

export default GroupList;
