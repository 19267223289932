import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useCertificates from '../../../hooks/useCertificates';

const CertificateSelect = ({ controller: { filters, setFilters } }) => {
  const { status, certificates } = useCertificates();

  return (
    <Autocomplete
      multiple
      options={certificates}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} label="Filter by certification" variant="outlined" />}
      loading={status === 'loading'}
      value={filters.certificates || []}
      onChange={(_e, certificates) => setFilters({ ...filters, certificates })}
    />
  );
};

export default React.memo(CertificateSelect);
