import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from '../../../navigation/routes';

import PageTitle from '../../App/PageTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const PaymentStep = () => {
  const { t } = useTranslation();
  const request = useSelector(state => state.memberships.request);

  return (
    <>
      <PageTitle title={t('Your membership request was accepted!')} />
      <Typography variant="body1">
        {t('You can now proceed to checkout phase to start your LPI Membership period.')}
      </Typography>
      <br />
      {request && request.order_id ? (
        <Button
          component={Link}
          to={routes.CHECKOUT_ORDER.replace(':orderId', request.order_id)}
          variant="contained"
          color="primary"
        >
          {t('PAY MEMBERSHIP DUES')}
        </Button>
      ) : null}
    </>
  );
};

export default React.memo(PaymentStep);
