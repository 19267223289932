import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  item: {
    margin: '2px 5px',
    width: 'calc(100% - 10px)',
    padding: '3px 16px',
    borderRadius: '5px',
    '& svg': {
      color: '#CFD8DC',
    },
    '&:hover, &:focus': {
      background: '#455A64',
    },
    '&.active': {
      background: '#455A64',
      color: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    width: '44px',
    minWidth: '44px',
  },
}));

const NavButton = ({ label, icon, testid, ...props }) => {
  const classes = useStyles();

  return (
    <ListItem exact button className={classes.item} component={NavLink} data-testid={testid} {...props}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

NavButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  testid: PropTypes.string,
};

export default React.memo(NavButton);
