import React, { memo } from 'react';
import { red } from '@material-ui/core/colors';
import { Grid, Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import usePayments from '../../../hooks/Shop/usePayments';
import { CHECKOUT_FAILURE, CHECKOUT_SUCCESS } from '../../../navigation/routes';

const FreeOrderCheckout = ({ cart }) => {
  const { t } = useTranslation();
  const { status, confirmFreeOrder, mutationStatus } = usePayments(cart.id);
  const history = useHistory();

  const handleFreeOrderConfirm = () => {
    confirmFreeOrder()
      .then(() => {
        history.push([CHECKOUT_SUCCESS, cart.id].join('/'));
      })
      .catch(error => {
        console.error(error);
        history.push([CHECKOUT_FAILURE, cart.id].join('/'));
      });
  };

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Box display="flex" justifyContent="center" height="100%" alignItems="center">
          <CircularProgress />
        </Box>
      );

    case 'error':
      return (
        <Grid container direction="column">
          <Typography style={{ color: red[500] }} align="center" gutterBottom variant="h6">
            {t('Error loading this order')}
          </Typography>
          <Typography align="center">{t('Try again')}</Typography>
        </Grid>
      );

    case 'success':
      return (
        <Box my={2}>
          <Typography gutterBottom>{t('Confirm free order')}</Typography>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleFreeOrderConfirm}
                disabled={mutationStatus.confirmFreeOrder === 'loading'}
              >
                {mutationStatus.confirmFreeOrder === 'loading' ? (
                  <CircularProgress color="secondary" size={30} />
                ) : (
                  t('Confirm')
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      );
  }
};

export default memo(FreeOrderCheckout);
