import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorderOutlined';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Divider, Link } from '@material-ui/core';

import usePeopleOfferSearchMutations from '../../hooks/Jobs/usePeopleOfferSearchMutations';
import OffersApplyModal from './OffersApplyModal';
import LabelledValue from '../Common/LabelledValue';

import { getTranslatedJobType } from '../../utils/getTranslatedEnum';
import joinComponents from '../../utils/joinComponents';
import useApplicationsMutations from '../../hooks/Jobs/useApplicationsMutations';
import { setMessage } from '../../store/reducers/SnackReducer';
import { useDispatch } from 'react-redux';
import useApplications from '../../hooks/Jobs/useApplications';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

const OffersDetail = ({ offer, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const { addToFavorites, removeFromFavorites } = usePeopleOfferSearchMutations();
  const { status, applications } = useApplications();
  const { apply } = useApplicationsMutations();
  const isUp880h = useMediaQuery('(min-height:880px)');
  const isFavoriteMutating = addToFavorites.status === 'loading' || removeFromFavorites.status === 'loading';
  const application = useMemo(() => applications.find(app => app.job_id === offer.id), [applications, offer.id]);

  const handleFavoriteClick = useCallback(
    event => {
      event.stopPropagation();
      if (offer.favorite) {
        removeFromFavorites.mutateAsync(offer.id);
      } else {
        addToFavorites.mutateAsync(offer.id);
      }
    },
    [offer],
  );

  const [currentTab, setTab] = React.useState(0);
  const classes = useStyles();

  const handleApplyClick = React.useCallback(() => {
    if (!!offer.external_url) {
      window.open(offer.external_url, '_blank');
    } else {
      if (!application) {
        setModalOpen(true);
      }
    }
  }, [application]);

  const handleApply = values => {
    apply
      .mutateAsync({ offerId: offer.id, payload: values })
      .then(() => {
        dispatch(setMessage(t('Your application has been submitted')));
      })
      .catch(error => {
        console.error(error);
        dispatch(setMessage(t('An error occurred during the request')));
      })
      .finally(() => {
        setModalOpen(false);
      });
  };

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Card variant="outlined">
          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="20vh">
            <CircularProgress />
          </Box>
        </Card>
      );

    case 'error':
      return (
        <Card variant="outlined">
          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="20vh">
            <Typography>{t('An error occurred during the request')}</Typography>
          </Box>
        </Card>
      );

    case 'success':
      return (
        <Card variant="outlined">
          <CardHeader
            classes={{
              title: classes.title,
              subheader: classes.subheader,
            }}
            title={t('JOB OFFER')}
            subheader={
              <Box display="flex" flexDirection="column">
                <Typography variant="h5" color="textPrimary">
                  {offer.name}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {[offer.country?.name, offer.state?.name, offer.city].filter(el => !!el).join(', ')}
                </Typography>
              </Box>
            }
            titleTypographyProps={{ variant: 'button', gutterBottom: true }}
            subheaderTypographyProps={{
              variant: 'h5',
              color: 'textPrimary',
            }}
            action={
              <IconButton onClick={() => onClose(null)}>
                <Close />
              </IconButton>
            }
          />
          <CardContent>
            <Tabs
              value={currentTab}
              onChange={(event, tab) => {
                setTab(tab);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={t('DESCRIPTION')} />
              <Tab label={t('DETAILs')} />
            </Tabs>

            <div style={{ overflow: 'auto', marginTop: 5, height: isUp880h ? '40vh' : '30vh' }}>
              <TabPanel value={currentTab} index={0}>
                <LabelledValue label={t('Job description')}>
                  {offer.description ? joinComponents(offer.description.split('\n'), <br />, true) : ''}
                </LabelledValue>

                {!!offer.external_url && (
                  <LabelledValue label={t('URL to full post')}>
                    <Link color="textPrimary" href={offer.external_url}>
                      {offer.external_url}
                    </Link>
                  </LabelledValue>
                )}

                {application && (
                  <>
                    <Box my={2}>
                      <Divider />
                    </Box>
                    {application.about_applicant && (
                      <LabelledValue label={t('Your message')}>
                        {joinComponents(application.about_applicant.split('\n'), <br />, true)}
                      </LabelledValue>
                    )}

                    <LabelledValue label={t('Application date')}>
                      {new Date(application.create_date).toLocaleDateString()}
                    </LabelledValue>

                    {!!application.resume_link && (
                      <LabelledValue label={t('Resume link')}>{application.resume_link}</LabelledValue>
                    )}

                    {!!application.resume_filename && (
                      <LabelledValue label={t('Resume filename')}>{application.resume_filename}</LabelledValue>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                <Grid container>
                  {/* LOCATION + WORKPLACE */}
                  {(!!offer.country ||
                    !!offer.state ||
                    !!offer.city ||
                    offer.remote ||
                    offer.remote_international ||
                    offer.inplace) && (
                    <Grid item xs={12}>
                      <LabelledValue label={t('Location')}>
                        {joinComponents(
                          [
                            (!!offer.country || !!offer.city) && (
                              <Typography display="inline">
                                {[offer.country?.name, offer.state?.name, offer.city].filter(el => !!el).join(', ')}
                              </Typography>
                            ),

                            !!offer.remote && <Typography display="inline">{t('Remote')}</Typography>,

                            !!offer.remote_international && (
                              <Typography display="inline">{t('Remote international')}</Typography>
                            ),

                            !!offer.inplace && <Typography display="inline">{t('In office')}</Typography>,
                          ],
                          <> | </>,
                        )}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* LANGUAGES */}
                  {!!offer.languages?.length && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Required languages')}>
                        {offer.languages.map(language => language.name).join(', ')}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* ORGANIZATION DETAILS */}
                  {(!!offer.organization || !!offer.organization_address) && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Organization')}>
                        {joinComponents([offer.organization, offer.organization_address], <br />)}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* ORGANIZATION CONTACTS */}
                  {(!!offer.organization_email || !!offer.organization_website) && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Contacts')}>
                        {joinComponents(
                          [
                            !!offer.organization_email && (
                              <Link color="textPrimary" href={'mailto:' + offer.organization_email}>
                                {offer.organization_email}
                              </Link>
                            ),
                            !!offer.organization_website && (
                              <Link color="textPrimary" href={offer.organization_website}>
                                {offer.organization_website}
                              </Link>
                            ),
                          ],
                          <br />,
                        )}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* CERTIFICATES */}
                  {!!offer.certificates?.length && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Required Certifications')}>
                        {offer.certificates.map(certificate => certificate.name).join(', ')}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* SKILLS */}
                  {!!offer.skills?.length && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Additional Skills')}>
                        {offer.skills.map(skill => skill.name).join(', ')}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* CONTRACT TYPE */}
                  {!!offer.contract_type && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Employment type')}>
                        {getTranslatedJobType(offer.contract_type, t)}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* SALARY */}
                  {!!offer.salary && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Salary')}>{offer.salary}</LabelledValue>
                    </Grid>
                  )}

                  {/* PUBLICATION START DATE */}
                  {!!offer.publish_date && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Publication date')}>
                        {new Date(offer.publish_date).toLocaleDateString()}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* PUBLICATION END DATE */}
                  {!!offer.close_date && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Expiring date')}>
                        {new Date(offer.close_date).toLocaleDateString()}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* JOB START DATE */}
                  {!!offer.job_start_date && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Job start date')}>
                        {new Date(offer.job_start_date).toLocaleDateString()}
                      </LabelledValue>
                    </Grid>
                  )}

                  {/* JOB END DATE */}
                  {!!offer.job_end_date && (
                    <Grid item xs={6}>
                      <LabelledValue label={t('Job end date')}>
                        {new Date(offer.job_end_date).toLocaleDateString()}
                      </LabelledValue>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </div>
          </CardContent>

          <CardActions disableSpacing classes={{ root: classes.cardActions }}>
            <Box mr={'auto'}>
              <Button onClick={handleApplyClick} color="secondary" disabled={application}>
                {application ? t('Already applied') : t('Apply')}
              </Button>
            </Box>
            <Box ml={'auto'}>
              {(() => {
                if (isFavoriteMutating)
                  return (
                    <IconButton>
                      <CircularProgress size={25} color="primary" />{' '}
                    </IconButton>
                  );

                if (offer.favorite) {
                  return (
                    <IconButton aria-label="RemoveFavorite" color="primary" onClick={handleFavoriteClick}>
                      <StarIcon />
                    </IconButton>
                  );
                } else {
                  return (
                    <IconButton aria-label="AddFavorite" color="default" onClick={handleFavoriteClick}>
                      <StarOutlineIcon />
                    </IconButton>
                  );
                }
              })()}
            </Box>
          </CardActions>

          <OffersApplyModal
            open={modalOpen}
            jobName={offer.name}
            handleClose={() => setModalOpen(false)}
            handleSubmit={handleApply}
            isLoading={apply.isLoading}
          />
        </Card>
      );
  }
};

export default React.memo(OffersDetail);
