import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchOpportunities } from '../../store/reducers/VolunteersReducer';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import Layout from '../../components/App/Layout';
import { useAppliedOpportunities, useOpportunities, usePrograms } from '../../hooks/Volunteers';
import OpportunitiesTable from '../../components/Volunteers/OpportunitiesTable';
import LoadingState from '../../components/Common/LoadingState';
import EmptyState from '../../components/Common/EmptyState';
import Filters from '../../components/Common/Filters';
import { useFilters } from '../../store/hooks/filters';
import { fi } from 'date-fns/locale';

const OpportunitiesPage = () => {
  const { t } = useTranslation();

  const { opportunities, isLoading } = useOpportunities();
  const { applications } = useAppliedOpportunities();
  const [shownItems, setShownItems] = React.useState([]);

  const filters = useFilters();

  React.useEffect(() => {
    let items = opportunities;
    if (filters.current.program_id) {
      items = items.filter(item => item.program == filters.current.program_id);
    }
    if (filters.current.joined) {
      items = items.filter(item => applications.find(application => application.opportunity.id == item.id));
    }
    setShownItems(items);
  }, [opportunities, applications, filters]);

  return (
    <Layout title={t('Opportunities')}>
      <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1" color="textSecondary">
          {t('Available opportunities')}
        </Typography>
      </Box>

      <Box mt={4} px={2}>
        {isLoading && <LoadingState />}
        {!isLoading && !opportunities && <EmptyState />}
        {!isLoading && (
          <>
            <OpportunityFilters controller={filters} />
            <OpportunitiesTable
              rows={shownItems}
              // program={program}
              // item={item}
              // onCloseClick={handleCloseDetail}
            />
          </>
        )}
      </Box>
    </Layout>
  );
};

const OpportunityFilters = ({ controller }) => {
  const { t } = useTranslation();
  const { programs } = usePrograms();

  return (
    <Filters
      controller={controller}
      chips={{
        joined: () => t('Only already applied'),
        program_id: program => t('Program: ') + (programs.find(p => p.id == program)?.name || t('Loading')),
      }}
      renderFilters={(current, setFilter) => (
        <>
          <FormControlLabel
            variant="outlined"
            control={
              <Checkbox
                color="primary"
                name="joined_filter"
                onChange={(event, value) => {
                  setFilter('joined', value ? true : '');
                }}
                checked={current.joined === undefined ? false : current.joined}
              />
            }
            label={t('Show only joined')}
          />

          <Select
            variant="outlined"
            fullWidth
            value={current.program_id}
            onChange={event => setFilter('program_id', event.target.value)}
          >
            <MenuItem value="">{t('All programs')}</MenuItem>
            {programs.map(program => (
              <MenuItem key={program.id} value={program.id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    />
  );
};

export default React.memo(OpportunitiesPage);
