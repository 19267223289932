import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCertifications } from '../actions/certifications';
import { getGroupedCertifications, getCertificationsCount } from '../selectors';

export const useGroupedCertifications = () => {
  const dispatch = useDispatch();

  const certificationCount = useSelector(getCertificationsCount);
  const groupedCertifications = useSelector(getGroupedCertifications);

  React.useEffect(() => {
    dispatch(fetchCertifications());
  }, [dispatch]);

  return { groupedCertifications, certificationCount };
};
