import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Snackbar from '../Common/Snackbar';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('sm')]: {
      marginTop: '30px',
    },
  },
}));

const Layout = ({ title, actions, back, backPath, children }) => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <CssBaseline />
      <Navbar
        back={back}
        backPath={backPath}
        actions={actions}
        onMenuClicked={() => setSidebarOpen(!sidebarOpen)}
        title={title}
      />
      <Sidebar onClose={() => setSidebarOpen(!sidebarOpen)} open={sidebarOpen} />

      <div className={classes.toolbar} />
      <div className={classes.content}>{children}</div>
      <Snackbar />
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
};

export default React.memo(Layout);
