import { useMutation, useQueryClient } from 'react-query';
import api from '../../backend';
import { QUERY_KEY } from './useSavedFilters';

const usePeopleOfferSearchMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const createSavedFilter = useMutation(({ filters, name }) => api.savedFilters.create({ name, domain: filters }), {
    onSuccess: invalidateQuery,
  });
  const deleteSavedFilter = useMutation(id => api.savedFilters.delete(id), {
    onSuccess: invalidateQuery,
  });

  return {
    createSavedFilter,
    deleteSavedFilter,
  };
};

export default usePeopleOfferSearchMutations;
