import React from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
`;

const BasicStep = ({ active, current, icon, label, href }) => {
  const history = useHistory();

  return (
    <Box display="flex" paddingY={2} flexDirection="column" alignItems="center">
      {icon && (
        <IconBox
          bgcolor={active ? 'black' : current ? 'primary.main' : 'text.hint'}
          color="white"
          borderRadius="50%"
          style={{ cursor: current && !!href ? 'pointer' : 'default' }}
          onClick={() => current && !!href && history.push(href)}
        >
          {icon}
        </IconBox>
      )}
      <Box fontWeight="bold" marginTop={1}>
        {label}
      </Box>
    </Box>
  );
};

export default React.memo(BasicStep);
