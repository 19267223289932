import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOffers, fetchFavourites, fetchAlerts } from '../reducers/JobsReducer';
import { getJobOffers, getJobAlerts, getJobFavourites } from '../selectors';

const useFavourites = () => {
  const dispatch = useDispatch();

  const favouriteIds = useSelector(getJobFavourites);
  const offers = useSelector(getJobOffers);

  const [favourites, setFavourites] = React.useState({});

  React.useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchFavourites());
  }, [dispatch]);

  React.useEffect(() => {
    setFavourites(
      Object.keys(offers)
        .filter(key => favouriteIds.includes(parseInt(key)))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: offers[key],
          };
        }, {}),
    );
  }, [favouriteIds, offers]);

  return { favourites };
};

const useAlerts = () => {
  const dispatch = useDispatch();

  const alertNestedIds = useSelector(getJobAlerts);
  const offers = useSelector(getJobOffers);

  const [alertIds, setAlertIds] = React.useState([]);
  const [alerts, setAlerts] = React.useState({});

  React.useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchAlerts());
  }, [dispatch]);

  React.useEffect(() => {
    setAlerts(
      Object.keys(offers)
        .filter(key => alertIds.includes(parseInt(key)))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: offers[key],
          };
        }, {}),
    );
  }, [alertIds, offers]);

  React.useEffect(() => {
    setAlertIds([
      ...new Set(
        (alertNestedIds || []).reduce((acc, cur) => {
          return [...acc, ...cur['jobs_ids']];
        }, []),
      ),
    ]);
  }, [alertNestedIds]);

  return { alerts };
};

export { useFavourites, useAlerts };
