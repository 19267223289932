import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@material-ui/core';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <Select native onChange={e => changeLanguage(e.target.value)} value={language}>
      <option value="en">English</option>
      <option value="de">Deutsch</option>
      <option value="it">Italiano</option>
      <option value="es">Español</option>
      <option value="fr">Français</option>
      <option value="jp">日本語</option>
    </Select>
  );
};

export default LanguageSwitcher;
