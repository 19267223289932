import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import CopyToClipboard from '../Common/CopyToClipboard';
import LabelledValue from '../Common/LabelledValue';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
});

const VouchersDetail = ({ item, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
          }}
          title={t('VOUCHER')}
          subheader={
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h5" color="textPrimary">
                {item.product_id_name}
              </Typography>
              <CopyToClipboard
                title={t('Copied to clipboard!')}
                leaveDelay={1500}
                render={copy => (
                  <Link
                    href="#"
                    color="secondary"
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      copy(item.code);
                    }}
                  >
                    {item.code}
                  </Link>
                )}
              />
            </Box>
          }
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <IconButton onClick={props.onCloseClick}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <LabelledValue label={t('Buyer')}>{item.buyer_id_name}</LabelledValue>
          <LabelledValue label={t('Assigned To')}>{item.assigned_to_name}</LabelledValue>
          <LabelledValue label={t('Expiring Date')}>{item.expiration_date}</LabelledValue>
        </CardContent>
      </Card>
    )
  );
};

export default React.memo(VouchersDetail);
