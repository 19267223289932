export const FETCH_MEMEBERSHIP_PRODUCTS = 'Memberships.Products.Fetch';
export const FETCH_MEMEBERSHIP_PRODUCTS_SUCCESS = 'Memberships.Products.Fetch.Success';
export const FETCH_MEMEBERSHIP_PRODUCTS_FAILURE = 'Memberships.Products.Fetch.Failure';

export const CREATE_MEMEBERSHIP_REQUEST = 'Memberships.Request.Create';
export const CREATE_MEMEBERSHIP_REQUEST_SUCCESS = 'Memberships.Request.Create.Success';
export const CREATE_MEMEBERSHIP_REQUEST_FAILURE = 'Memberships.Request.Create.Failure';

export const FETCH_MEMEBERSHIP_REQUEST = 'Memberships.Request.Fetch';
export const FETCH_MEMEBERSHIP_REQUEST_SUCCESS = 'Memberships.Request.Fetch.Success';
export const FETCH_MEMEBERSHIP_REQUEST_FAILURE = 'Memberships.Request.Fetch.Failure';

export const FETCH_MEMEBERSHIP_RULES = 'Memberships.Rules.Fetch';
export const FETCH_MEMEBERSHIP_RULES_SUCCESS = 'Memberships.Rules.Fetch.Success';
export const FETCH_MEMEBERSHIP_RULES_FAILURE = 'Memberships.Rules.Fetch.Failure';
