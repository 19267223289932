import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembershipProducts } from '../../store/actions/memberships';

import { makeStyles } from '@material-ui/styles';
import Layout from '../../components/App/Layout';
import IneligibleStep from '../../components/Memberships/Steps/IneligibleStep';
import COCStep from '../../components/Memberships/Steps/COCStep';
import EligibleStep from '../../components/Memberships/Steps/EligibleStep';
import PendingStep from '../../components/Memberships/Steps/PendingStep';
import PaymentStep from '../../components/Memberships/Steps/PaymentStep';
import MemberStep from '../../components/Memberships/Steps/MemberStep';
import RenewStep from '../../components/Memberships/Steps/RenewStep';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const MembershipsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchMembershipProducts());
  }, [dispatch]);

  const renderStep = useCallback(() => {
    switch (user.membership_step) {
      case 'renewing':
        return <RenewStep renewing />;
      case 'expired':
        return <RenewStep renewal_order={user.membership_renewal_order_id} />;
      case 'ineligible':
        return <IneligibleStep />;
      case 'eligible':
        return user.has_accepted_coc ? <EligibleStep /> : <COCStep />;
      case 'pending':
        return <PendingStep />;
      case 'payment':
        return <PaymentStep />;
      case 'member':
        return <MemberStep />;
      default:
        return <IneligibleStep />;
    }
  }, [user]);

  return (
    <Layout title={t('Memberships')}>
      <Box mt={4} px={2}>
        <div className={classes.mainContent}>{renderStep()}</div>
      </Box>
    </Layout>
  );
};

const useStyles = makeStyles({
  mainContent: {
    textAlign: 'center',
    fontSize: 18,
  },
});

export default React.memo(MembershipsPage);
