import * as types from './types';

export const fetchActivity = activityId => ({
  type: types.FETCH_ACTIVITY,
  payload: activityId,
});

export const fetchActivitySuccess = activity => ({
  type: types.FETCH_ACTIVITY_SUCCESS,
  payload: activity,
});

export const fetchActivityFailure = error => ({
  type: types.FETCH_ACTIVITY_FAILURE,
  payload: error,
});

export const fetchActivities = () => ({
  type: types.FETCH_ACTIVITIES,
});

export const fetchActivitiesSuccess = activities => ({
  type: types.FETCH_ACTIVITIES_SUCCESS,
  payload: activities,
});

export const fetchActivitiesFailure = error => ({
  type: types.FETCH_ACTIVITIES_FAILURE,
  payload: error,
});

export const createActivity = (data, next) => ({
  type: types.CREATE_ACTIVITY,
  payload: data,
  meta: {
    next,
  },
});

export const createActivitySuccess = activity => ({
  type: types.CREATE_ACTIVITY_SUCCESS,
  payload: activity,
});

export const createActivityFailure = error => ({
  type: types.CREATE_ACTIVITY_FAILURE,
  payload: error,
});

export const editActivity = (id, data, next) => ({
  type: types.EDIT_ACTIVITY,
  payload: { id, data },
  meta: {
    next,
  },
});

export const editActivitySuccess = activity => ({
  type: types.EDIT_ACTIVITY_SUCCESS,
  payload: activity,
});

export const editActivityFailure = error => ({
  type: types.EDIT_ACTIVITY_FAILURE,
  payload: error,
});

export const updateActivity = data => ({
  type: types.UPDATE_ACTIVITY,
  payload: data,
});

export const updateActivitySuccess = activity => ({
  type: types.UPDATE_ACTIVITY_SUCCESS,
  payload: activity,
});

export const updateActivityFailure = error => ({
  type: types.UPDATE_ACTIVITY_FAILURE,
  payload: error,
});

export const claimActivity = (activityId, groupId) => ({
  type: types.CLAIM_ACTIVITY,
  payload: {
    activity_id: activityId,
    certification_group_id: groupId,
  },
});

export const claimActivitySuccess = activity => ({
  type: types.CLAIM_ACTIVITY_SUCCESS,
  payload: activity,
});

export const claimActivityFailure = error => ({
  type: types.CLAIM_ACTIVITY_FAILURE,
  payload: error,
});

export const fetchActivitiesCategories = () => ({
  type: types.FETCH_ACTIVITY_CATEGORIES,
});

export const fetchActivitiesCategoriesSuccess = categories => ({
  type: types.FETCH_ACTIVITY_CATEGORIES_SUCCESS,
  payload: categories,
});

export const fetchActivitiesCategoriesFailure = error => ({
  type: types.FETCH_ACTIVITY_CATEGORIES_FAILURE,
  payload: error,
});

export const openClaimDialog = activity => ({
  type: types.OPEN_CLAIM_DIALOG,
  payload: activity,
});

export const dismissClaimDialog = activity => ({
  type: types.DISMISS_CLAIM_DIALOG,
  payload: activity,
});
