import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Layout from '../../components/App/Layout';
import LabelledValue from '../../components/Common/LabelledValue';
import Table from '../../components/Common/Table';
import Column from '../../components/Common/TableColumn';
import { getTranslatedLocationStatus } from '../../utils/getTranslatedEnum';
import useLpiLocations from '../../hooks/Locations/useLpiLocations';

const useStyles = makeStyles({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

const LocationDetails = ({ item, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  if (!item) return null;

  return (
    <Card variant="outlined">
      <CardHeader
        classes={{
          title: classes.title,
          subheader: classes.subheader,
        }}
        title={t('Location')}
        subheader={
          <Box display="flex" flexDirection="column">
            <Typography variant="h5" color="textPrimary">
              {item.name}
            </Typography>
          </Box>
        }
        titleTypographyProps={{ variant: 'button', gutterBottom: true }}
        subheaderTypographyProps={{
          variant: 'h5',
          color: 'textPrimary',
        }}
        action={
          <IconButton onClick={props.onCloseClick}>
            <Close />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          {!!item.lpi_partner && (
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} md={6}>
                <LabelledValue label={t('Partner')}>{item.lpi_partner.name}</LabelledValue>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="flex-end">
                  {item.lpi_partner.image_url && (
                    <img
                      style={{ maxWidth: '100%', maxHeight: 100, padding: 10 }}
                      src={item.lpi_partner.image_url}
                      alt="partner logo"
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} style={{ overflow: 'hidden' }}>
                {item.lpi_partner.email && <LabelledValue label={t('Email')}>{item.lpi_partner.email}</LabelledValue>}
                {item.lpi_partner.phone && <LabelledValue label={t('Phone')}>{item.lpi_partner.phone}</LabelledValue>}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6">{t('Location info')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {item.name && <LabelledValue label={t('Name')}>{item.name}</LabelledValue>}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.status && (
              <LabelledValue label={t('Status')}>{getTranslatedLocationStatus(item.status, t)}</LabelledValue>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.country && <LabelledValue label={t('Country')}>{item.country.name}</LabelledValue>}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.state && <LabelledValue label={t('State')}>{item.state.name}</LabelledValue>}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.city && <LabelledValue label={t('City')}>{item.city}</LabelledValue>}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.address && <LabelledValue label={t('Address')}>{item.address}</LabelledValue>}
          </Grid>
          <Grid item xs={12} md={6}>
            {item.zip && <LabelledValue label={t('Zip')}>{item.zip}</LabelledValue>}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing classes={{ root: classes.cardActions }}>
        {item.status === 'site-admin-review' && (
          <Button
            style={{ marginRight: 6 }}
            variant="contained"
            color="primary"
            onClick={() => history.push('/location/' + item.id)}
          >
            {t('Approve')}
          </Button>
        )}
        <Button variant="contained" onClick={props.onCloseClick}>
          {t('Close')}
        </Button>
      </CardActions>
    </Card>
  );
};

const LocationsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const browserLocation = useLocation();
  const history = useHistory();

  const { locations, status } = useLpiLocations();

  const id = useRouteMatch({
    path: '/locations/:id',
  })?.params?.id;

  const handleRowClick = React.useCallback(
    id => {
      history.push({
        pathname: id ? '/locations/' + id : '/locations',
        search: browserLocation.search,
      });
    },
    [history, browserLocation],
  );

  return (
    <Layout title="Locations">
      <Grid item xs={12} container spacing={0} display="flex">
        <Hidden smDown={!!id}>
          <Grid item xs={12} md={id ? 8 : 12}>
            <Box display="flex" justifyContent="space-between" pl={2} mx={2} mt={4} flexDirection="row">
              <Typography variant="subtitle1" color="textSecondary">
                {t('Manage your locations')}
              </Typography>
              {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
            </Box>

            <Card variant="outlined" style={{ margin: '32px 9px 32px 18px ' }}>
              <CardContent classes={{ root: classes.customCardRoot }}>
                {(() => {
                  switch (status) {
                    case 'idle':
                    case 'loading':
                      return (
                        <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Box>
                      );
                    case 'error':
                      return (
                        <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                          <Typography>{t('Cannot find this location')}</Typography>
                        </Box>
                      );
                    case 'success':
                      return (
                        <Table rows={locations} onRowClick={handleRowClick}>
                          <Column label={t('Name')} renderDepends={item => item.name} />
                          <Column
                            label={t('Status')}
                            renderDepends={item =>
                              item.status === 'site-admin-review' ? (
                                <Typography color="error">{getTranslatedLocationStatus(item.status, t)}</Typography>
                              ) : (
                                getTranslatedLocationStatus(item.status, t)
                              )
                            }
                          />
                          <Column label={t('Partner')} renderDepends={item => item.lpi_partner?.name} />
                          <Column label={t('Country')} renderDepends={item => item.country?.name} />
                          <Column label={t('City')} renderDepends={item => item.city} />
                        </Table>
                      );
                  }
                })()}
              </CardContent>
            </Card>
          </Grid>
        </Hidden>
        <Switch>
          <Route path={'/locations/:id'}>
            <Grid item xs={12} md={4}>
              <Box style={{ margin: '32px 18px 32px 9px' }}>
                <LocationDetails
                  item={locations.find(location => location.id == id)}
                  onCloseClick={() => handleRowClick()}
                />
              </Box>
            </Grid>
          </Route>
        </Switch>
      </Grid>
    </Layout>
  );
};

export default React.memo(LocationsPage);
