import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../backend/index';

export const QUERY_KEY = 'vouchers';

const useVouchers = () => {
  const req = useQuery([QUERY_KEY], () => api.vouchers.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    vouchers: req.data?.results || [],
    ...req,
  };
};

export default useVouchers;
