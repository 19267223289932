import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePersonalPageInfo } from '../../store/hooks/personalPage';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Label from '../../components/Common/Label';

import logo from '../../assets/images/logo.png';
import membersLogo from '../../assets/images/lpi.members.logo.svg';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3),
  },
  card: {
    width: '100%',
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      width: 70,
      height: 70,
    },
    [theme.breakpoints.up('xs')]: {
      height: '100%',
    },
    objectFit: 'contain',
    objectPosition: 'center',
  },

  cardHeader: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.dark,
    alignItems: 'flex-start',
  },
  cardContent: {
    padding: 0,
  },
  certLogoContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: theme.spacing() * 2,
    },

    display: 'flex',
    alignItems: 'center',
  },
  certLogo: {
    [theme.breakpoints.down('sm')]: {
      width: 220,
      alignSelf: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      height: 80,
      width: 80,
    },
    objectFit: 'contain',
    objectPosition: 'center',
  },
  certTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  certTitle: {
    flexGrow: 2,
  },
  overTitle: {
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  trainer: {
    fontWeight: 'bold',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const CertificationItem = ({ certification, index, info }) => {
  const isExpired = cert => new Date(cert.inactive_date) < new Date() && cert.expires;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment key={index}>
      <Box px={4} py={3}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={3} className={classes.certLogoContainer}>
            <img src={certification.cert_image} className={classes.certLogo} alt={certification.code} />
          </Grid>
          <Grid item xs={9}>
            <div className={classes.certTitleContainer}>
              <Typography className={classes.certTitle} variant="subtitle2">
                {certification.name}
              </Typography>
              <Label type={isExpired(certification) ? 'info' : 'success'} size="small">
                {isExpired(certification) ? t('Inactive') : t('Active')}
              </Label>
            </div>
            <Typography variant="caption" color="textSecondary">
              {t('Date of certification {{date}}', {
                date: new Date(certification.date).toLocaleDateString(),
              })}
            </Typography>
            {certification.expires && (
              <Typography variant="caption" color="textSecondary" display="block">
                {t('Expiration {{date}}', {
                  date: new Date(certification.inactive_date).toLocaleDateString(),
                })}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {index !== info.certifications.length - 1 && <Divider />}
    </React.Fragment>
  );
};

const PersonalPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { code } = useParams();
  const { info } = usePersonalPageInfo(code);

  const locName = item => [item.city, item.state, item.country].filter(chunk => chunk).join(', ');

  return (
    info && (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm" className={classes.content}>
          <Grid container direction="column" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <Box height={60}>
                <img src={logo} className={classes.logo} alt={t('Linux Professional Institute')} />
              </Box>
            </Grid>
            <Grid item className={classes.card}>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      variant="subtitle1"
                      classes={{
                        root: classes.overTitle,
                      }}
                      gutterBottom
                    >
                      {t('CERTIFICATION PROFILE')}
                    </Typography>
                  }
                  subheader={
                    <div>
                      <Typography variant="h5" color="textPrimary">
                        {info.name}
                      </Typography>
                      {info.is_approved_trainer && (
                        <Typography
                          variant="subtitle1"
                          classes={{
                            root: classes.trainer,
                          }}
                          color="textPrimary"
                        >
                          APPROVED TRAINER
                        </Typography>
                      )}

                      <Typography variant="subtitle1" color="white">
                        {locName(info)}
                      </Typography>
                      {info.membership_start && (
                        <Typography variant="caption" color="textSecondary">
                          {t('Member since {{date}}', {
                            date: new Date(info.membership_start).toLocaleDateString(),
                          })}
                        </Typography>
                      )}

                      {info.is_approved_trainer && info.public_url && (
                        <>
                          <Typography
                            variant="subtitle1"
                            classes={{
                              root: classes.trainer,
                            }}
                            color="textPrimary"
                          >
                            Contact me:
                          </Typography>
                          <Link href={info.public_url} target="_blank" color="blue">
                            {info.public_url}
                          </Link>
                        </>
                      )}
                    </div>
                  }
                  className={classes.cardHeader}
                  action={
                    <Box height={100}>
                      {info.membership_start && (
                        <img src={membersLogo} className={classes.logo} alt={t('Linux Professional Institute')} />
                      )}
                    </Box>
                  }
                />
                <CardContent
                  classes={{
                    root: classes.cardContent,
                  }}
                >
                  {info.certifications.length ? (
                    info.certifications.map((certification, index) => (
                      <CertificationItem key={index} certification={certification} index={index} info={info} />
                    ))
                  ) : (
                    <Box px={4} py={3}>
                      <Grid container alignItems="center" justify="center">
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" color="textSecondary" align="center">
                            {t('No certifications')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {info.bio && (
                    <React.Fragment>
                      <Divider />
                      <Box px={4} py={3}>
                        <Typography variant="caption" color="textSecondary" display="block">
                          {info.bio}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Box height={100}>
                <img src={info.qr_code} className={classes.logo} alt={t('QR CODE')} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  );
};

export default React.memo(PersonalPage);
