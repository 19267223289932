import React from 'react';
import * as routes from '../../navigation/routes';

import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const ActivityEmpty = ({ button }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('You have not added any PDU claims for your professional activities and accomplishments.')}
        </Typography>
        <br />

        <Typography variant="body1">
          {t(
            'Describing your professional development actvities and claiming PDUs is a requirement for maintaining your membership. ',
          )}
        </Typography>

        <Typography variant="body1">
          {t('Read more about Professional Development Units (PDUs) at ')}

          <Link
            href="https://www.lpi.org/member/pdu-procedures-and-policies#earning-pdus"
            target="_blank"
            rel="noopener"
          >
            https://www.lpi.org/member/pdu-procedures-and-policies#earning-pdus
          </Link>
        </Typography>

        <br />

        {button ? (
          <Button component={Link} to={routes.ACTIVITIES_CREATE} color="primary" variant="contained" size="large">
            {t('Add Activity')}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

ActivityEmpty.defaultProps = {
  button: true,
};

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  icon: {
    marginBottom: 20,
    height: 70,
    width: 70,
  },
});

export default React.memo(ActivityEmpty);
