import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MTextField from '@material-ui/core/TextField';

class TextField extends PureComponent {
  render() {
    const { error, ...rest } = this.props;
    return <MTextField fullWidth variant="outlined" {...rest} error={!!error} helperText={error} />;
  }
}

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default TextField;
