import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _flattenDeep from 'lodash/flattenDeep';
import { createSelector } from 'reselect';

const getCategories = state => state.activities.categories;
const getUser = state => state.auth.user;
const getAuthRequests = state => state.auth.requests;
const getRules = state => state.memberships.rules;
const getScoreboard = state => state.certifications.scoreboard;
const getCertifications = state => _values(state.certifications.list);
const getCertificationsDict = state => state.certifications.list;

const getSnackMessage = createSelector(
  state => state.snack.message,
  message => message,
);

const getPDUStatus = createSelector([getUser, getScoreboard, getCertifications], (user, scoreboard) => {
  const hasAchived = scoreboard.pdu >= scoreboard.target;
  if (user.membership_step !== 'member') {
    return 'inactive';
  } else {
    return hasAchived ? 'achieved' : 'not_achieved';
  }
});

const getSubCategoriesList = createSelector(getCategories, categories => {
  return categories
    ? _flattenDeep(
        categories.map(cat => {
          return cat.child && cat.child.map
            ? cat.child.map(subCat => {
                return {
                  ...subCat,
                  categoryId: cat.id,
                  categoryName: cat.name,
                };
              })
            : [];
        }),
      )
    : [];
});

const getSpecCategoriesList = createSelector(getCategories, categories => {
  if (!categories) {
    return [];
  }

  const result = [];

  categories.forEach(category => {
    if (!category.child || !category.child.map) {
      return;
    }

    category.child.forEach(subCat => {
      if (!subCat.child || !subCat.child.map) {
        return;
      }

      subCat.child.forEach(specCat => {
        result.push({
          ...specCat,
          categoryId: category.id,
          categoryName: category.name,
          subCategoryId: subCat.id,
          subCategoryName: subCat.name,
        });
      });
    });
  });

  return _flattenDeep(result);
});

const getRuleMetrics = createSelector(getRules, rules => {
  return _keyBy(
    rules.map(r => ({
      name: r.metric,
      id: `${r.category_id}-${r.sub_category_id}-${r.spec_category_id}`,
    })),
    'id',
  );
});

const getActiveCertifications = createSelector(
  state => state.certifications.list,
  certifications => _values(certifications).filter(c => c.active && !c.group_exclude_from_pdu),
);

const getInactiveCertifications = createSelector(
  state => state.certifications.list,
  certifications => _values(certifications).filter(c => !c.active && !c.group_exclude_from_pdu),
);

const getOrderedActivities = createSelector(
  state => state.activities.list,
  activities => _reverse(_sortBy(_values(activities), 'create_date')),
);

const getRejectedActivities = createSelector(getOrderedActivities, activities =>
  activities.filter(a => a.state === 'rejected'),
);

const getPendingActivities = createSelector(getOrderedActivities, activities =>
  activities.filter(a => a.state !== 'rejected' && a.state !== 'accepted'),
);

const getAcceptedActivities = createSelector(getOrderedActivities, activities =>
  activities.filter(a => a.state === 'accepted'),
);

const getJobOffers = createSelector(
  state => state.jobs.offers,
  offers => offers,
);

const getJobSkills = createSelector(
  state => state.jobs.skills,
  skills => skills,
);

const getJobFavourites = createSelector(
  state => state.jobs.favourites,
  favourites => favourites,
);

const getJobApplications = createSelector(
  state => state.jobs.applications,
  applications => applications,
);

const getJobSearches = createSelector(
  state => state.jobs.searches,
  searches => searches,
);

const getFilteredJobs = createSelector(
  state => state.jobs.filtered,
  filtered => filtered,
);

const getJobAlerts = createSelector(
  state => state.jobs.alerts,
  alerts => alerts,
);

const getJobRequests = createSelector(
  state => state.jobs.requests,
  requests => requests,
);

const getVouchers = createSelector(
  state => state.vouchers.list,
  items => items,
);

const getCertificationGroups = createSelector(
  state => state.certifications.groups,
  groups => _values(groups),
);

const getActivities = createSelector(
  state => state.activities.list,
  activities => activities,
);

const getPersonalPageInfo = createSelector(
  state => state.personalPage.info,
  info => info,
);

const getGroupedCertifications = createSelector(
  state => state.certifications.groups,
  state => state.certifications.list,
  (groups, list) =>
    _values(groups).map(group => {
      group.certifications = group.certs.map(certificationId => list[certificationId]);
      return group;
    }),
);

const getCertificationsCount = createSelector(
  state => state.certifications,
  certifications => _values(certifications).length,
);

// Volunteers Selectors

const getVolunteerPrograms = createSelector(
  state => state.volunteers.programs,
  programs => programs,
);

const getAppliedVolunteerPrograms = createSelector(
  state => state.volunteers.appliedOpportunities,
  opps =>
    _values(opps).reduce((acc, opp) => {
      if (!acc.includes(opp.program)) {
        acc.push(opp.program);
      }
      return acc;
    }, []),
);

const getAppliedOpportunities = createSelector(
  state => state.volunteers.appliedOpportunities,
  opps => opps,
);

const getSavedOpportunities = createSelector(
  state => state.volunteers.savedOpportunities,
  opps => opps,
);

const getVolunteerRequests = createSelector(
  state => state.volunteers.requests,
  requests => requests,
);

const getCoupons = createSelector(
  state => state.coupons.list,
  items => items,
);

const getClaimedCoupon = createSelector(
  state => state.coupons.claimedCoupon,
  coupon => coupon,
);

const getClaimCouponRequests = createSelector(
  state => state.coupons.requests.claimCoupon,
  requests => requests,
);

const getCreateReferralCouponRequests = createSelector(
  state => state.coupons.requests.createReferralCoupon,
  requests => requests,
);

const getBenefits = createSelector(
  state => state.benefits.list,
  items => items,
);

const getBenefitsRequests = createSelector(
  state => state.benefits.requests,
  requests => requests,
);

export {
  getSnackMessage,
  getSubCategoriesList,
  getSpecCategoriesList,
  getRuleMetrics,
  getPDUStatus,
  getActiveCertifications,
  getInactiveCertifications,
  getUser,
  getAuthRequests,
  getCertificationsCount,
  getGroupedCertifications,
  getCertifications,
  getCertificationsDict,
  getCertificationGroups,
  getOrderedActivities,
  getPendingActivities,
  getAcceptedActivities,
  getRejectedActivities,
  getScoreboard,
  getJobOffers,
  getFilteredJobs,
  getJobSkills,
  getJobFavourites,
  getJobApplications,
  getJobSearches,
  getJobAlerts,
  getJobRequests,
  getVouchers,
  getActivities,
  getPersonalPageInfo,
  getVolunteerPrograms,
  getAppliedVolunteerPrograms,
  getAppliedOpportunities,
  getSavedOpportunities,
  getVolunteerRequests,
  getCoupons,
  getClaimedCoupon,
  getClaimCouponRequests,
  getCreateReferralCouponRequests,
  getBenefits,
  getBenefitsRequests,
};
