import React, { memo } from 'react';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import usePayments, { PAYMENT_PROVIDER } from '../../../hooks/Shop/usePayments';
import FreeOrderCheckout from './FreeOrderCheckout';
import { CHECKOUT_CARD, CHECKOUT_PAYPAL, CHECKOUT_TRANSFER } from '../../../navigation/routes';
import { FaPaypal } from 'react-icons/fa';

const CheckoutMethods = ({ cart }) => {
  const { t } = useTranslation();
  const history = useHistory();

  // Get payment methods after that cart is fetched
  const { paymentMethods, status } = usePayments(cart.id);

  // Empty cart
  if (!cart.lines.count) {
    return (
      <Box my={2}>
        <Typography variant="subtitle2" gutterBottom>
          {t('This cart is empty')}
        </Typography>
      </Box>
    );
  }

  // Cart not empty but nothing to pay
  if (cart.lines.count && cart.amount.total === 0) {
    return <FreeOrderCheckout cart={cart} />;
  }

  // Cart not empty and to be paid
  return (
    <Box my={2}>
      <Typography variant="subtitle2" gutterBottom>
        {t('Choose your payment method')}
      </Typography>
      <Grid container direction="column" spacing={1}>
        {(() => {
          if (status === 'success') {
            return paymentMethods.map(method => {
              switch (method.provider) {
                case PAYMENT_PROVIDER.STRIPE:
                  return (
                    <Grid item key={method.provider}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<PaymentIcon />}
                        onClick={() => history.push([CHECKOUT_CARD, cart.id].join('/'))}
                      >
                        {t('Pay now')}
                      </Button>
                    </Grid>
                  );

                case PAYMENT_PROVIDER.PAYPAL:
                  return (
                    <Grid item key={method.provider}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<FaPaypal />}
                        onClick={() => history.push(CHECKOUT_PAYPAL)}
                      >
                        {t('Paypal')}
                      </Button>
                    </Grid>
                  );

                case PAYMENT_PROVIDER.WIRE_TRANSFER:
                  return (
                    <Grid item key={method.provider}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<AccountBalanceIcon />}
                        onClick={() => history.push([CHECKOUT_TRANSFER, cart.id].join('/'))}
                      >
                        {t('Bank transfer')}
                      </Button>
                    </Grid>
                  );
              }
            });
          }
        })()}
      </Grid>
    </Box>
  );
};

export default memo(CheckoutMethods);
