import api from '../backend';
import { useQuery, useQueryClient, useMutation } from 'react-query';

export const QUERY_KEY = 'volunteers';

export const usePrograms = () => {
  const req = useQuery([QUERY_KEY, 'programs'], () => api.volunteer.programs.search());

  return {
    programs: req.data?.results || [],
    ...req,
  };
};

export const useOpportunities = () => {
  const req = useQuery([QUERY_KEY, 'opportunities'], () => api.volunteer.opportunities.search());

  return {
    opportunities: req.data?.results || [],
    ...req,
  };
};

export const useAppliedOpportunities = () => {
  const req = useQuery([QUERY_KEY, 'applications'], () => api.volunteer.applications.search());

  return {
    applications: req.data?.results || [],
    ...req,
  };
};

export const useFavoriteOpportunities = () => {
  const req = useQuery([QUERY_KEY, 'saved'], () => api.volunteer.favourites.search());

  return {
    favorites: req.data?.results || [],
    ...req,
  };
};

export const useVolunteersMutations = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries([QUERY_KEY]);

  const apply = useMutation(payload => api.volunteer.applications.create(payload), { onSuccess });
  const withdraw = useMutation(id => api.volunteer.applications.withdraw(id), { onSuccess });
  const createFavorite = useMutation(id => api.volunteer.favourites.create({ opportunity_id: id }), { onSuccess });
  const removeFavorite = useMutation(id => api.volunteer.favourites.delete(id), { onSuccess });

  return {
    apply,
    withdraw,
    createFavorite,
    removeFavorite,
  };
};
