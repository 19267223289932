import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import qs from 'qs';

import clean from '../utils/clean';

const urlReducer = (_p, nextStore) => {
  // Clean & sort properties
  return clean(nextStore);
};

/**
 * Create a state controller stored in the URL search query.
 * @param {any} initialState Initial filters
 */
const useParamsState = initialState => {
  const history = useHistory();
  const [state, setState] = useReducer(urlReducer, initialState);

  /* Parse state from search query after mount */
  useEffect(() => {
    const parsed = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    if (parsed !== initialState) setState(parsed);
  }, []);

  /* Push stringified state in the search query */
  useEffect(() => {
    const serializedState = qs.stringify(state, {
      encode: false,
      addQueryPrefix: true,
    });
    if (serializedState.length >= 2047) {
      console.warn(
        'Your serialized state has exceeded the maximum length for some browsers. Consider removing unnecessary info from it to reduce its size',
      );
    }

    window.history.replaceState(null, null, history.location.pathname + serializedState);
  }, [state]);

  return [state, setState];
};

export default useParamsState;
