import React from 'react';
import { Box, Typography } from '@material-ui/core';
import emptyCartImage from '../../assets/images/empty_cart.svg';
import { useTranslation } from 'react-i18next';

const EmptyCart = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
      <Box textAlign="center">
        <img src={emptyCartImage} atl="Empty Cart" width={300} alt="Empty Cart" />
        <Box marginTop={2}>
          <Typography variant="h5">{t('Your cart is empty')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyCart;
