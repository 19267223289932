import _keyBy from 'lodash/keyBy';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class ChooseCategory extends PureComponent {
  handleSelect = specCategoryId => {
    const { formProps } = this.props;
    const specCategory = _keyBy(this.props.specCategories, 'id')[specCategoryId];
    formProps.setFieldValue('category_id', specCategory.categoryId);
    formProps.setFieldValue('sub_category_id', specCategory.subCategoryId);
    formProps.setFieldValue('spec_category_id', specCategory.id);
    window.scroll(0, 0);
  };

  render() {
    const { categories } = this.props;
    if (!categories) return null;

    return (
      <div>
        {categories.map(cat => (
          <div key={`cat_${cat.id}`}>
            {cat.child &&
              cat.child.map(subCat => (
                <div key={`subCat_${subCat.id}`}>
                  <h3>
                    {cat.name}: {subCat.name}
                  </h3>
                  <List>
                    {subCat.child &&
                      subCat.child.map(spec => (
                        <ListItem onClick={() => this.handleSelect(spec.id)} button key={`spec_${spec.id}`}>
                          <ListItemText>{spec.name}</ListItemText>
                        </ListItem>
                      ))}
                  </List>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  }
}

ChooseCategory.propTypes = {
  formProps: PropTypes.object,
  categories: PropTypes.array,
  specCategories: PropTypes.array,
};

export default ChooseCategory;
