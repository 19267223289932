import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffcd04',
      dark: '#ffb500',
    },
    secondary: {
      main: '#37474F',
      dark: '#263238',
    },
    background: {
      default: '#eceff1',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,

        '&:hover': {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },

      contained: {
        boxShadow: 'none',
        borderRadius: 0,

        '&:hover': {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;
