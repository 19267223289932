import React, { PureComponent } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { getMetric } from '../../utils/metrics';
import { withTranslation } from 'react-i18next';
class RulesTable extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Specification')}</TableCell>
            <TableCell align="right">{t('Metric')}</TableCell>
            <TableCell align="right">{t('PDUs per Unit')}</TableCell>
            <TableCell align="right">{t('Max. PDUs per Cycle')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.rules.map(r => (
            <TableRow key={r.id}>
              <TableCell>{r.spec_category_name}</TableCell>
              <TableCell align="right">{getMetric(r.metric)}</TableCell>
              <TableCell align="right">{r.pdu_per_achievement}</TableCell>
              <TableCell align="right">{r.max_pdu_per_cycle}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withTranslation()(RulesTable);
