const METRICS = {
  event_attended: 'Events attended',
  course_completed: 'Courses completed',
  course_done: 'Courses done',
  hours_spent: 'Hours spent',
  activity_completed: 'Activities completed',
  item_published: 'Items published',
  chapter_started: 'Chapters started',
  contest_entered: 'Contests entered',
  exam_taken: 'Exams taken',
  level_acchived: 'Levels achieved',
  acceptance: 'Acceptance',
  project_created: 'Projects created',
  papers_published: 'Papers published',
  exam_proctored: 'Exams proctored',
  session_delivered: 'Sessions delivered',
  badge_achieved: 'Badge achieved',
  book_created: 'Book created',
  certification_achieved: 'Certification achieved',
  course_created: 'Course created',
  days_attended: 'Days attended',
  group_organized: 'Group organized',
  paper_published: 'Paper published',
  session_proctored: 'Session proctored',
  topic_addressed: 'Topic addressed',
  topic_completed: 'Topic completed',
  years_active: 'Years active',
};

export const getMetric = key => {
  return METRICS[key] ? METRICS[key] : key;
};
