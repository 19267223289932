import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const JobOptions = ({ controller: { filters, setFilters } }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={filters.remote} onChange={() => setFilters({ ...filters, remote: !filters.remote })} />
          }
          label={t('Remote')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.remote_international}
              onChange={() => setFilters({ ...filters, remote_international: !filters.remote_international })}
            />
          }
          label={t('Remote international')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.inplace}
              onChange={() => setFilters({ ...filters, inplace: !filters.inplace })}
            />
          }
          label={t('In  office')}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(JobOptions);
