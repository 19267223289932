import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AccountBox from '@material-ui/icons/AccountBox';
import ExitToApp from '@material-ui/icons/ExitToApp';
import React from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as routes from '../../../navigation/routes';
import { logout } from '../../../store/reducers/AuthReducer';
import { getUser } from '../../../store/selectors';

const IconMenuItem = ({ icon, primary, secondary }) => {
  return (
    <Box display="flex" alignItems="center" minWidth={200}>
      <ListItemIcon style={{ minWidth: 0, marginRight: 16 }}>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </Box>
  );
};

const User = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <Tooltip title={t('Profile')}>
        <IconButton onClick={handleClick}>
          <Avatar email={user.email} name={user.name} size="32" round={true} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuList>
          <ListItem>
            <ListItemText primary={user.name} secondary={user.lpiid} />
          </ListItem>
          <Divider />
          <MenuItem component={NavLink} to={routes.PROFILE_PAGE}>
            <IconMenuItem icon={<AccountBox />} primary={t('Profile')} />
          </MenuItem>
          <MenuItem onClick={() => dispatch(logout())}>
            <IconMenuItem icon={<ExitToApp />} primary={t('Logout')} />
          </MenuItem>
        </MenuList>
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(User);
