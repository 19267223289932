import _keyBy from 'lodash/keyBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useLocation, useHistory, useParams, Link as RouteLink } from 'react-router-dom';
import useVouchers from '../../hooks/Vouchers/useVouchers';
import { VOUCHERS, VOUCHER_PRODUCT_LIST } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ActionButton from '../../components/Common/ActionButton';
import Layout from '../../components/App/Layout';
import VouchersDetail from '../../components/Vouchers/VouchersDetail';
import VouchersTable from '../../components/Vouchers/VouchersTable';
import { CircularProgress } from '@material-ui/core';

const TABS = { NEW: 0, USED: 1 };

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const VouchersPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const rootPath = VOUCHERS;
  const location = useLocation();
  let history = useHistory();
  const { id } = useParams();

  const detail_route_match = useRouteMatch({
    path: rootPath + '/:id/',
  });

  const [activeTab, setActiveTab] = React.useState(TABS.NEW);
  const { vouchers, status } = useVouchers();

  const [item, setItem] = React.useState(null);
  // const [shownItems, setShownItems] = React.useState([]);

  React.useEffect(() => {
    let tempItem = _keyBy(vouchers, 'id')[id];
    if (tempItem) {
      setItem(tempItem);
    }
  }, [id, vouchers, history]);

  const handleRowClick = React.useCallback(
    id => {
      history.push({
        pathname: rootPath + '/' + id,
        search: location.search,
      });
    },
    [history, location, rootPath],
  );

  const handleCloseDetail = React.useCallback(() => {
    history.push({
      pathname: rootPath,
      search: location.search,
    });
  }, [history, location, rootPath]);

  // React.useEffect(() => {
  //   handleCloseDetail();
  // setShownItems(activeTab === TABS.NEW ? vouchers.new : vouchers.old);
  // eslint-disable-next-line
  // }, [activeTab])

  return (
    <React.Fragment>
      <Layout title={t('Vouchers')} back={!!detail_route_match} backPath={rootPath}>
        <Tabs
          centered
          className={classes.tabs}
          value={activeTab}
          onChange={(event, newTabIndex) => {
            handleCloseDetail();
            setActiveTab(newTabIndex);
          }}
        >
          <Tab label={t('New')} index={TABS.NEW} />
          <Tab label={t('Used')} index={TABS.USED} />
        </Tabs>

        <Box display="flex" justifyContent="space-between" pl={2} m={2} flexDirection="row">
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('My Vouchers')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t('List of vouchers owned')}
            </Typography>
          </Box>
          <Box>
            <ActionButton component={RouteLink} to={VOUCHER_PRODUCT_LIST}>
              {t('BUY VOUCHERS')}
            </ActionButton>
          </Box>
        </Box>

        <Box px={2}>
          <Grid container spacing={3} display="flex">
            <Grid item xs={12} sm>
              <Card variant="outlined">
                <CardContent classes={{ root: classes.customCardRoot }}>
                  {(() => {
                    switch (status) {
                      case 'idle':
                      case 'loading':
                        return (
                          <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                            <CircularProgress color="primary" />
                          </Box>
                        );
                      case 'error':
                        return (
                          <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                            {t('An error occurred during the request')}
                          </Box>
                        );
                      case 'success':
                        return (
                          <VouchersTable
                            rows={vouchers.filter(voucher =>
                              activeTab === TABS.NEW ? voucher.state === 'assigned' : voucher.state !== 'assigned',
                            )}
                            onRowClick={handleRowClick}
                          />
                        );
                    }
                  })()}
                </CardContent>
              </Card>
            </Grid>
            <Switch>
              <Route path={rootPath + '/:id(\\d+)'}>
                <Grid item xs={12} lg={4}>
                  <VouchersDetail item={item} onCloseClick={handleCloseDetail} />
                </Grid>
              </Route>
            </Switch>
          </Grid>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default React.memo(VouchersPage);
