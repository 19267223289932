import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../../store/reducers/AuthReducer';
import * as queryString from 'query-string';

import Anchor from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/logo.png';

import LoginForm from '../../components/Users/LoginForm';
import PageBackground from '../../components/App/PageBackground';
import Paper from '@material-ui/core/Paper';
import { Button, Divider } from '@material-ui/core';
import LanguageSwitcher from '../../components/App/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      paddingTop: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '20px',
    },
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textColumn: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: '50%',
    paddingRight: 50,
    paddingLeft: 50,
  },
  loginColumn: {
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      paddingRight: 20,
      paddingLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  loginbox: {
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 70,
    paddingBottom: 70,
  },
  loginContainer: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  logoLogin: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginBottom: 20,
  },
  textbox: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}));

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { next } = queryString.parse(location.search);

  const request = useSelector(state => state.auth.requests.login);

  const handleSubmit = React.useCallback(
    values => {
      values['next'] = next;
      return dispatch(login(values));
    },
    [dispatch, next],
  );

  return (
    <PageBackground styles={{ backgroundColor: '#fff9e0' }}>
      <Box className={classes.pageContainer}>
        <Box display="flex">
          <Box className={classes.textColumn}>
            <img src={logo} height={60} alt="Linux Professional Institute" className={classes.logo} />

            <p>
              {t(`Welcome to our community portal.  You can use this site to track 
            your credentials and membership, volunteer for community programs, 
            take advantage of various partner (and LPI) special offers, 
            and more (in development).`)}
            </p>

            <p>
              {t(
                "However, if you have reached this site and don't know why you are logging in.  You may be interested in our:",
              )}
              <ul>
                <li>
                  <a href="https://lpi.org/certifications" target="_blank" rel="noopener noreferrer">
                    {t('certifications')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://learning.lpi.org/en/learning-materials/all-materials/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('learning materials and books')}
                  </a>{' '}
                  or{' '}
                  <a href="http://cs.lpi.org/caf/Xamman/partner" target="_blank" rel="noopener noreferrer">
                    {t('training partners')}
                  </a>
                </li>
                <li>
                  <a href="https://lpi.org/member" target="_blank" rel="noopener noreferrer">
                    {t('membership')}
                  </a>{' '}
                  and{' '}
                  <a href="https://lpi.org/community" target="_blank" rel="noopener noreferrer">
                    {t('community programs')}
                  </a>
                </li>
                <li>
                  <a href="https://lpi.org/articles" target="_blank" rel="noopener noreferrer">
                    {t('news')}
                  </a>
                  ,{' '}
                  <a href="https://lpi.org/blog" target="_blank" rel="noopener noreferrer">
                    {t('blogs')}
                  </a>{' '}
                  and{' '}
                  <a href="https://lpi.org/events" target="_blank" rel="noopener noreferrer">
                    {t('events')}
                  </a>{' '}
                  or the{' '}
                  <a href="https://discuss.lpi.org" target="_blank" rel="noopener noreferrer">
                    {t('discussion forums')}
                  </a>
                </li>
              </ul>

              <p>
                {t('If you are a partner, you may be looking for our partner portal at')}{' '}
                <a href="https://partners.lpi.org" target="_blank" rel="noopener noreferrer">
                  https://partners.lpi.org
                </a>
              </p>

              <p>
                {t('If you are still reading and want to log in here, you will need an LPI ID.')}{' '}
                {t('You can obtain one at')}{' '}
                <a href="https://lpi.org/register" target="_blank" rel="noopener noreferrer">
                  https://lpi.org/register
                </a>
              </p>
            </p>
          </Box>

          <Box className={classes.loginColumn}>
            <Paper className={classes.loginbox}>
              <Box className={classes.loginContainer}>
                <img src={logo} height={60} alt="Linux Professional Institute" className={classes.logoLogin} />
                <Typography variant="h5" gutterBottom>
                  {t('Login')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t('Insert your LPI ID and password')}
                </Typography>
                <LoginForm loading={request.loading} onSubmit={handleSubmit} />
                <Typography variant="subtitle1" className={classes.error}>
                  {request.error}
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Anchor color="secondary" href="https://cs.lpi.org/caf/Xamman/register/send_password">
                    {t('Forgot password?')}
                  </Anchor>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Anchor color="secondary" href="mailto:membership@lpi.org">
                    {t('Having trouble logging in?')}
                  </Anchor>
                </Box>
                <Box marginY={3}>
                  <Divider />
                  <Box display="flex" justifyContent="center" position="relative" my={3}>
                    <Typography>{t("don't have an account?")}</Typography>
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    href="https://lpi.org/register"
                    target="_blank"
                  >
                    {t('Register now ')}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2} mt={5}>
        <LanguageSwitcher />
      </Box>
    </PageBackground>
  );
};

export default React.memo(LoginPage);
